import React, { useContext, useState } from 'react';
import { copyToClipboard } from '../../../services/CustomFunctions';
import FieldErrors from '../FieldErrors';
import UnsavedChangesIcon from './UnsavedChangesIcon';
import { getMathText, getSubScriptText, getSuperScriptText } from '../../../services/TextHelper';
import { useTranslation } from 'react-i18next';
import Translate from '../../Helper/Translate';
import { FetchError } from '../../../services/FetchHelper';
import { GlobalModalContext } from '../../_MyFloor/MyFloorApp';
import { ChangeEvent } from '../../../model/ChangeEvent';

interface TextAreaType {
    name: string;
    value: string;
    onChange: (e: ChangeEvent<string>) => void;
    placeholder?: string;
    errors?: FetchError;
    errorField?: string;
    noLabel?: boolean;
    label?: string;
    tabIndex?: number;
    labelWidth100?: boolean;
    disabled?: boolean;
    disabledHint?: string;
    clickToCopy?: boolean;
    readOnly?: boolean;
}
const TextArea = (props: TextAreaType) => {
    const {name, value, onChange, placeholder, errors, errorField, noLabel, label, tabIndex, labelWidth100, disabled, disabledHint, clickToCopy, readOnly} = props;
    const {popMsg} = useContext(GlobalModalContext);
    const {t} = useTranslation();
    const [_o] = useState(value);
    const showUnsaved = _o !== value;

    const copyValue = () => {
        copyToClipboard(value);
        popMsg("pop_added", "pop_msg_clipboard_add");
    }

    return(
        <div className={`form-group ${disabled && disabledHint ? ' hover-trigger' : ''}`} onClick={clickToCopy ? copyValue : undefined}>
            { !noLabel && <label className={labelWidth100 ? 'full-width' : undefined} htmlFor={name}><Translate id={label || name} /></label> }
            <textarea
                dir='auto' 
                disabled={disabled} 
                readOnly={readOnly}
                tabIndex={tabIndex} 
                name={name} 
                placeholder={placeholder ? (t(placeholder) ?? '') : ''}  
                className='form-control' 
                value={value} 
                onClick={clickToCopy ? copyValue : undefined}
                onChange={e => onChange({target: {name: name, value: e.target.value}})} 
                onKeyDown={e => {
                    if(e.ctrlKey && (e.key === "+" || e.key === "-" || e.key === "*")){
                        e.stopPropagation();
                        e.preventDefault();
                        const start = e.currentTarget.selectionStart;
                        const end = e.currentTarget.selectionEnd;
                        if(start !== null && end !== null) {
                            let selectedText = e.currentTarget.value.slice(start, end);
                            const t = e.currentTarget.value;
                            selectedText = e.key === "+"
                                ? getSuperScriptText(selectedText)
                                : e.key === "-"
                                    ? getSubScriptText(selectedText)
                                    : getMathText(selectedText);

                            const newText = `${t.slice(0, start)}${selectedText}${t.slice(end)}`;
                            onChange({
                                target: {
                                    name: name,
                                    // @ts-ignore
                                    value: typeof(value) === 'number' ? parseFloat(e.target.value) : newText
                                }
                            })
                        }
                    }
                }}
            />                    
            <UnsavedChangesIcon show={showUnsaved}/>
            { errors && <FieldErrors errors={errors} fieldname={errorField || name}/>}   
            {disabled && disabledHint && <div className='hover-msg'><Translate id={disabledHint} /></div>}
        </div>
    );
};

export default TextArea;