import React from 'react';
import { StatsContext } from '../../api/StatsContext';
import { useTranslation } from 'react-i18next';
import { StatsFilters } from '../../model/Stats/StatsFilters';
import CombiChart from './BaseCharts/CombiChart';

interface Props{
    filters: StatsFilters;
    showDuration?: boolean;
}

const GameTypesChart = (props: Props) => {
    const {filters, showDuration} = props;
    const {t} = useTranslation();
    

    return(
        <CombiChart 
            dataFunc={StatsContext.useGameType}
            initialType='pie' 
            filters={filters}
            label={x => x ? t(`gametype_${x.data}`) : "Unknown"} 
            showDuration={showDuration}  
            title='chart_gametype' 
        />
    )
}

export default GameTypesChart;