import React from 'react';
import { StatsContext } from '../../api/StatsContext';
import Game from '../ModelPreview/Game';
import { StatsFilters } from '../../model/Stats/StatsFilters';
import CombiChart from './BaseCharts/CombiChart';
import { preciseHumanDuration } from '../../services/CustomFunctions';

interface Props{
    filters: StatsFilters;
    showDuration?: boolean;
}

const GamesChart = (props: Props) => {
    const {filters, showDuration} = props;

    return(
        <CombiChart 
            dataFunc={StatsContext.usePopularGames}
            filters={filters}
            initialType='pie'
            label={x => x?.data?.title ?? "Unknown"}
            title='chart_most_played_games'
            showDuration={showDuration}  
            toolTip={e => (
                <div>
                    <div><span style={{color: e.color}}>&#11044;</span> {showDuration ? preciseHumanDuration(e.data.duration) : e.data.totalValue} ({e.percent}%)</div>
                    {e.data.data && <Game game={e.data.data} />}
                </div>
            )}
            onClick={data => {
                const game = data.data;
                if(game){
                    window.open(`/workshop/${game.gameType}/edit/${game.id}`);
                }
            }} 
        /> 
    )
}

export default GamesChart;