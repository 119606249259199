import React, { useEffect, useState } from 'react';
import { PlayListType } from '../../model/PlayListType';
import { PlaylistContext, PlaylistWithContent } from '../../api/PlaylistContext';
import { isFetchError } from '../../services/FetchHelper';
import PlaylistFloorGridPreview from './PlaylistFloorGridPreview';
import PlaylistFloorDefaultPreview from './PlaylistFloorDefaultPreview';
import { EventEffects } from '../../events/PlaylistEvents';
import { playlistUpdatedChangeContent } from './Explorer/Helpers';

interface Props{
    playlist: PlayListType;
}

const PlaylistFloorPreview = (props: Props) => {
    const {playlist} = props;

    const [content, setContent] = useState<PlaylistWithContent>();
    const [getContent, loadingContent] = PlaylistContext.useGetWithContent();

    useEffect(() => {
        getContent(playlist.id).then(x => !isFetchError(x) && setContent(x));
    }, [playlist.id, getContent])

    //Refetch content if playlist is updated.
    EventEffects.usePlaylistUpdatedEffect(x => playlistUpdatedChangeContent(setContent, x), []);

    if(playlist.viewMode === "Grid"){
        return (
            <PlaylistFloorGridPreview
                playlist={playlist}
                games={content?.games || []}
                playlists={content?.playlists || []}
                loading={loadingContent}
            />
        )
    } 
    return (
        <PlaylistFloorDefaultPreview
            playlist={playlist}
            games={content?.games || []}
            loading={loadingContent}
        />
    );
}

export default PlaylistFloorPreview;