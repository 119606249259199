import { useState } from 'react';

const useThrottle = <TArgs extends unknown[]> (callback: (...args: TArgs) => void, fps: number) => {
    const [last, setLast] = useState<number>(0);
    const interval = 1000 / fps;

    const func = (...args: TArgs) => {
        const now = Date.now();
        if(last + interval < now){
            setLast(now)
            callback(...args);
        }
    }
    
    return func;
}

export default useThrottle;