import React, { useContext } from 'react';
import { accountHasAnyPermission, accountIsOwner, AccountType } from '../../model/AccountType';
import { BaseGame } from '../../model/Game/BaseGame';
import TopMenuButton from '../Menu/TopMenuButton';
import { ExtraButtonProps } from '../EditGameComponent';
import { GlobalModalContext, SecureContext } from '../_MyFloor/MyFloorApp';
import { PermissionService } from '../../services/PermissionService';

interface Props{
    game: BaseGame | undefined;
    me: AccountType | undefined;
    newElement?: { func: () => void; disabled?: boolean; text?: string};
    restoreFunc?: () => void; 
    editClick: () => void;
    onAuthorClick: () => void;
    showTransferOwner: () => void;
    showDeleteGame: () => void;
    showCopy: () => void;
    onSettingsClick?: () => void;
    extraButtons?: ExtraButtonProps[];
}

const EditGameTopMenu = (props: Props) => {
    const {
        game, me, newElement, restoreFunc, showTransferOwner, showDeleteGame, onSettingsClick, extraButtons,
        showCopy, onAuthorClick,
        editClick
    } = props;
    const {myOrg} = useContext(SecureContext)
    const {showGameToPlaylist, showGameToFloor} = useContext(GlobalModalContext);
    const canEdit = PermissionService.updateItem(me, myOrg, game ?? null);
    const isAdmin = accountHasAnyPermission(me, "Admin");
    const isOwner = accountIsOwner(me, game);
    if (game?.deleted){
        return(<TopMenuButton 
            action={restoreFunc} 
            icon={'redo'} 
            hoverMsg={'trash_restore'}
        />)
    }
    return(
        <>
            {newElement &&
                <TopMenuButton
                    action={newElement.func}
                    icon={'plus'}
                    disabled={newElement.disabled || !canEdit}
                    hoverMsg={newElement.text}
                />
            }
            {game &&
                <TopMenuButton
                    action={editClick}
                    icon={'pen'}
                    hoverMsg={'game_edit_base_info'}
                    disabled={!game || !canEdit}
                />
            }
            {game && onSettingsClick &&
                <TopMenuButton
                    action={onSettingsClick}
                    icon={'cog'}
                    hoverMsg={'game_edit_settings'}
                    disabled={!canEdit}
                />
            }
            {game &&
                <TopMenuButton
                    action={onAuthorClick}
                    icon={'users'}
                    hoverMsg={'authors'}
                    disabled={!isAdmin && !isOwner && !game?.authorIds.find(x => x === me?.id)}
                />
            }
            {game &&
                <TopMenuButton
                    action={showTransferOwner}
                    icon={'user'}
                    hoverMsg={'transfer_owner'}
                    disabled={!isAdmin && !isOwner}
                />
            }
            {game && game.isPublished &&
                <TopMenuButton
                    action={() => showGameToPlaylist(game)}
                    icon={'plus-square'}
                    hoverMsg={'playlist_add_to'}
                />
            }
            {game && game.isPublished &&
                <TopMenuButton
                    action={() => showGameToFloor(game)}
                    icon={'folder-plus'}
                    hoverMsg={'game_add_to_floor'}
                />
            }

            {game && game.id &&
                <TopMenuButton
                    action={showCopy}
                    icon={'globe'}
                    hoverMsg={'copy_create'}
                />
            }
            {extraButtons && extraButtons.map(x => <TopMenuButton key={x.id} {...x} />)}
            {game && (isOwner || isAdmin) &&
                <TopMenuButton
                    action={showDeleteGame}
                    icon={'trash'}
                    hoverMsg={'delete'}
                />
            }
        </>
    )
}

export default EditGameTopMenu;