import React, { MouseEvent } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Translate from '../Helper/Translate';

export type IconMenuPointProps = undefined | {
    label: string;
    icon: IconProp;
    link?: string;
    onClick?: (e: MouseEvent) => void;
}

interface MenuProps{
    menuPoints: (IconMenuPointProps|undefined)[];
    visible: boolean;
}

const IconMenu = (props: MenuProps) => {
    const {menuPoints, visible} = props;

    return(
        <div className={`icon-menu ${!visible && 'hidden'}`}>
            <div className='menu-container'>
                {menuPoints.map(mp => {
                    if(mp && mp.link) return <LinkMenuPoint key={mp.label} {...mp} />;
                    if(mp && mp.onClick) return <ActionMenuPoint key={mp.label} {...mp} />;
                    return undefined;
                })}
                <div className='close-icon'>
                    <span>
                        <FontAwesomeIcon icon={'caret-up'} />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default IconMenu;

const LinkMenuPoint = (props: IconMenuPointProps) => (
    <Link to={props?.link || ''} >
        <div className='menu-point'>
            <span>
                <Translate id={props?.label}/>
            </span>
            {props?.icon && <FontAwesomeIcon icon={props.icon} className='fa-icon'/>}
        </div>
    </Link>
)

const ActionMenuPoint = (props: IconMenuPointProps) => (
    <div className='menu-point' onClick={props?.onClick}>
        <Translate id={props?.label}/>
        {props?.icon && <FontAwesomeIcon icon={props.icon} className='fa-icon'/>}
    </div>
)
