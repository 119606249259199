import React from 'react';
import { AdType } from '../../model/Advertisement/Ad';
import { DbOrUploadImage } from '../../services/ImageHelper';
import LoadingtBackgroundImg from '../../images/ad/loading.png';
import AboveGridPlaylistBackgroundImg from '../../images/ad/gridplaylist.png';
import LockScreenBackgroundImg from '../../images/ad/lockscreen.png';
import EmptyBackgroundImg from '../../images/ad/empty.png';
import { DbImage } from '../../model/DbImage';
import { ImageUploadType } from '../../model/ImageUploadType';

interface Props{
    model: {image?: DbImage|ImageUploadType, adType: AdType|""};
}

const getBackgroundImg = (type: AdType|"") => {
    switch(type){
        case "BeforeGameStart":
        case "AfterGameEnd":
            return LoadingtBackgroundImg;
        case "AboveGridPlaylist":
            return AboveGridPlaylistBackgroundImg;
        case "LockScreen":
            return LockScreenBackgroundImg;
    }
    return EmptyBackgroundImg;
}

export const AdPreview = (props: Props) => {
    const {model} = props;



    return(
        <div className='ad-preview'>
            <img className='background' alt='background' src={getBackgroundImg(model.adType)} />
            <DbOrUploadImage className={model.adType} image={model.image} realSize/>
        </div>
    )
}