import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextAlignment } from "../../../model/Game/TextType";
import UnsavedChangesIcon from "./UnsavedChangesIcon";
import Translate from "../../Helper/Translate";
import { ChangeEvent } from "../../../model/ChangeEvent";

interface Props {
    name: string;
    value: TextAlignment;
    onChange: (event: ChangeEvent<TextAlignment>) => void;
    label?: string;
    disabled?: boolean;
    disabledHint?: string;
    original?: TextAlignment;
}
const TextAlignmentFormGroup = (props: Props) => {
    const showUnsaved = props.original !== undefined && props.original !== props.value;

    const label = props.label ? <label htmlFor={props.name}><Translate id={props.label} /></label> : null;

    const onClick = (e: React.MouseEvent, align: TextAlignment) => {
        e.preventDefault();
        props.onChange({target: {name: props.name, value: align}});
    };

    const input = (
        <div className={`text-alignment${showUnsaved ? ' show-changes' : ''}`}>
            <button
                className={`float btn ${props.value === TextAlignment.Left ? 'active' : ''}`}
                onClick={(e) => onClick(e, TextAlignment.Left)}
            >
                <FontAwesomeIcon icon={'align-left'} />
            </button>
            <button
                className={`float btn ${props.value === TextAlignment.Center ? 'active' : ''}`}
                onClick={(e) => (onClick(e, TextAlignment.Center))}
            >
                <FontAwesomeIcon icon={'align-center'} />
            </button>
            <button
                className={`float btn ${props.value === TextAlignment.Right ? 'active' : ''}`}
                onClick={(e) => (onClick(e, TextAlignment.Right))}
            >
                <FontAwesomeIcon icon={'align-right'} />
            </button>
            <div className='clear-fix' />
            <UnsavedChangesIcon show={showUnsaved}/>
        </div>
    );
    return(
        <div className={`form-group`}>
            { label }
            { input }
            { props.disabled && props.disabledHint && <div className='hover-msg'><Translate id={props.disabledHint} /></div> }
            <div className='clear-fix'/>
        </div>
    )
};

export default TextAlignmentFormGroup;