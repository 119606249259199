import React from 'react';
import Translate from '../Helper/Translate';
import { OrganizationCategories, OrganizationCategory } from '../../model/Organization';

const schoolCategories: OrganizationCategory[] = ["PrimarySchool", "MiddleSchool", "Secondary", "SpecialEducation"];

interface Props{
    value: OrganizationCategory[];
    onChange: (changed: OrganizationCategory[]) => void;
    autoSchool?: boolean;
}

const OrgCategoryPicker = (props: Props) => {
    const {value, onChange, autoSchool} = props;
    const unselectedCategories = OrganizationCategories.filter(x => !value.includes(x));

    const onPick = (cat: OrganizationCategory) => {
        if(autoSchool){
            if(!value.includes("School") && schoolCategories.includes(cat)){
                onChange([...value, "School", cat]);
                return;
            }
        }
        onChange([...value, cat]);
    }

    return(
        <div className='org-category-field'>
            <div className='label'><Translate id='org_categories' /></div>
            <div className='selected'>
                {value.map(x => <CategoryTagLike key={x} cat={x} onClick={() => onChange(value.filter(c => c !== x))} />)}
            </div>
            <div><Translate id='choose_many' /></div>
            <hr />
            <div className='un-selected'>
                {unselectedCategories.map(x => <CategoryTagLike key={x} cat={x} onClick={() => onPick(x)} />)}
            </div>                               
        </div>
    )
}

export default OrgCategoryPicker;

interface CategoryTagLikeProps{
    cat: OrganizationCategory;
    onClick?: () => void;
}

export const CategoryTagLike = (props: CategoryTagLikeProps) => {
    const {cat, onClick} = props;
    return(
        <span 
            className={`org-cat ${cat}`}
            onClick={onClick}
        >
        <Translate id={`org_cat_${cat}`} />
    </span>   
    )
}