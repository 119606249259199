import React, { useContext, useEffect, useRef, useState } from 'react';
import { PlaylistContext } from '../../api/PlaylistContext';
import { accountHasAnyPermission } from '../../model/AccountType';
import { PlayListType } from '../../model/PlayListType';
import { isFetchError } from '../../services/FetchHelper';
import { useLocaleStorageItem } from '../../services/LocalStorageService';
import Dialog from '../Dialog';
import { GeneralError } from '../Error/GeneralError';
import StandaloneFieldError from '../forms/StandaloneFieldError';
import Translate from '../Helper/Translate';
import Subnavigation from '../Navigation/Subnavigation';
import { GlobalModalContext, SecureContext } from '../_MyFloor/MyFloorApp';
import PlaylistDetailed from './PlaylistDetailed';
import PlaylistExplorer from './Explorer/PlaylistExplorer';
import PlaylistOverview from './PlaylistOverview';
import { updateArrayItemsById } from '../../services/CustomFunctions';
import { ViewModeWarning } from './Explorer/ViewModeWarning';

interface Props{
    onClose: () => void;
    playlist: PlayListType;
}

const viewModeKey = "playlist_add_game_viewmode";

const PlaylistAddPlaylist = (props: Props) => {
    const {onClose, playlist} = props;
    const {popMsg} = useContext(GlobalModalContext);
    const {me} = useContext(SecureContext);
    
    const errorRef = useRef<HTMLDivElement>(null);
    const [playlists, setPlaylists] = useState<PlayListType[]>([]);
    const [floorPlaylists, setFloorPlaylists] = useState<PlayListType[]>([]);
    const [convertToViewModeDialog, showConvertToViewModeDialog] = useState<PlayListType>();
    const [viewMode, setViewMode] = useLocaleStorageItem<'Overview'|'Explorer'|'Floor'>(viewModeKey,  "Overview");
    
    const [getPlaylists, loadingPlaylists, errorPlaylists] = PlaylistContext.usePlaylists();
    const [getFloorPlaylists, loadingFloorPlaylists, errorFloorPlaylists] = PlaylistContext.usePrimaryFloorPlaylists();
    const [_addPlaylist, loadingAddPlaylist, errorAddPlaylist] = PlaylistContext.useAddPlaylist();

    useEffect(() => {
        getPlaylists().then(x => !isFetchError(x) && setPlaylists(x));
        if(me && accountHasAnyPermission(me, 'Floor', 'Admin') ){
            getFloorPlaylists().then(x => !isFetchError(x) && setFloorPlaylists(x));
        }
    }, [getPlaylists, getFloorPlaylists, me]);
    
    if(me == null) return null;

    const pipp = accountHasAnyPermission(me, 'Admin', 'PlaylistInPlaylist');

    const addPlaylist = async (selectedPlaylist: PlayListType) => {
        const result = await _addPlaylist(selectedPlaylist.id, playlist.id);
        if(!isFetchError(result)){
            popMsg("pop_added", "pop_msg_playlist_add_playlist", {name: selectedPlaylist.name});
            setPlaylists(x => updateArrayItemsById(x, result.parent, result.child));
        }else{
            errorRef.current?.scrollIntoView({behavior: "smooth"});
        }
    }

    const playlistSelected = (selected: PlayListType) => {
        if(selected.viewMode === 'Standard')
        {
            showConvertToViewModeDialog(selected);
        }
        else{
            addPlaylist(selected);
        }
    }

    const ownerPlaylists = playlists.filter(x => x.ownerId === me.id || (x.authorIds && x.authorIds.includes(me.id)));

    return(
        <Dialog 
            className='playlist-add-playlist'
            onClose={onClose}
            loading={loadingAddPlaylist || loadingPlaylists || loadingFloorPlaylists}
        >
            <h2><Translate id='playlist_add_playlist_title' /></h2>
            <PlaylistDetailed playlist={playlist}/>
            <GeneralError error={errorPlaylists || errorFloorPlaylists} />
            <StandaloneFieldError refForward={errorRef} errors={errorAddPlaylist} fieldname='playlist' />
            <Subnavigation
                title={<Translate id='subnav_overview_select' />}
                menuPoints={[
                    {
                        icon: 'list', 
                        name: 'playlist_overview', 
                        active: viewMode === 'Overview', 
                        hoverMsg: 'playlist_overview_explain', 
                        onClick: () => setViewMode('Overview')
                    },
                    pipp ? {
                        icon: 'folder', 
                        name: 'playlist_explorer', 
                        active: viewMode === 'Explorer', 
                        hoverMsg: 'playlist_explorer_advanced_explain', 
                        onClick: () => setViewMode('Explorer')
                    } : undefined,
                    {
                        icon: 'folder', 
                        name: 'floor_explorer', 
                        active: viewMode === 'Floor', 
                        hoverMsg: 'playlist_primary_floor_explain', 
                        onClick: () => setViewMode('Floor')
                    }
                ]}
            />
            {viewMode === 'Overview' && 
                <PlaylistOverview
                    autoSize
                    heading={'playlist_owner'}
                    descriptionText='overview_playlist_add_playlist_description' 
                    playlists={ownerPlaylists} 
                    loading={loadingPlaylists || loadingFloorPlaylists} 
                    menuPoints={p => [{icon: 'check-square', label: 'playlist_add', onClick: () => playlistSelected(p)}]}
                    sort={{key: 'name', asc: true}}
                />
            }
            {viewMode === 'Explorer' && 
                <PlaylistExplorer
                    title='playlist_explorer_title' 
                    descriptionText='overview_playlist_add_playlist_description' 
                    playlists={ownerPlaylists} 
                    playlistMenuPoints={p => [{icon: 'check-square', label: 'playlist_add', onClick: () => playlistSelected(p)}]} 
                    desiredElementWidth={180}
                />
            }
            {viewMode === 'Floor' && 
                <PlaylistExplorer
                    allowAllNavigate
                    title='playlist_explorer_title' 
                    descriptionText='overview_playlist_add_playlist_description' 
                    playlists={floorPlaylists}
                    playlistMenuPoints={p => [{icon: 'check-square', label: 'playlist_add', onClick: () => playlistSelected(p)}]}
                    desiredElementWidth={180}
                />
            }
            {convertToViewModeDialog &&
                <ViewModeWarning
                    playlist={convertToViewModeDialog}
                    onCancel={() => showConvertToViewModeDialog(undefined)}
                    onConfirm={() => addPlaylist(convertToViewModeDialog)}
                />
            }
        </Dialog>
    )
}

export default PlaylistAddPlaylist;