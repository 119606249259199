import { useEffect } from 'react';
import useLogout from '../services/CustomHooks/UseLogout';
import { useNavigate } from 'react-router-dom';


const Logout = () => {
    const navigate = useNavigate();
    const logOutFunc = useLogout();

    useEffect(() => {
        logOutFunc();
        navigate("/login");
    }, [logOutFunc, navigate])

    return null;
}

export default Logout;