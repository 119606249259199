import React, { FormEvent, useContext, useEffect, useState } from 'react';
import Dialog from "../Dialog";
import { accountIsChildAllowed } from '../../model/AccountType';
import { Button, SelectList, SubmitButton } from './FormGroup';
import { GeneralError } from '../Error/GeneralError';
import { getLocaleStorageItem, saveLocaleStorageItem } from '../../services/LocalStorageService';
import { Floor } from '../../model/Floor';
import { FetchError, isFetchError } from '../../services/FetchHelper';
import { ActiveContext, SecureContext } from '../_MyFloor/MyFloorApp';
import Translate from '../Helper/Translate';
import OrganizationPicker from './Organization/OrganizationPicker';
import { FloorContext } from '../../api/FloorContext';

interface Props {
    onChoose: (floorId: string, rowIndex: number) => void;
    onClose: () => void;
    onBulkClick?: () => void;
    error?: FetchError;
    loading?: boolean;
}

interface AddSettings{
    floorId: string|undefined;
    rowIndex: number;
}

const defaultSettings: AddSettings = { floorId: undefined, rowIndex: 0};

const FloorChooser = (props: Props) => {
    const {error, loading, onChoose, onClose, onBulkClick} = props;
    const {me, myOrg} = useContext(SecureContext);
    const {activeOrg: _a, setActiveOrg} = useContext(ActiveContext);
    
    const [getFloors, loadingFloors] = FloorContext.useOrgFloors();

    const [chooseActiveOrg, showChooseActiveOrg] = useState<boolean>(false);
    const activeOrg = _a || myOrg;
    const savedSettings = getLocaleStorageItem(`floor_add_org_${activeOrg?.id}`, defaultSettings);
    const [settings, setSettings] = useState<AddSettings>(savedSettings);
    const {floorId, rowIndex} = settings;
    const [floors, setFloors] = useState<Floor[]>();

    

    useEffect(() => {
        //when floors is updated, if the selected floorId doesn't exist in the collection change to the first available floor.
        if(floors && floors.length > 0 && !floors.find(x => x.id === settings.floorId)){
            setSettings({floorId: floors[0].id, rowIndex: 0});
        }
    }, [floors, settings.floorId]);
    
    const options = floors ? floors.map((f) => ({ name: f.name, value: f.id})) : undefined;
    const floor = floors && floors.find(x => x.id === floorId);
    const rowOptons = [{ name: `1. ${floor?.row1Name || ''}`, value: '0' }, { name: `2. ${floor?.row2Name || ''}`, value: '1' }, { name: `3. ${floor?.row3Name || ''}`, value: '2' }];

    useEffect(() => {
        if(activeOrg?.id) getFloors(activeOrg.id).then(x => !isFetchError(x) && setFloors(x));
    }, [activeOrg?.id, getFloors]);

    useEffect(() => {
        if(activeOrg?.id){
            setSettings(getLocaleStorageItem(`floor_add_org_${activeOrg?.id}`, defaultSettings));
        }
    }, [activeOrg?.id]);

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if(activeOrg && floorId){
            onChoose(floorId, rowIndex);
            saveLocaleStorageItem(`floor_add_org_${activeOrg.id}`, settings);
        }
    }

    return (
        <Dialog onClose={onClose} loading={loadingFloors || loading} className={'floor-chooser'}>
            {
                accountIsChildAllowed(me) &&
                (
                    <div className='admin-options'>
                        {onBulkClick &&
                            <Button icon='bullhorn' name={'bulk_add'} onClick={onBulkClick} />
                        }
                        <Button name='organization_title'  icon='building' onClick={() => showChooseActiveOrg(true)} />
                    </div>
                )
            }
            <h2><Translate id='floor_add' /></h2>
            <form onSubmit={onSubmit}>
                <GeneralError error={error} />
                {options && <SelectList
                    labelName='floor_name'
                    name="floorId"
                    options={options}
                    defaultValue={floorId}
                    onChange={(e) => setSettings(s => ({...s, floorId: e.target.value}))}
                    labelWidth100
                />}
                <SelectList
                    name='row'
                    labelName='row'
                    options={rowOptons}
                    defaultValue={rowIndex.toString()}
                    onChange={(e) => setSettings(s => ({...s, rowIndex: parseInt(e.target.value)}))}
                    labelWidth100
                />
                
                <SubmitButton split />
            </form>
            {chooseActiveOrg &&
                <OrganizationPicker
                    closeOnChoose
                    onChoose={setActiveOrg} 
                    onClose={() => showChooseActiveOrg(false)} 
                />
            }
        </Dialog>
    )
};

export default FloorChooser;