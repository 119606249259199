import React, { MouseEvent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BaseGame } from '../../model/Game/BaseGame';
import Translate from '../Helper/Translate';

interface Props{
    game?: BaseGame;
    startVisible?: boolean;
    publishedRequirements: RequirementProps[];
    publicRequirements: RequirementProps[];
    openBaseEdit: () => void;
}

const RequirementsJourney = (props: Props) => {
    const {game, publishedRequirements, publicRequirements, openBaseEdit, startVisible} = props;

    const [visible, setVisible] = useState(startVisible ?? true);


    const publishReq = [
        {done: !!game?.title, name: 'game_title_required',  action: openBaseEdit},
        {done: !!game?.image, name: 'game_image_required',  action: openBaseEdit},
        ...publishedRequirements
    ];

    const publicReq = [
        {done: !!game?.isPublished, name: 'game_is_published_required',  action: openBaseEdit},
        {done: !!game?.description, name: 'game_description_required',  action: openBaseEdit},
        {done: !!game?.languageCode, name: 'game_language_required',  action: openBaseEdit},
        ...publicRequirements
    ];

    const allFilled = publishReq.find(x => !x.done) === undefined && publicReq.find(x => !x.done) === undefined;
    if(allFilled && game?.isPublic) return null;

    return(
        <div className={`requirements-journey ${visible ? '' : 'hidden'}`} onClick={() => setVisible(v => !v)} dir='auto'>
            <ul>
                {!game?.isPublished ?
                    <>
                        <li><Translate id='game_publish_missing_title' /></li>
                        <ul>
                            {publishReq.map(x => <Requirement key={x.key || x.name} {...x} />)}
                        </ul>
                    </>
                    :
                    <Requirement done={!!game.isPublished} name={'game_is_published_required_complete'} action={openBaseEdit} />
                }
            </ul>
            <ul>
                {!game?.isPublic ?
                    <>
                        <li><Translate id='game_public_missing_title' /></li>
                        <ul>
                            {publicReq.map(x => <Requirement key={x.key || x.name} {...x} />)}
                        </ul>
                    </>
                    :
                    <Requirement done={!!game.isPublic} name={'isPublic'} action={openBaseEdit} />
                
                }
            </ul>
            <div className='toggle'>
                <FontAwesomeIcon icon={visible ? 'chevron-circle-right' : 'chevron-circle-left' } />
            </div>
        </div>
    )
}

export default RequirementsJourney;

export interface RequirementProps{
    key?: React.Key;
    done: boolean;
    name: string;
    translationData?: {[key: string]: string|number};
    action?: () => void;
}

const Requirement = (props: RequirementProps) => {
    const {done, name, translationData, action} = props;

    const onClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        action && action();
    }

    return(
        <li className={done ? 'done' : 'missing'}>
            {done && <FontAwesomeIcon icon={'check'} />} <span onClick={onClick}><Translate id={name} data={translationData} /></span>
        </li>
    )
}