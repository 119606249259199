import { useCallback } from "react";
import { ContextFunc, useFetchGet} from "../services/FetchHelper";
import { BaseGame } from "../model/Game/BaseGame";

interface IDiscoverContext{
    useTrendingGames: ContextFunc<BaseGame[], []>;
}

export const DiscoverContext: IDiscoverContext = {
    useTrendingGames: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame[]>();
        const invoke = useCallback(() => rawInvoke(`api/discover/trending/games`), [rawInvoke]);
        return [invoke, loading, error];
    }
}