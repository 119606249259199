import React from 'react';
import { GameType, GameTypesMyFloor, GameTypesMyFloorStatic } from '../../../model/Game/GameType';
import { GameIcons } from '../../Helper/GameIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Translate from '../../Helper/Translate';

interface Props{
    values: GameType[];
    onChange: (e: GameType[]) => void;
}

//PresentationGame is the old game aka "DemoMenu", it's now obsolete. Don't confuse it with "Presentation".
//Not quite ready to remove it from the gameTypes yet, so its filtered here to avoid user confusion.
export const ChartGameTypes = GameTypesMyFloor.filter(x => x !== "PresentationGame");
export const ChartGameTypesStatic: GameType[] = GameTypesMyFloorStatic;
export const ChartGameTypeContent = ChartGameTypes.filter(x => !ChartGameTypesStatic.includes(x));

const GameTypePickerIcons = (props: Props) => {
    const {values, onChange} = props;
    
    return(
        <div className='gametype-picker-icons'>
            {ChartGameTypes.map(x => <Item key={x} gameType={x} selected={values.includes(x)} onClick={() => onChange(values.includes(x) ? values.filter(v => v !== x) : [...values, x])} />)}
        </div>
    )
}

interface ItemProps{
    gameType: GameType;
    selected: boolean;
    onClick: () => void;
}

const Item = (props: ItemProps) => {
    const {gameType, selected, onClick} = props;

    return(
        <div className={`item hover-trigger${selected ? ' selected' : ''}`} onClick={onClick}>
            <img src={GameIcons[gameType]} alt={gameType} />
            <FontAwesomeIcon icon={'check-circle'} />
            <div className='hover-msg'><Translate id={`gametype_${gameType}`} /></div>
        </div>
    )
}

export default GameTypePickerIcons;