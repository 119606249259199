import React, {Component} from 'react';
import ReactCrop, {Crop} from 'react-image-crop';
import Dropzone, { FileError, FileRejection } from 'react-dropzone';
import 'react-image-crop/dist/ReactCrop.css';
import {Loading} from "../../Loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ImageUploadType} from '../../../model/ImageUploadType';

interface Props {
    aspectRatio: number | undefined;
    minRatio?: number;
    maxRatio?: number;
    onSubmit: (img: ImageUploadType) => void;
    previewWidth: number;
    previewHeight: number;
    realSizeOrPreviewMax?: boolean; 
    fixedAspect?: boolean;
    onInputError: (errors: FileError[]) => void; 
}
interface State {
    crop: Crop | undefined;
    image: HTMLImageElement | undefined;
    fileEnding: string;
    cropsrc: string;
    fixedAspect: boolean;
    isLoading: boolean;
    scale: number;
}

class QuickImage extends Component<Props, State>{
    initialAspect: number | undefined;

    constructor(props: Props){
        super(props);
        this.initialAspect = props.aspectRatio; 
        this.state = {
            image: undefined,
            crop: undefined,
            cropsrc: '',
            fileEnding: 'unknown',
            isLoading: false,
            scale: 1,
            fixedAspect: !!props.fixedAspect,
        };
    }

    submit = (crop: Crop, scale: number, image: HTMLImageElement) => {
        if (crop && this.state.cropsrc && image){

            if(crop.x === undefined || crop.y === undefined
                || !crop.width || !crop.height) return;

            const image = new Image();
            image.src = this.state.cropsrc;

            //find the correct aspect for the preview image
            const aspect = getCorrectAspectRatio(this.props.aspectRatio, 
                            this.props.minRatio, this.props.maxRatio, crop.width, crop.height); 
            const data : ImageUploadType = {
                base64Image: this.state.cropsrc,
                x: Math.round(crop.x * scale) || 0,
                y: Math.round(crop.y * scale) || 0,
                width: Math.min(
                    Math.round(crop.width * scale) || 0,
                    image.naturalWidth
                ),
                height: Math.min(
                    Math.round(crop.height * scale) || 0,
                    image.naturalHeight
                ),
                fileEnding: this.state.fileEnding,
                aspectRatio: aspect,
                previewBlob: this.getCroppedPreview(image, crop, aspect),
                salt: Math.random(),
                previewHeight: this.props.previewHeight, 
                previewWidth: this.props.previewWidth
            }; 

            //Make sure startpoint is inside img
            data.x = Math.max(data.x, 0);
            data.y = Math.max(data.y, 0);
            //Make sure width and height doesn't exceed image width/height
            const maxWidthFromX = image.naturalWidth - data.x;
            const maxHeightFromY = image.naturalHeight - data.y;
            data.width = Math.min(data.width, maxWidthFromX);
            data.height = Math.min(data.height, maxHeightFromY);
            data.previewHeight = this.props.realSizeOrPreviewMax ? Math.min(this.props.previewHeight, data.height) : this.props.previewHeight; 
            data.previewWidth = this.props.realSizeOrPreviewMax ? Math.min(this.props.previewWidth, data.width) : this.props.previewWidth; 
            this.props.onSubmit(data);
        }
    };

    getCroppedPreview = (
        image: HTMLImageElement, crop: Crop | undefined, aspect: number) => {
        if (!crop || crop.width === undefined || crop.height === undefined) return '';
        const canvas = document.createElement('canvas');

        const ctx = canvas.getContext('2d');
        if (ctx === null) return '';

        const currentAspect = crop.width / crop.height;
        let desiredWidth, desiredHeight;

        canvas.width = this.props.previewWidth;
        canvas.height = this.props.previewHeight;
        //adjust previewWidth if either width/height is auto
        if (this.props.previewHeight === 0)
        {
            canvas.height = Math.round(this.props.previewWidth / aspect);
        }
        else if(this.props.previewWidth === 0)
        {
            canvas.width = Math.round(this.props.previewHeight * aspect);
        }

        //set desired height width factor. defaults to const (1)
        if(currentAspect !== aspect){
            if (currentAspect < aspect)
            {
                desiredHeight = 1;
                const fullPixelHeight = crop.height;
                const fullPixelWidth = fullPixelHeight * aspect;
                desiredWidth = crop.width / fullPixelWidth;
            }
            else
            {
                desiredWidth = 1;
                const fullPixelWidth = crop.width;
                const fullPixelHeight = fullPixelWidth / aspect;
                desiredHeight = crop.height / fullPixelHeight;
            }
        }

        const x = (1 - (desiredWidth || 1)) / 2 * canvas.width;
        const y = (1 - (desiredHeight || 1)) / 2 * canvas.height;


        ctx.drawImage(
            image,
            Math.floor((crop.x || 0) * this.state.scale),
            Math.floor((crop.y || 0) * this.state.scale),
            Math.floor(crop.width * this.state.scale),
            Math.floor(crop.height * this.state.scale),
            Math.floor(x),
            Math.floor(y),
            Math.floor((desiredWidth || 1) * canvas.width),
            Math.floor((desiredHeight || 1) * canvas.height)
        );


        return canvas.toDataURL('image/png');
    };

    calculateMaxSize = (img: HTMLImageElement|undefined) => {
        if(!img) return {x: 0, y: 0, width: 1, height: 1};

        if (this.state.fixedAspect && this.initialAspect) {
            //calculate new cut
            let x = 0, y = 0;
            let height = img.height;
            let width = img.height * this.initialAspect;

            while (width - x >= img.width) {
                height = height * 0.99;
                width = height * this.initialAspect;
            }

            //center the crop
            x = Math.floor((img.width - width) / 2);
            y = Math.floor((img.height - height) / 2);
            return {x, y, width, height}
        }

        return {
            x: 0,
            y: 0,
            height: img.height,
            width: img.width,
        };
    }

    onImageLoaded = (image: HTMLImageElement) => {
        const crop: Crop = this.calculateMaxSize(image);
        const scale = image.naturalWidth / image.width;


        this.setState({
            isLoading: false,
        });

        this.submit(crop, scale, image);
    };

    fileDropped = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        const reader = new FileReader();
        const file = acceptedFiles[0];
        if (rejectedFiles.length) {
            this.props.onInputError(rejectedFiles[0].errors);
        }
        if (file){
            reader.onload = (upload) => {
                const target = (upload.target as FileReader);
                target.result && this.setState({ "cropsrc" : target.result as string, fileEnding: file.name.split(".").pop() || 'unknown'});
            };
            reader.readAsDataURL(file);
            this.setState({
                isLoading: true
            });
        }
    };


    render(){
        return (
            <div className='quick-upload'>
                <Loading visible={this.state.isLoading}/>
                <div className='drop-container'>
                    <Dropzone onDrop={this.fileDropped} accept={['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/jpe']}>
                        {({getRootProps, getInputProps, isDragActive}) => {
                            return (
                                <div {...getRootProps()} className={`dropzone${isDragActive ? ' active' : ''} `}>
                                    <input {...getInputProps()}/>
                                    <FontAwesomeIcon icon='upload' />
                                </div>
                            )
                        }}
                    </Dropzone>
                    <ReactCrop src={this.state.cropsrc} onChange={() => {}} onImageLoaded={this.onImageLoaded} style={{display: 'none'}} />
                </div>
            </div>
        );
    }
}

export default QuickImage;

const getCorrectAspectRatio = ( 
    aspectRatio: number | undefined, 
    minAspect: number | undefined, 
    maxAspect: number | undefined, 
    cropWidth: number, 
    cropHeight: number
    ) : number => {
        if (aspectRatio) return aspectRatio; 
        const currentAspect = cropWidth / cropHeight;
        // isSmallerThanMinAspect
        if (!!minAspect && minAspect > currentAspect) return minAspect;
        // isLargerThanMaxAspect
        if (!!maxAspect  && maxAspect < currentAspect) return maxAspect; 
        return cropWidth / cropHeight;
}