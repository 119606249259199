import React, {FormEvent, useState} from 'react'
import { AccountContext } from '../../../api/AccountContext';
import { isFetchError } from '../../../services/FetchHelper';
import Dialog from '../../Dialog';
import Translate from '../../Helper/Translate';
import {SubmitButton} from "../FormGroup";
import InputFormGroup from '../FormGroups/InputFormGroup';

interface Props{
    onClose: () => void;
}

interface ChangePasswordModel{
    oldPassword: string;
    password: string;
    passwordRepeat: string;
}

const ChangePasswordForm = (props: Props) => {
    const {onClose} = props;
    const [model, setModel] = useState<ChangePasswordModel>({
        oldPassword: '',
        password: '',
        passwordRepeat: '',
    });

    const [invoke, loading, passwordError] = AccountContext.useChangePassword();
    
    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const result = await invoke(model.oldPassword, model.password, model.passwordRepeat);
        if(!isFetchError(result)){
            onClose();
        }
    };
    
    return (
        <Dialog onClose={onClose} loading={loading}>
            <h2><Translate id='change_password_title'/></h2>
            <form onSubmit={onSubmit}>
                <InputFormGroup
                    name={'oldPassword'}
                    type={'password'}
                    value={model.oldPassword}
                    onChange={e => setModel(x => ({...x, [e.target.name]: e.target.value}))}
                    placeholder={'old_password'}
                    inputIcon={'lock'}
                    errors={passwordError}
                    noLabel
                />

                <InputFormGroup
                    name={'password'}
                    type={'password'}
                    value={model.password}
                    onChange={e => setModel(x => ({...x, [e.target.name]: e.target.value}))}
                    placeholder={'password'}
                    inputIcon={'lock'}
                    errors={passwordError}
                    noLabel
                />
                <InputFormGroup
                    name={'passwordRepeat'}
                    type={'password'}
                    value={model.passwordRepeat}
                    onChange={e => setModel(x => ({...x, [e.target.name]: e.target.value}))}
                    placeholder={'password_repeat'}
                    inputIcon={'lock'}
                    errors={passwordError}
                    noLabel
                />
                <SubmitButton text={'change_password'} split />
            </form>
        </Dialog>
    );
}

export default ChangePasswordForm;