import React from 'react';
import loadingImg from '../images/load.png';

interface Props{
    visible?: boolean;
    noBackground?: boolean;
}

export const Loading = (props: Props) => {
    return(
        <div className={`loading-visuals ${props.noBackground ? 'no-background' : ''} ${props.visible ? 'shown' : ''}`}>
            <img className='loading-image' src={loadingImg} alt=''/>
        </div>
    );
};