import React, {CSSProperties} from 'react';
import {TextAlignment, TextType, TextTypeStyle} from "../model/Game/TextType";

export const defaultEmptyText: TextType = { content: "", fontColorHex: "#FFFFFF", fontFamily: "Lato", fontSize: 14, textAlignment: TextAlignment.Left};
export const defaultEmptyTextBlack: TextType = { content: "", fontColorHex: "#000000", fontFamily: "Lato", fontSize: 14, textAlignment: TextAlignment.Left};
export const defaultEmptyTextStyle: TextTypeStyle = { fontColorHex: "#FFFFFF", fontFamily: "Lato", fontSize: 14, textAlignment: TextAlignment.Left};

interface Props{
    element: string;
    text: TextType|undefined;
    overrideFontSize?: string;
    className?: string;
    style?: CSSProperties;
    scale?: number;
    refObject?: React.RefObject<HTMLDivElement>;
    defaultAlignment?: TextAlignment|'initial';
}

const getAlignString = (align: TextAlignment|'initial') => {
    if(align === 'initial') return 'initial';
    return align === TextAlignment.Left
        ? 'left'
        : align === TextAlignment.Center
            ? 'center'
            : "right"
}

export const fontFamily = (family: string) => `${family}, "Lato", "Secular One"`;

const TextView = (props: Props) => {
    const { scale: _scale, text, defaultAlignment, refObject } = props;
    const scale = _scale || 1;

    const align = text?.textAlignment || defaultAlignment || TextAlignment.Center;

    const style: CSSProperties = text
        ? {
            ...props.style,
            fontSize: props.overrideFontSize ? props.overrideFontSize : (text.fontSize * scale),
            color: text.fontColorHex,
            fontFamily: fontFamily(text.fontFamily),
            textAlign: getAlignString(align)
         }
    : {...props.style};
    return React.createElement(props.element, { style, ref: refObject, className: `myfloor-text${props.className ? ` ${props.className}` : ''}`, dir: 'auto'}, text?.content || "");

}

export default TextView;