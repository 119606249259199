import React, {Component} from 'react';
import ReactCrop, {Crop} from 'react-image-crop';
import Dropzone, { FileRejection } from 'react-dropzone';
import 'react-image-crop/dist/ReactCrop.css';
import {Loading} from "../../Loading";
import {FormButton, CheckBox, SubmitButton, Button} from "../FormGroup";
import {ImageUploadType} from '../../../model/ImageUploadType';
import Translate from '../../Helper/Translate';

interface Props {
    minRatio: number;
    maxRatio: number;
    onSubmit: (img: ImageUploadType) => void;
    previewWidth: number;
    previewHeight: number;
    realSizeOrPreviewMax?: boolean; 
    forceFixedAspect?: boolean;
    displayedAspect?: [number, number];
    fixedAspect?: boolean;
}
interface State {
    crop: Crop | undefined;
    fileEnding: string;
    cropsrc: string;
    fixedAspect: boolean;
    isLoading: boolean;
    image: HTMLImageElement | undefined;
    scale: number;
    forceFixedAspect?: boolean;
    error?: string; 
}


class ImageForm extends Component<Props,State>{

    constructor(props: Props){
        super(props);
        this.state = {
            image: undefined,
            crop: undefined,
            cropsrc: '',
            fileEnding: 'unknown',
            fixedAspect: !!props.fixedAspect,
            isLoading: false,
            scale: 1,
            forceFixedAspect: props.forceFixedAspect
        };
    }

    isAllowedAspect = (width: number, height: number) => {
        const a = width/height
        return a === this.props.minRatio || (a >= this.props.minRatio && a <= this.props.maxRatio);
    }
    
    getAspectRatio = (width: number, height: number) => {
        const a = width/height
        //clamp the aspect between min and max aspect;
        return Math.min(Math.max(a, this.props.minRatio), this.props.maxRatio);
    }
    

    submit = () => {
        if (this.state.crop && this.state.cropsrc && this.state.image){
            const crop = this.state.crop;

            if(crop.x === undefined || crop.y === undefined
                || !crop.width || !crop.height) return;

            const image = new Image();
            image.src = this.state.cropsrc;
            const w = Math.min(
                Math.round(crop.width * this.state.scale) || 0,
                this.state.image.naturalWidth
            );
            const h = Math.min(
                Math.round(crop.height * this.state.scale) || 0,
                this.state.image.naturalHeight
            );
            const data : ImageUploadType = {
                base64Image: this.state.cropsrc,
                x: Math.round(crop.x * this.state.scale) || 0,
                y: Math.round(crop.y * this.state.scale) || 0,
                width: w,
                height: h,
                fileEnding: this.state.fileEnding,
                aspectRatio: this.getAspectRatio(w, h),
                previewBlob: this.getCroppedPreview(image, crop),
                salt: Math.random(), 
                previewHeight: this.props.previewHeight, 
                previewWidth: this.props.previewWidth
            };
            //Make sure startpoint is inside img
            data.x = Math.max(data.x, 0);
            data.y = Math.max(data.y, 0);
            //Make sure width and height doesn't exceed image width/height
            const maxWidthFromX = this.state.image.naturalWidth - data.x;
            const maxHeightFromY = this.state.image.naturalHeight - data.y;
            data.width = Math.min(data.width, maxWidthFromX);
            data.height = Math.min(data.height, maxHeightFromY);
            data.previewHeight = this.props.realSizeOrPreviewMax ? Math.min(this.props.previewHeight, data.height) : this.props.previewHeight; 
            data.previewWidth = this.props.realSizeOrPreviewMax ? Math.min(this.props.previewWidth, data.width) : this.props.previewWidth; 
            this.props.onSubmit(data);
        }
    };

    cropChange = (crop: Crop) => {
        this.setState({ crop });
    };
    
    getCroppedPreview = (image: HTMLImageElement, crop: Crop | undefined) => {
        if (!crop || crop.width === undefined || crop.height === undefined) return '';
        const canvas = document.createElement('canvas');

        const ctx = canvas.getContext('2d');
        if (ctx === null) return '';

        const cropAspect = crop.width / crop.height;
        let desiredWidth, desiredHeight;
        const isFixedAspect = this.props.previewHeight && this.props.previewWidth;
        const originalAspect = crop.width / crop.height;
        const isSmallerThanMinAspect = this.props.minRatio && this.props.minRatio > originalAspect;
        const isBiggerThanMaxAspect = this.props.maxRatio  && this.props.maxRatio < originalAspect;

        //find the correct aspect for the preview image
        const aspect = this.getAspectRatio(crop.width, crop.height);

        canvas.width = this.props.previewWidth;
        canvas.height = this.props.previewHeight;
        //adjust previewWidth if either width/height is auto
        if (this.props.previewHeight === 0)
        {
            canvas.height = Math.round(this.props.previewWidth / aspect);
        }
        else if(this.props.previewWidth === 0)
        {
            canvas.width = Math.round(this.props.previewHeight * aspect);
        }

        //set desired height width factor. defaults to const (1)
        if(isFixedAspect || isSmallerThanMinAspect || isBiggerThanMaxAspect){
            if (cropAspect < aspect)
            {
                desiredHeight = 1;
                const fullPixelHeight = crop.height;
                const fullPixelWidth = fullPixelHeight * aspect;
                desiredWidth = crop.width / fullPixelWidth;
            }
            else
            {
                desiredWidth = 1;
                const fullPixelWidth = crop.width;
                const fullPixelHeight = fullPixelWidth / aspect;
                desiredHeight = crop.height / fullPixelHeight;
            }
        }

        //Center the image in the padded canvas.
        const x = (1 - (desiredWidth || 1)) / 2 * canvas.width;
        const y = (1 - (desiredHeight || 1)) / 2 * canvas.height;


        ctx.drawImage(
            image,
            Math.floor((crop.x || 0) * this.state.scale),
            Math.floor((crop.y || 0) * this.state.scale),
            Math.floor(crop.width * this.state.scale),
            Math.floor(crop.height * this.state.scale),
            Math.floor(x),
            Math.floor(y),
            Math.floor((desiredWidth || 1) * canvas.width),
            Math.floor((desiredHeight || 1) * canvas.height)
        );

        return canvas.toDataURL('image/png');
        
        //AWAIT BROWSER SUPPORT
        /*return new Promise((resolve, reject) => {
            canvas.toBlob(file => {
                file.name = fileName;
                resolve(file);
            }, 'image/jpeg');
        });*/
    };

    calculateMaxSize = (img: HTMLImageElement|undefined) => {
        if(!img) return {x: 0, y: 0, width: 1, height: 1};

        if (this.state.fixedAspect) {
            //calculate new cut
            const a = this.getAspectRatio(img.width, img.height)
            let x = 0, y = 0;
            let height = img.height;
            let width = img.height * a;

            while (width - x >= img.width) {
                height = height * 0.99;
                width = height * a;
            }

            //center the crop
            x = Math.floor((img.width - width) / 2);
            y = Math.floor((img.height - height) / 2);
            return {x, y, width, height}
        }

        return {
            x: 0,
            y: 0,
            height: img.height,
            width: img.width,
        };
    }

    setMaxCropSize = () => {
        this.setState({crop: this.calculateMaxSize(this.state.image)})
    };

    onImageLoaded = (image: HTMLImageElement) => {
        const crop: Crop = {
            ...this.calculateMaxSize(image),
            aspect: this.props.fixedAspect ? this.getAspectRatio(image.width, image.height) : undefined
        };

        const scale = image.naturalWidth / image.width;
        this.setState({
            image: image,
            crop: crop,
            isLoading: false,
            scale: scale
        });
        return false;//must return false when changing state in crop..
    };

    calculateRestrictedCrop = (x: number, y: number,width: number, height: number, maxWidth: number, maxHeight: number) => {
        const a = this.getAspectRatio(width, height);
        const correctionToWidth = width - (height * a);
        const correctionToHeight = height - (width / a);
        const shouldCorrectWidth = correctionToWidth < correctionToHeight;

         //first calculate the side that needs to be corrected, then set the other according to the corrected.
         let w = 0;
         let h = 0;
         if(shouldCorrectWidth){
             w = Math.min(height * a, maxWidth);
             h = w / a;
         }
         else{
             h = Math.min(width / a, maxHeight);
             w = h * a;
         }          

         return {
            x: x + w > maxWidth ? maxWidth - w : x,
            y: y + h > maxHeight ? maxHeight - h : y,
            width: w, 
            height: h
        };
    }

    setFixedAspect = (b: boolean) => {
        if(this.props.forceFixedAspect) return;

         //calculate new cut
         if(this.state.image && this.state.crop && this.state.crop.width && this.state.crop.height){
            if(b){
                const imgWidth = this.state.image.width;
                const imgHeight = this.state.image.height;
                const x = this.state.crop.x ?? 0;
                const y = this.state.crop.y ?? 0;
                const height = this.state.crop.height;
                const width = this.state.crop.width;

                const restrictedCrop = this.calculateRestrictedCrop(x, y, width, height, imgWidth, imgHeight);
               
                this.setState({
                    fixedAspect: b,
                    crop: {
                        ...restrictedCrop,
                        aspect: (b && this.props.minRatio === this.props.maxRatio ) ? this.props.minRatio : undefined,
                    }
                });
            }
            else{
                this.setState({ fixedAspect: b, crop: {...this.state.crop, aspect: undefined} });
            }
        }

        
    };

    fileDropped = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        const reader = new FileReader();
        const file = acceptedFiles[0];
        
        if (rejectedFiles.length) {
            this.setState(x => ({...x, error: rejectedFiles[0].errors.length ? rejectedFiles[0].errors[0].code : "unknown_file_upload_error"})); 
        }
        else{
            this.setState(x => ({...x, error: undefined})); 
        }
        if (file){
            reader.onload = (upload) => {
                const target = (upload.target as FileReader);
                target.result && this.setState({ "cropsrc" : target.result as string, fileEnding: file.name.split(".").pop() || 'unknown'});
            };
            reader.readAsDataURL(file);
            this.setState({
                isLoading: true
            });
        }
    };

    render(){
        const disable = !this.state.crop || !this.state.cropsrc;
        //const [a,b] = this.props.displayedAspect || getReducedAspectRatio(this.props.previewWidth, this.props.previewHeight);
        return (
            <div className='image-crop-form'>
                <Loading visible={this.state.isLoading}/>
                <h2><Translate id='image'/></h2>
                <div className='drop-container'>
                    <div>
                        <Dropzone onDrop={this.fileDropped} accept={['image/jpeg', 'image/png', 'image/gif', 'image/bmp']}>
                            {({ getRootProps, getInputProps, isDragActive }) => {
                                return (
                                    <div {...getRootProps()} className={`dropzone${isDragActive ? ' active' : ''} `}>
                                        <input {...getInputProps()} />
                                        <p><Translate id='dropzone_upload_text' /></p>
                                    </div>
                                )
                            }}
                        </Dropzone>
                        {this.state.error && 
                            <div className='form-error-container'>
                                <div className='form-error'>
                                <Translate id={this.state.error==='file-invalid-type' ? 'file_invalid_type_placeholder' : this.state.error} data={this.state.error==='file-invalid-type' ? {formats: "'.jfif', '.pjpeg', '.jpeg', '.pjp', '.jpg', '.png', '.gif', '.bmp'"} : undefined}/>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {this.state.cropsrc && !this.state.forceFixedAspect &&
                    <>
                        { this.props.minRatio === this.props.maxRatio &&
                            <CheckBox name={'fixedAspect'} label={'image_fixedAspect'} active={this.state.fixedAspect} onChange={() => this.setFixedAspect(!this.state.fixedAspect)}/>
                        }
                        { this.props.minRatio !== this.props.maxRatio &&
                            <Button 
                                name='image_conform_to_aspect_interval' 
                                disabled={this.isAllowedAspect(this.state.crop?.width ?? 1, this.state.crop?.height ?? 1)} 
                                disabledHoverMsg='image_conform_to_aspect_interval_disabled'
                                onClick={() => this.setFixedAspect(true)} 
                            />
                        }
                        
                        <FormButton onClick={() => this.setMaxCropSize()} name={'image_setmax'} />
                    </>
                }
                <div className='crop-container'>
                    {this.state.cropsrc &&
                        <ReactCrop minHeight={10} minWidth={10} keepSelection src={this.state.cropsrc} onChange={this.cropChange}  crop={this.state.crop}  onImageLoaded={this.onImageLoaded} />
                    }
                </div>
                <SubmitButton split text={'submit'} onClick={this.submit} disabled={disable} />
            </div>

        );
    }
}

export default ImageForm;