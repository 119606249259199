import React, {useContext, useEffect, useRef, useState} from 'react';
import Dialog from "../Dialog";
import GameDetailed from "../ModelPreview/GameDetailed";
import { accountHasAnyPermission } from '../../model/AccountType';
import { GeneralError } from '../Error/GeneralError';
import { PlaylistContext } from '../../api/PlaylistContext';
import { PlayListType } from '../../model/PlayListType';
import { GlobalModalContext, SecureContext } from '../_MyFloor/MyFloorApp';
import { isFetchError } from '../../services/FetchHelper';
import { BaseGame } from '../../model/Game/BaseGame';
import { useLocaleStorageItem } from '../../services/LocalStorageService';
import StandaloneFieldError from './StandaloneFieldError';
import Subnavigation from '../Navigation/Subnavigation';
import PlaylistOverview from '../PlayList/PlaylistOverview';
import PlaylistExplorer from '../PlayList/Explorer/PlaylistExplorer';
import Translate from '../Helper/Translate';

interface Props{
    game: BaseGame;
    onClose: () => void;
}

const viewModeKey = "playlist_add_game_viewmode";

const AddGameToPlaylist = (props: Props) => {
    const {game, onClose} = props;
    const {popMsg} = useContext(GlobalModalContext);
    const {me} = useContext(SecureContext);

    const errorRef = useRef<HTMLDivElement>(null);
    const [playlists, setPlaylists] = useState<PlayListType[]>([]);
    const [floorPlaylists, setFloorPlaylists] = useState<PlayListType[]>([]);
    const [viewMode, setViewMode] = useLocaleStorageItem<'Overview'|'Explorer'|'Floor'>(viewModeKey,  "Overview");

    const [getPlaylists, loadingPlaylists, errorPlaylists] = PlaylistContext.usePlaylists();
    const [getFloorPlaylists, loadingFloorPlaylists, errorFloorPlaylists] = PlaylistContext.usePrimaryFloorPlaylists();
    const [_addGame, loadingAddGame, errorAddGame] = PlaylistContext.useAddGame();
    
    const error = errorPlaylists || errorFloorPlaylists;
    const meHasFloorPermission = accountHasAnyPermission(me, 'Floor', 'Admin');

    useEffect(() => {
        if(game){
            getPlaylists().then(x => !isFetchError(x) && setPlaylists(x));
            if(me && accountHasAnyPermission(me, 'Floor', 'Admin') ){
                getFloorPlaylists().then(x => !isFetchError(x) && setFloorPlaylists(x));
            }
        }
    }, [game, getPlaylists, getFloorPlaylists, me]);
    
    if(!game) return null;
    if(me == null) return null;

    const pipp = accountHasAnyPermission(me, 'Admin', 'PlaylistInPlaylist');

    const addGame = async (pid: string) => {
        if(game){
            const result = await _addGame(pid, game.id);
            if(!isFetchError(result)){
                popMsg("pop_added", "pop_msg_playlist_game_add", {name: game.title});
                setPlaylists(x => x.map(pl => pl.id === result.id ? result : pl));
                if(meHasFloorPermission){
                    setFloorPlaylists(x => x.map(pl => pl.id === result.id ? result : pl));
                } 
            }
            else{
                errorRef.current?.scrollIntoView({behavior: "smooth"});
            }
        }
    }

    const ownerPlaylists = playlists.filter(x => x.ownerId === me.id || (x.authorIds && x.authorIds.includes(me.id)));

    return(
        <Dialog onClose={onClose} className='playlist-chooser' loading={loadingAddGame}>
            <h2><Translate id='playlist_put_game' data={{game: game.title}}/></h2>
            <GameDetailed game={game} gameType={game.gameType} hideTranslations/>
            <GeneralError error={error || errorAddGame} />
            <StandaloneFieldError refForward={errorRef} errors={errorAddGame} fieldname='game' />
            <Subnavigation
                title={<Translate id='subnav_overview_select' />}
                menuPoints={[
                    {
                        icon: 'list', 
                        name: 'playlist_overview', 
                        active: viewMode === 'Overview', 
                        hoverMsg: 'playlist_overview_explain', 
                        onClick: () => setViewMode('Overview')
                    },
                    pipp ? {
                        icon: 'folder', 
                        name: 'playlist_explorer', 
                        active: viewMode === 'Explorer', 
                        hoverMsg: 'playlist_explorer_advanced_explain', 
                        onClick: () => setViewMode('Explorer')
                    } : undefined,
                    {
                        icon: 'folder', 
                        name: 'floor_explorer', 
                        active: viewMode === 'Floor', 
                        hoverMsg: 'playlist_primary_floor_explain',
                         onClick: () => setViewMode('Floor')
                    }
                ]}
            />
            {viewMode === 'Overview' && 
                <PlaylistOverview 
                    autoSize
                    heading={"playlist_owner"}
                    playlists={ownerPlaylists} 
                    loading={loadingPlaylists || loadingFloorPlaylists} 
                    desiredElementWidth={180}
                    menuPoints={p => [{icon: 'check-square', label: 'playlist_add', onClick: () => addGame(p.id)}]}
                    descriptionText={'overview_game_add_playlist_description'}
                    sort={{key: 'name', asc: true}}
                />
            }
            {viewMode === 'Explorer' && 
                <PlaylistExplorer
                    title='playlist_explorer_title' 
                    playlists={ownerPlaylists} 
                    playlistMenuPoints={p => [{icon: 'check-square', label: 'playlist_add', onClick: () => addGame(p.id)}]} 
                    desiredElementWidth={180}
                    descriptionText={'explorer_game_add_playlist_description'}
                />
            }
            {viewMode === 'Floor' && 
                <PlaylistExplorer 
                    allowAllNavigate
                    title='playlist_explorer_title' 
                    playlists={floorPlaylists}
                    playlistMenuPoints={p => [{icon: 'check-square', label: 'playlist_add', onClick: () => addGame(p.id)}]}
                    desiredElementWidth={180}
                    descriptionText={'explorer_game_add_playlist_description'}
                />
            }
        </Dialog>
    );
};

export default AddGameToPlaylist;