import { MathClamp } from "../services/CustomFunctions";
import { SortByCustom } from "../services/SortFunctions";
import { DbModel } from "./DbModel";
import { PlatformType } from "./PlatformType";
import { BulkPrice, Currency } from "./Price";
import {MinimalOrganization} from "./Response/MinimalOrganization";

export interface Organization extends DbModel {
    createdEpoch: number;
    name: string;
    systemName: string;
    country: string;
    state: string; 
    city: string;
    primaryLanguageCode?: string;
    type: string;
    contactName: string;
    contactPhone: string;
    contactEmail: string;
    invoiceContactName?: string;
    invoiceContactEmail?: string;
    licenseContactName?: string;
    licenseContactEmail?: string;
    accountIds: string[];
    childOrganizationIds: string[];
    baseFloorPrices?: BulkPrice[];
    baseWallPrices?: BulkPrice[];
    economicCustomerNumber?: string;
    invoiceHeading?: string;
    dealRegistrationContactId?: string;
    categories: OrganizationCategory[];
    allowCustomerAutoRenew: boolean;
    allowRenewFreeStartDate: boolean;
    useOrgPricesOverLicense: boolean;
    floorCount: number;
    wallCount: number;

    isReseller: boolean; //backend calculated field
}


export interface TempOrganization{
    id: string;
    loading: boolean;
}


export const newOrganiztion: Organization = {
    createdEpoch: 0,
    id: '',
    systemName: "",
    type: "",
    country: "",
    state: "",
    city: "",
    name: "",
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    invoiceContactEmail: "",
    invoiceContactName: "",
    accountIds: [],
    childOrganizationIds: [],
    baseFloorPrices: [
        {value: 495, currency: Currency.DKK, bulkAmount: 0}, 
        {value: 395, currency: Currency.DKK, bulkAmount: 12}, 
        {value: 295, currency: Currency.DKK, bulkAmount: 24},
        {value: 195, currency: Currency.DKK, bulkAmount: 36},
    ],
    baseWallPrices: [
        {value: 495, currency: Currency.DKK, bulkAmount: 0}, 
        {value: 395, currency: Currency.DKK, bulkAmount: 12}, 
        {value: 295, currency: Currency.DKK, bulkAmount: 24},
        {value: 195, currency: Currency.DKK, bulkAmount: 36},
    ],
    economicCustomerNumber: "",
    invoiceHeading: '',
    isReseller: false,
    categories: [],
    allowCustomerAutoRenew: true,
    useOrgPricesOverLicense: false,
    allowRenewFreeStartDate: false,
    floorCount: 0,
    wallCount: 0
};

export const getBasePricesForPlatform = (platform: PlatformType, org: Organization) => {
    if(platform === PlatformType.Floor){
        if (!org.baseFloorPrices?.length || !org.baseFloorPrices[0].value) return undefined; 
        return org.baseFloorPrices; 
    }
    if(platform === PlatformType.Wall){
        if (!org.baseWallPrices?.length || !org.baseWallPrices[0].value) return undefined; 
        return org.baseWallPrices
    }
    return undefined;
}
export const OrganizationCategories = ["Academy", "Advertising", "Daycare", "Demo", "EducationCenter", "Fitness", "Foundation", "Goverment", "HigherEducation", "Highschool", "Hospital",
"Kindergarden", "Library", "MiddleSchool", "Municipality", "Museum", "Nursery", "Other", "Park", "PrimarySchool", "Private", "Public", "Religious", "Restaurant",
"SalesPartner", "School", "ScienceCenter", "Secondary", "Senior", "SpecialEducation", "Sport", "Store", "SwimmingPool", "Temporary", "Test", "TouristCenter",
"University"] as const;
export type OrganizationCategory = typeof OrganizationCategories[number];


export const getPriceForFrequency = (org: Organization|undefined, freq: number|undefined, platform: PlatformType) => {
    if(!freq || !org) return undefined;
    freq = MathClamp(freq, 1, 120);
    const prices = getBasePricesForPlatform(platform, org);   
    if (!prices) return undefined; 
    const sortedPrices = [...prices].sort((a,b) => SortByCustom(a,b,'bulkAmount', false));
    for(const price of sortedPrices){
        if(freq >= price.bulkAmount) return price;
    }
    return sortedPrices[sortedPrices.length-1];
}

export function isOrganizationType(arg: string | Organization | TempOrganization | undefined): arg is Organization {
    return !!arg &&
        typeof arg !== "string" &&
        !!arg.id &&
        "systemName" in arg &&
        "name" in arg;
}

export function isMinOrgType(arg: MinimalOrganization | TempOrganization | undefined): arg is MinimalOrganization {
    return !!arg &&
        !("loading" in arg) &&
        "name" in arg;
}

export function isResellerOrg(arg: string | Organization | TempOrganization | undefined): arg is Organization{
    return isOrganizationType(arg) && ((arg.childOrganizationIds && arg.childOrganizationIds.length > 0) || arg.type === "Reseller" || arg.categories.includes("SalesPartner"))
}