import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props{
    id: string|undefined;
    data?: {[key: string]: string|number};
}

const Translate = (props: Props) => {
    const {id, data} = props;
    const {t} = useTranslation();

    return <>{data ? t(id ?? '', data) : t(id ?? '')}</>;

}

export default Translate;