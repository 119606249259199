import React, {useEffect, useState} from 'react';
import Dialog from "../Dialog";
import {GameStats} from "../../model/GameStats";
import Game from "../ModelPreview/Game";
import { isFetchError } from '../../services/FetchHelper';
import { GameContext } from '../../api/Game/GameContext';
import { Loading } from '../Loading';
import Translate from '../Helper/Translate';
import PlaylistOverview from '../PlayList/PlaylistOverview';
import ItemContainer from '../ModelPreview/ItemContainer';
import { CombiChartWithData } from '../StatCharts/BaseCharts/CombiChart';
import { useTranslation } from 'react-i18next';

interface Props{
    gameId: string;
}

const GameRelatedInfo = (props: Props) => {
    const {gameId} = props;
    const {t} = useTranslation();
    const [stats, setStats] = useState<GameStats>();
    const [showPlaylistDialog, setShowPlaylistDialog] = useState(false);
    const [showSimilarDialog, setShowSimilarDialog] = useState(false);

    const [fetchStats, loadingStats] = GameContext.useStats();

    useEffect(() => {
        if(gameId){
            fetchStats(gameId).then(x => !isFetchError(x) && setStats(x));
        }
        else{
            setStats(undefined);
        }
    }, [gameId, fetchStats]);

    useEffect(() => {
        setShowPlaylistDialog(false);
        setShowSimilarDialog(false);
    }, [stats])
    
    if(!stats) return null;

    return(
        <div className='game-related'>
            <Loading visible={loadingStats} />
            <div>
                <h2><Translate id='info' /></h2>
                <div className='group btn' onClick={() => setShowPlaylistDialog(true)}>
                    <label><Translate id='playlists' /></label>
                    <span>{stats.playlistCount}</span>
                </div>
                <div className='group btn' onClick={() => setShowSimilarDialog(true)}>
                    <label><Translate id='more_like_this' /></label>
                    <span>{stats.moreLikeThis.length}</span>
                </div>
                <div className='chart'>
                    <CombiChartWithData
                        data={stats?.gameUsage}
                        loading={loadingStats}
                        initialType='line'
                        label={() => t("played_count")}
                        disableTypeChoice
                    />
                </div>
            </div>

            {showSimilarDialog &&
            <Dialog onClose={() => setShowSimilarDialog(false)}>
                <ItemContainer
                    heading={<h2><Translate id='games' /></h2>}
                    items={stats.moreLikeThis}
                    itemRender={x => <Game key={x.id} game={x} />}
                />
            </Dialog>
            }
            {stats && showPlaylistDialog &&
                <Dialog onClose={() => setShowPlaylistDialog(false)}>
                    <PlaylistOverview
                        heading={"playlists"} 
                        playlists={stats.playlists} 
                        sort={{
                            key: 'name',
                            asc: true
                        }} 
                    />
                </Dialog>
            }
        </div>
    )
}

export default GameRelatedInfo;