import React, { useEffect, useState } from 'react';

interface Props{
    path: string;
}

const SlideShow = (props: Props) => {
    const {path} = props;
    const [imgUrls, setImgUrls] = useState<string[]>([]);
    const [active, setActive] = useState<number>(0);
    const [timeId, setTimeId] = useState<ReturnType<typeof setTimeout>>();

    //if the path changes, reset the images!
    useEffect(() => {
        setImgUrls([]);
        setActive(0);
    }, [path]);

    useEffect(() => {
        const checkImg = (p: string, i: number) => {
            const url = `${p}${i !== 0 ? i : ''}.png`;
            const xhr = new XMLHttpRequest();
            xhr.open('HEAD', url, true);
            xhr.send();
            xhr.onloadend = (e) => {
                if(xhr.getResponseHeader('content-type') !== 'image/png'){
                    return;
                }
                else{
                    //filter to make sure we don't show images from other skins
                    setImgUrls(urls => ([...urls.filter(x => x.startsWith(p)), url]));
                    checkImg(p, ++i);
                }
            }
            return xhr;
        }
        const xhr = checkImg(path, 0)
        return () => xhr.abort();
    }, [path]);

    useEffect(() => {
        setTimeId(t => {
            t && clearTimeout(t);
            return setTimeout(() => setActive(a => a + 1 >= imgUrls.length ? 0 : a + 1), 10000);
        });
    }, [active, imgUrls]);
    useEffect(() => () => timeId && clearTimeout(timeId), [timeId]);

    if(!imgUrls) return null;
    const imgSrc = imgUrls[active];
    return(
        <div className={`preview-slide-show`}>
            {imgUrls.length > 1 &&
                <div className='selector'>
                    {imgUrls.map((src, i) => (
                        <div
                            key={src}
                            onClick={() => setActive(i)}
                        >
                            <img src={src} className={`test ${active === i ? ' active' : ''}`} alt='' />
                        </div>
                    ))}
                </div>
            }
            <div className='container'>
                <div className='skin-preview'>
                    <img src={imgSrc} alt='' />
                </div>
            </div>

        </div>

    )
}

export default SlideShow;