import React, { useEffect, useState } from 'react';
import { StatsContext } from '../../api/StatsContext';
import { StatsFilters } from '../../model/Stats/StatsFilters';
import { CombiChartWithData } from './BaseCharts/CombiChart';
import { ChartData } from '../../model/Stats/PieData';
import { isFetchError } from '../../services/FetchHelper';
import InputFormGroup from '../forms/FormGroups/InputFormGroup';
import { MinimalOrganization } from '../../model/Response/MinimalOrganization';

interface Props{
    filters: StatsFilters;
    showDuration?: boolean;
}

const GameOrgUsageChart = (props: Props) => {
    const {filters, showDuration} = props;

    const [getData, loading] = StatsContext.useGameUsageByOrg();
    const [data, setData] = useState<ChartData<MinimalOrganization>>();
    const [gameId, setGameId] = useState<string>("");

    useEffect(() => {
        if(filters.orgId && gameId){
            getData(filters, gameId).then(x => !isFetchError(x) ? setData(x) : setData(undefined));
        }
    },[filters, getData, gameId])

    return(
        <div className='chart-with-input'>
            <InputFormGroup 
                name='gameId'
                label='form_game_id'
                value={gameId}
                onChange={e => setGameId(e.target.value)}
                type='text'
                noUnsavedChanges
            />
            <CombiChartWithData
                label={x => x?.data?.name ?? "Unknown"}
                initialType='pie'
                data={data} 
                loading={loading}
                showDuration={showDuration}
            />
        </div>
        
    )
}

export default GameOrgUsageChart;