import React, { ReactElement, ReactNode, useState } from "react";
import DatePicker from "react-datepicker";
import moment, {Moment} from "moment";
import Translate from "../../Helper/Translate";
import FieldErrors from "../FieldErrors";
import UnsavedChangesIcon from "./UnsavedChangesIcon";
import { useTranslation } from "react-i18next";
import { getFnsLocale } from "../../../model/LocalizationVariables";
import { FetchError } from "../../../services/FetchHelper";
import { ChangeEvent } from "../../../model/ChangeEvent";

interface Props {
    name: string;
    value: Moment;
    label?: string;
    noLabel?: boolean;
    onChange?: (e: ChangeEvent<Moment>) => void;
    onChangeUnix?: (e: ChangeEvent<number>) => void;
    labelWidth100?: boolean;
    errors?: FetchError;
    disabled?: boolean;
    tabIndex?: number;
    extraNode?: ReactElement;
    hideUnsaved?: boolean;
    hovermsg?: ReactNode; 
}

export const DatePickerFormGroup = (props: Props) => {
    const {name, value, label, noLabel, onChange, onChangeUnix, labelWidth100, errors, disabled, tabIndex, extraNode, hideUnsaved, hovermsg} = props;
    const {i18n} = useTranslation();
    const [_o] = useState(value);

    return(
        <div className={`form-group date-picker${hovermsg ? ' hover-trigger' : ''}`} dir='auto'>
            {!noLabel &&
                <label className={labelWidth100 ? 'full-width' : ''} htmlFor={name}>
                    <Translate id={label || name}/>
                </label>
            }
            <DatePicker
                className={`form-control dir-${i18n.dir(i18n.resolvedLanguage)}`}
                name={name}
                selected={value.unix() === 0 ? undefined : value.toDate()}
                dateFormat={'PP'}
                onChange={e => {
                    const mom = Array.isArray(e) ? moment.unix(0) : e ? moment(e).startOf('day') : moment.unix(0);
                    onChange && onChange({target: {name, value: mom}})
                    onChangeUnix && onChangeUnix({target: {name, value: mom.unix()}})
                }}
                maxDate={new Date("9999-01-01")}
                dropdownMode='scroll'
                locale={getFnsLocale(i18n.language)}
                disabled={disabled}
                tabIndex={tabIndex}
                showYearDropdown
                fixedHeight
            />
            { extraNode }
            { errors && <FieldErrors errors={errors} fieldname={name} /> }
            {!hideUnsaved && <UnsavedChangesIcon show={_o.unix() !== value.unix()}/>}
            { hovermsg && <div className="hover-msg">{hovermsg}</div> }
        </div>
    );
};

export default DatePickerFormGroup;