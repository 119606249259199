import React from 'react';
import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import Translate from '../Helper/Translate';
import { arrayToClassName, notUndefined } from '../../services/CustomFunctions';

interface NavProps{
    icon: IconProp;
    path: string;
    onClick: () => void;
}

interface Props extends NavProps{
    subNav?: (NavProps|undefined)[];
}

const getIsActive = (path: string, pathname: string) => {
    if(!path) return pathname === "/";
    return pathname.indexOf(path) !== -1;
};

const NavMenuItem = (props: Props) => {
    const {onClick, icon, path, subNav} = props;
    const location = useLocation();
    const pathname = location.pathname;
    const isActive = getIsActive(path, pathname);
    
    return(
        <div 
            className={
                arrayToClassName(["nav-item", path || 'myfloor', isActive ? 'active' : undefined].filter(notUndefined)
            )}
        >
            <Link to={`/${path}`} onClick={onClick}>
                <FontAwesomeIcon icon={icon} className='nav-icon'/> <Translate id={`menu_${path ? path : 'myfloor'}`}/>
            </Link>

            {isActive && subNav?.filter(notUndefined).map(x => 
                <div key={x.path} className={arrayToClassName(['sub-nav', getIsActive(x.path, pathname) ? 'active' : undefined].filter(notUndefined))}>
                    <Link to={`/${path}/${x.path}`} onClick={x.onClick}>
                        <FontAwesomeIcon icon={x.icon} className='nav-icon'/> <Translate id={`menu_${x.path}`}/>
                    </Link>
                </div>
            )}
        </div>
    )
}

export default NavMenuItem;