import React, { useContext } from 'react';
import FloorChooser from '../forms/FloorChooser';
import { isFetchError } from '../../services/FetchHelper';
import { BaseGame } from '../../model/Game/BaseGame';
import { GlobalModalContext } from '../_MyFloor/MyFloorApp';
import { FloorContext } from '../../api/FloorContext';

interface Props{
    game: BaseGame;
    onClose: () => void;
}

const GameAddToFloor = (props: Props) => {
    const {game, onClose} = props;
    const {popMsg} = useContext(GlobalModalContext);

    const [add, loading, error] = FloorContext.useAddGame();

    const onChoose = async (floorId: string, rowIndex: number) => {
        const result = await add(floorId, game.id, rowIndex);
        if(!isFetchError(result)){
            popMsg("pop_added", "pop_msg_floor_game_add", {name: game?.title || ''});
            onClose();
        }
    }

    if (!game) return null;

    return <FloorChooser
        onChoose={onChoose}
        loading={loading}
        onClose={onClose}
        error={error}
    />
};

export default GameAddToFloor;