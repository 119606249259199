import React from 'react';
import { PlayListType } from '../../model/PlayListType';
import { BaseGame } from '../../model/Game/BaseGame';
import { Loading } from '../Loading';
import Game from '../ModelPreview/Game';
import leftarrow from '../../images/left-arrow.png';
import rightarrow from '../../images/right-arrow.png';
import controlStub from '../../images/controlbackground-stub.png';
import { notUndefined } from '../../services/CustomFunctions';

interface Props{
    loading?: boolean;
    playlist: PlayListType;
    games: BaseGame[];
}

const PlaylistFloorDefaultPreview = (props: Props) => {
    const {loading, playlist, games} = props;

    const items = playlist.combinedSort.map(x => {
        const game = games.find(g => g.id === x.id) ?? undefined;
        return game;
    });

    return(
        <div className='playlist-floor-preview default'>
            <Loading visible={loading} />
            <div className='items'>
                {items.filter(notUndefined).filter(x => x.isPublished).filter((_,i) => i < 6).map(game => {
                    if(game && game.isPublished) return <Game key={game.id} game={game} width={70} onlyImage hideBadges noMenu />;
                    return undefined;
                })}
            </div>

            <div className='name'>{playlist.name}</div>
            <img className='arrow left'  src={leftarrow}  alt='left-navigation' />
            <img className='arrow right' src={rightarrow} alt='right-naviation' />
            <div className='pull-up-menu-container'>
                <img className='pull-up-menu' src={controlStub} alt='pull up menu'/>
            </div>
        </div>
    )
}

export default PlaylistFloorDefaultPreview;