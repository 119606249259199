import React, {useEffect, useState} from "react";
import {MinimalOrganization} from "../../model/Response/MinimalOrganization";
import { Link } from "react-router-dom";
import { OrganizationContext } from "../../api/OrganizationContext";
import { isFetchError } from "../../services/FetchHelper";

interface Props{
    orgId: string;
    miniOrg?: MinimalOrganization;
}

export const OrganizationName = (props: Props) => {
    const {orgId, miniOrg} = props;

    const [getOrg] = OrganizationContext.useOrganizationMinimal();

    const [_org, setOrg] = useState<MinimalOrganization>();
    const org = miniOrg || _org;


    useEffect(() => {
        if(orgId && !miniOrg){
            getOrg(orgId).then(x => !isFetchError(x) && setOrg(x));
        }
    }, [getOrg, orgId, miniOrg]);

    return(
        
        <div className='org-name' dir='auto' >
            <Link to={`/discover/org/${orgId}`}>
                {org && org.name}
            </Link>
        </div>
    )
};