import { useCallback } from "react";
import { FeaturedGame } from "../model/FeaturedGame";
import { ContextFunc, useFetchDelete, useFetchGet, useFetchPost, useFetchPut } from "../services/FetchHelper";
import { BannerCreateModel, BannerEditModel } from "../components/Admin/CreateFeaturedGame";

interface IBannerContext{
    useBanners: ContextFunc<FeaturedGame[], []>;
    useAll: ContextFunc<{[key: string]: FeaturedGame}, []>;
    useRemove: ContextFunc<void, [string]>;
    useCreate: ContextFunc<FeaturedGame, [BannerCreateModel]>;
    useUpdate: ContextFunc<FeaturedGame, [string, BannerEditModel]>;
}

export const BannerContext: IBannerContext = {
    useBanners: () => {
        const [rawInvoke, loading, error] = useFetchGet<FeaturedGame[]>();
        const invoke = useCallback(() => rawInvoke('api/featured/game'), [rawInvoke]);
        return [invoke, loading, error];
    },
    useAll: () => {
        const [rawInvoke, loading, error] = useFetchGet<{[key: string]: FeaturedGame}>();
        const invoke = useCallback(() => rawInvoke('api/featured/game/all'), [rawInvoke]);
        return [invoke, loading, error];
    },
    useRemove: () => {
        const [rawInvoke, loading, error] = useFetchDelete<void>();
        const invoke = useCallback((id: string) => rawInvoke(`api/featured/game/${id}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useCreate: () => {
        const [rawInvoke, loading, error] = useFetchPost<FeaturedGame>();
        const invoke = useCallback((model: BannerCreateModel) => rawInvoke('api/featured/game', model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useUpdate: () => {
        const [rawInvoke, loading, error] = useFetchPut<FeaturedGame>();
        const invoke = useCallback((id: string, model: BannerEditModel) => rawInvoke(`api/featured/game/${id}`, model), [rawInvoke]);
        return [invoke, loading, error];
    }
}