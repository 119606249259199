import moment from "moment";
import { useState } from "react";

export function getLocaleStorageItem<T>(key: string, std: T, keysToRemove?: string[]): T {
    const fromStorage = localStorage.getItem(key);  
    const raw = fromStorage || JSON.stringify(std); 
    const reviver = (key: string, value: string) => {
        if(keysToRemove && keysToRemove.indexOf(key) !== -1) return undefined;
        return value; 
    }
    return JSON.parse(raw, reviver);
}

export function saveLocaleStorageItem<T>(key: string, item: T, keysToRemove?: string[]): T{
    const replacer = (key: string, value: unknown) => {
        if(keysToRemove && keysToRemove.indexOf(key) !== -1) return undefined;
        return value;
    }
    const str = JSON.stringify(item, replacer);
    localStorage.setItem(key, str);
    return JSON.parse(str);
}

// keysToRemove: properties to ignore
export const useLocaleStorageItem = <T extends unknown> (key: string, defaultValue: T, keysToRemove?: string[]): [T, (a: T) => void] => {
    const [item, _setItem] = useState(getLocaleStorageItem<T>(key, defaultValue, keysToRemove));
    const saveItem = (s: T) => {
        const saved = saveLocaleStorageItem(key, s, keysToRemove);
        _setItem(saved);
    }

    return [item, saveItem];
}

export const deleteLocalStorageItem = (key: string) => {
    localStorage.removeItem(key); 
}

export const tokenKey = "myfloorjwt";
export const tokenExpireKey = "myfloorjwt_expire";
export const profileImageKey = "profileImage";
export const myfloorLangKey = "myfloor_lang";
export const getExpireTime = () => {
    const item = localStorage.getItem(tokenExpireKey) 
    return item ? moment(item) : undefined;
};

//Return a token if it is not expired, otherwise undefined
export const getToken = () => {
    const expireTimeString = localStorage.getItem(tokenExpireKey) || '';
    const expireTime = moment(expireTimeString);
    if(expireTime && expireTime > moment()){
        return localStorage.getItem(tokenKey) ?? undefined;
    }
}
export const setToken = (token: string, expire: string) => {
    localStorage.setItem(tokenExpireKey, expire);
    localStorage.setItem(tokenKey, token);
}

export const getLocalStorageImage = () => {
    return localStorage.getItem(profileImageKey);
}

export const setLocalProfileImg = (imagePath: string) => {
    localStorage.setItem(profileImageKey, imagePath);
}

export const getLocalProfileImg = () => {
    return localStorage.getItem(profileImageKey);
}

export const getLocalLanguage = () => {
    return localStorage.getItem(myfloorLangKey);
}

export const setLocalLanguage = (lang: string) => {
    localStorage.setItem(myfloorLangKey, lang);
}