import React, {Component, ReactNode} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface Props {
    width: number;
    height: number;
    children: ReactNode[];
    className?: string;
    pageSize?: number;
    spacing: number;
}

interface State {
    containerWidth: number;
    width: number;
    height: number;
    currentPage: number;
    pageSize: number;
}

class ComponentSlider extends Component<Props, State>{
    containerRef: React.RefObject<HTMLDivElement>;

    constructor(props: Props){
        super(props);
        this.containerRef = React.createRef();

        this.state = {
            containerWidth: 0,
            width: this.props.width,
            height: this.props.height,
            currentPage: 0,
            pageSize: 0,
        };
    }

    remeasure = () => {
        if(this.containerRef.current){
            const containerWidth = this.containerRef.current.offsetWidth || 0;
            const desiredNumberResult = containerWidth / (this.props.width + this.props.spacing);
            const pageSize = this.props.pageSize || Math.ceil(desiredNumberResult);
            const totalSpacing = (pageSize * this.props.spacing);
            const desiredMatchWidth = (containerWidth  - totalSpacing) / pageSize;
            const desiredMatchScale = desiredMatchWidth / this.props.width;
            const desiredMatchHeight = this.props.height * desiredMatchScale;

            const itemCount = this.props.children ? this.props.children.filter(x => x).length : 0;
            const pageCount = Math.ceil(itemCount / pageSize);

            this.setState({
                containerWidth: containerWidth,
                width: desiredMatchWidth,
                height: desiredMatchHeight,
                pageSize: pageSize,
                currentPage: Math.min(Math.max(pageCount - 1, 0), this.state.currentPage)
            });
        }
    };

    componentDidMount() {
        this.remeasure();
        window.addEventListener('resize', this.remeasure);
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.remeasure);
    }

    componentDidUpdate(prevProps: Props, prevState: State){
        if(prevProps.children.length !== this.props.children.length){
            this.remeasure();
        }
    }

    render(){
        const props = this.props;
        const itemCount = this.props.children ? this.props.children.filter(x => x).length : 0;
        const pageCount = Math.ceil(itemCount / this.state.pageSize);
        const pageSize = 100 / pageCount;
        const canGoLeft = this.state.currentPage > 0;
        const canGoRight = pageCount && this.state.currentPage < pageCount - 1;

        const modifiedChildren = props.children && props.children.map((x, i) => {
            if (!x) return null;
            if (typeof x === 'string') return null;
            if (typeof x === 'number') return null;
            if (typeof x === 'boolean') return null;
            if (!('props' in x)) return null;

            const oldProps = x.props;
            const oldStyle = x.props.style || {};
            const newStyle = {...oldStyle, width: this.state.width, height: this.state.height, marginRight: this.props.spacing };
            const newProps = {...oldProps, width: this.state.width, style: newStyle, key: i, className: `${oldProps.className ? oldProps.className : ''} component-slide`};
            return React.cloneElement(x, newProps);
        });

        return(
            <div className={`component-slider-container ${props.className || ''}`} style={{height: this.state.height}}>
                <div className={`arrow left${canGoLeft ? ' active' : ''}`} style={{lineHeight: `${this.state.height}px`}} onClick={() => this.setState({currentPage: Math.max(0, this.state.currentPage - 1)})}><FontAwesomeIcon icon='angle-left' /></div>
                <div ref={this.containerRef} className='center'>
                    <div className='component-slider' style={{width: `${pageCount ? pageCount*100 : 9999}%`,transform: `translateX(-${ pageSize * this.state.currentPage}%)`}}>
                        {modifiedChildren}
                    </div>
                </div>
                <div className={`arrow right${canGoRight ? ' active' : ''}`} style={{lineHeight: `${this.state.height}px`}} onClick={() => this.setState({currentPage: Math.min(pageCount-1,this.state.currentPage + 1)})}><FontAwesomeIcon icon='angle-right' /></div>
            </div>
        )
    }
}

export default ComponentSlider;
