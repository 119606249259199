import * as React from 'react';
import App from './App';
import daTranslations from './localization/da/translations.json';
import enTranslations from './localization/en/translations.json';
import iwTranslations from './localization/he/translations.json';
import arTranslations from './localization/ar/translations.json';
import esTranslations from './localization/es/translations.json';
import nlTranslations from './localization/nl/translations.json';
import noTranslations from './localization/nb-NO/translations.json';
import svTranslations from './localization/sv-SE/translations.json';
import itTranslations from './localization/it/translations.json';
import deTranslations from './localization/de/translations.json';
import frTranslations from './localization/fr/translations.json';
import ltTranslations from './localization/lt/translations.json';
import csTranslations from './localization/cs/translations.json';
import skTranslations from './localization/sk/translations.json';
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { createRoot } from 'react-dom/client';
import './index.css';


//polyfill crypto.randomUUID
if(!Crypto.prototype.randomUUID){
    Crypto.prototype.randomUUID = () => {
        // eslint-disable-next-line
        return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c: any) => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        ) as `${string}-${string}-${string}-${string}-${string}`;
    }
}

// Create browser history to use in the Redux store
// const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') || '';
// export const history = createBrowserHistory({basename: baseUrl});

const activeLang = localStorage.getItem("myfloor_lang") || 'en';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            en: { translation: enTranslations },
            da: { translation: daTranslations },
            iw: { translation: iwTranslations },
            ar: { translation: arTranslations },
            es: { translation: esTranslations },
            nl: { translation: nlTranslations },
            no: { translation: noTranslations },
            sv: { translation: svTranslations },
            it: { translation: itTranslations },
            de: { translation: deTranslations },
            fr: { translation: frTranslations },
            lt: { translation: ltTranslations },
            cs: { translation: csTranslations },
            sk: { translation: skTranslations }
        },
        lng: activeLang, // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });


const container = document.getElementById('root');
const root = container && createRoot(container); 

root?.render(
    <DndProvider backend={HTML5Backend}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </DndProvider>
);

//registerServiceWorker();
