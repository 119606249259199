import React from 'react';
import Translate from '../Helper/Translate';
import { Message } from '../_MyFloor/MyFloorApp';

interface Props {
    messages: Message[];
}

const PopContainer = (props: Props) => {
    const {messages} = props;

    return (
        <div className='pop-container'>
            {messages.map(x => {
                return(
                    <div key={x.id} className={`msg ${x.state || ''} ${x.title === "pop_msg_error" ? ' error' : ''}`}>
                        <div className='msg-content' dir='auto'>
                            <div className='title'>
                                <Translate id={x.title} />
                            </div>
                            <p><Translate id={x.message} data={x.placeholderData || undefined} /></p>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default PopContainer;