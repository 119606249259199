import React, { FormEvent, useEffect, useState } from 'react';
import { BaseGame } from '../../../model/Game/BaseGame';
import GoogleTranslateList from '../../../model/LocalizationVariables';
import { FetchError, isFetchError } from '../../../services/FetchHelper';
import Dialog from "../../Dialog";
import Translate from '../../Helper/Translate';
import FieldErrors from '../FieldErrors';
import { CheckBox, SelectLanguage, SubmitButton } from "../FormGroup";
import { useNavigate } from 'react-router-dom';
import { GeneralError } from '../../Error/GeneralError';

interface Props {
    game: BaseGame;
    invoke: (languageCode: string, useGoogle: boolean, keepDocument: boolean) => Promise<BaseGame|FetchError>;
    onClose: () => void;
}
const CreateTranslationForm = (props: Props) => {
    const {game, invoke, onClose} = props;
    const navigate = useNavigate();
    const [languageCode, setLanguageCode] = useState(game.languageCode)
    const [ableToTranslate, setAbleToTranslate] = useState(!!(GoogleTranslateList.find(x => x === languageCode) && GoogleTranslateList.find(x => x === game.languageCode)));
    const [googleTranslate, setGoogleTranslate] = useState(ableToTranslate);
    const [keepDocument, setKeepDocument] = useState(game.languageCode === languageCode && !!game.document);
    const [error, setError] = useState<FetchError>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const canTranslate = !!(GoogleTranslateList.find(x => x === languageCode) && GoogleTranslateList.find(x => x === game.languageCode));
        setAbleToTranslate(canTranslate);
        if(!canTranslate){
            setGoogleTranslate(false);
        }
    }, [game, languageCode])

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        const result = await invoke(languageCode, googleTranslate, keepDocument);
        setLoading(false);
        if(isFetchError(result)){
            setError(result);
        }
        else{
            onClose();
            navigate(`/workshop/${result.gameType}/edit/${result.id}`);
        }
    }

    return(
        <Dialog
            onClose={onClose}
            loading={loading}
        >
            <form onSubmit={onSubmit} className='copy-form'>
                <h2><Translate id='copy_create' /></h2>
                <SelectLanguage
                    name={'originalLanguage'}
                    defaultValue={game.languageCode}
                    onChange={() => {}}   
                    errors={error}
                    disabled     
                    noLabel        
                />
                <SelectLanguage
                    name={'LanguageCode'} 
                    defaultValue={languageCode}
                    onChange={e => setLanguageCode(e.target.value)}   
                    errors={error}     
                    noLabel   
                    noUnsavedChanges     
                />
                <CheckBox
                    name={'googleTranslate'}
                    label={'use_google_translate'}
                    active={googleTranslate}
                    onChange={(e) => setGoogleTranslate(e.target.value)}
                    disabled={!ableToTranslate}
                    disabledHint={'google_cant_translate'}
                    errors={error}
                    hideUnsaved
                />
                <CheckBox
                    name={'keepDocument'}
                    label={'document_keep'}
                    active={keepDocument}
                    onChange={(e) => setKeepDocument(e.target.value)}
                    errors={error}
                    disabled={!game.document}
                    hideUnsaved
                />
                {game.document && keepDocument && googleTranslate && game.languageCode !== languageCode && <div className='form-group'><Translate id='document_no_translate_hint' /></div>}
                {error && <FieldErrors errors={error} fieldname={'preventCopy'} />}
                {error && <GeneralError error={error}/>}
                <SubmitButton
                    text='submit'
                    split
                    disabled={!languageCode}
                />
            </form>
        </Dialog>
    )
};

export default CreateTranslationForm;