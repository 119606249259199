import React, { FormEvent, useContext, useEffect, useState } from 'react';
import Dialog from "../../Dialog";
import ChangePasswordForm from "./ChangePasswordForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { CheckBox, FormButton, ImageFormButton, SelectList, SubmitButton} from "../FormGroup";
import {DbOrUploadImage} from "../../../services/ImageHelper";
import profile from '../../../images/missing-profile.png';
import { UpdateAccountRequest } from '../../../model/Request/UpdateAccountRequest';
import { Floor } from '../../../model/Floor';
import { isFetchError } from '../../../services/FetchHelper';
import { Loading } from '../../Loading';
import InputFormGroup from '../FormGroups/InputFormGroup';
import TextAreaFormGroup from '../FormGroups/TextAreaFormGroup';
import { GlobalModalContext, SecureContext } from '../../_MyFloor/MyFloorApp';
import { FloorContext } from '../../../api/FloorContext';
import { AccountContext } from '../../../api/AccountContext';
import { myfloorLocalizations } from '../../../model/LocalizationVariables';
import Translate from '../../Helper/Translate';
import { GeneralError } from '../../Error/GeneralError';

const AccountEditForm = () => {
    const {me} = useContext(SecureContext);
    const {showAccountEditForm, showTrackingOptions} = useContext(GlobalModalContext);
    const [changePassword, showChangePassword] = useState(false);

    const [floors, setFloors] = useState<Floor[]>([]);
    const [invokeGetFloors, loadingGetFloors] = FloorContext.useOrgFloors();
    const [invokeUpdateAccount, loadingUpdateAccount, errorUpdate] = AccountContext.useUpdateAccount();
    const [model, setModel] = useState<UpdateAccountRequest>({
        profileImage: me?.profileImage,
        firstname: me?.firstname || '',
        lastname: me?.lastname || '',
        description: me?.description || '',
        email: me?.email || '',
        language: me?.language || 'en',
        isPublic: me?.isPublic ?? false,
        favoriteFloorId: me?.favoriteFloorId
    });
    useEffect(() => {
        if(me?.organizationId){
            invokeGetFloors(me.organizationId).then(x => !isFetchError(x) && setFloors(x));
        }
        else{
            setFloors([]);
        }
        return () => {setFloors([]);};
    }, [me?.organizationId, invokeGetFloors]);
    
    if(!me) return null;

    if(changePassword){
        return <ChangePasswordForm onClose={() => showChangePassword(false)} />;
    } 

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const result = await invokeUpdateAccount(me.id, model);
        if(!isFetchError(result)){
            showAccountEditForm(undefined);
        }
    }

    return (
        <Dialog onClose={() => showAccountEditForm(undefined)} className='dialog-account' loading={loadingUpdateAccount}>
            <GeneralError error={errorUpdate}/>
            <div className='profile'>
                <DbOrUploadImage image={model.profileImage} tempImage={profile}/>
            </div>
            <form onSubmit={onSubmit}>
                <ImageFormButton 
                    name='profile_image'
                    value={model.profileImage} 
                    imageSubmit={(image) => setModel(x => ({...x, profileImage: image}))} 
                    previewWidth={500} 
                    previewHeight={500} 
                    errorKey={'ProfileImage'}
                    errors={errorUpdate}
                />
                <InputFormGroup
                    name='firstname'
                    placeholder='firstname'
                    type='text'
                    value={model.firstname}
                    onChange={e => setModel(x => ({...x, [e.target.name]: e.target.value}))}
                    errors={errorUpdate}
                    noLabel
                />
                <InputFormGroup
                    name='lastname'
                    placeholder='lastname'
                    type='text'
                    value={model.lastname}
                    onChange={e => setModel(x => ({...x, [e.target.name]: e.target.value}))}
                    errors={errorUpdate}
                    noLabel
                />
                <InputFormGroup
                    name='email'
                    placeholder='email'
                    type='text'
                    value={model.email}
                    onChange={e => setModel(x => ({...x, [e.target.name]: e.target.value}))}
                    errors={errorUpdate}
                    noLabel
                />
                <SelectList
                    name='language'
                    defaultValue={model.language}
                    options={myfloorLocalizations}
                    onChange={e => setModel(x => ({...x, [e.target.name]: e.target.value}))}
                    noLabel
                />
                <CheckBox
                    name={'isPublic'}
                    label={'account_public'}
                    active={model.isPublic}
                    onChange={e => setModel(x => ({...x, [e.target.name]: e.target.value}))}
                />
                <TextAreaFormGroup
                    name='description'
                    placeholder='account_description'
                    value={model.description}
                    onChange={e => setModel(x => ({...x, [e.target.name]: e.target.value}))}
                    errors={errorUpdate}
                    noLabel
                />
                <div className='form-group'>
                    <div className='link-text' onClick={() => showChangePassword(true)}><FontAwesomeIcon icon='lock' /> <Translate id='change_password' /></div>
                </div>
                <FormButton name='tracking_update_consent' onClick={() => showTrackingOptions(true)} />
                
                <div>
                    <Loading visible={loadingGetFloors} />
                </div>
                {floors && floors.length > 1 &&
                    <SelectList
                        labelWidth100
                        labelName='primary_floor'
                        name='favoriteFloorId'
                        defaultValue={model?.favoriteFloorId?.toString() || floors[0].id} 
                        onChange={e => setModel(x => ({...x, [e.target.name]: e.target.value}))} 
                        options={floors.map(f => ({name: f.name, value: f.id}))}
                    />
                }
                <SubmitButton split/>
            </form>
        </Dialog>
    );
};

export default AccountEditForm;