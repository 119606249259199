import React, { MouseEvent, ReactNode } from 'react';
import Sortable from '../../DragnDrop';

export interface CrumbProps{
    id: string;
    name: ReactNode;
    onClick: (e: MouseEvent) => void;
    onDropped?: (src: string) => Promise<void>;
    acceptedDropTypes: string[];
    draggedType?: string;
}

const BreadCrumbElement = (props: CrumbProps) => {
    const {id, name, onDropped, onClick, acceptedDropTypes, draggedType} = props;
    const node = <div className='node' onClick={onClick}>{name}</div>;

    if(onDropped){
        return(
            <Sortable
                type={'list'} 
                id={id} 
                onSort={() => {}} 
                onSortCommit={onDropped}
                notDraggable
                acceptDropTypes={id !== 'root' ? ['list', 'game'] : ['list']}
                className={acceptedDropTypes?.find(x => x === draggedType) ? 'dropable' : undefined}
            >
                {node}
            </Sortable>
        )
    }

    return node;
}

export default BreadCrumbElement;