import { RefObject, useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Floor } from "../model/Floor";
import { MenuItem } from "../model/MenuItem";
import { PopUp } from "../model/Game/Presentation/PopUp";
import { Dict } from "./JsDict";
import { PlayListType } from "../model/PlayListType";
import { PlaylistOverride } from "../model/PlaylistOverride";
import moment from "moment";

export const presentationPopUpIs16x10 = (popup: PopUp) => {
    return !popup?.videoInfo?.videoAspectRatio || popup?.videoInfo?.videoAspectRatio < 1.76;
}

export const toDict = <T extends unknown, T2 extends unknown> (items: T[], keyFunc: (x: T) => string, valueFunc: (x: T) => T2): Record<string, T2> => {
    return Object.fromEntries(items.map(x => [keyFunc(x), valueFunc(x)]));
}

export const copyToClipboard = (msg: string|number) => {
    navigator.clipboard.writeText(msg.toString());
};


export const combineRowContents = (floor: Floor|false|undefined, rowIndex: number) => {
    const playlists = (floor && floor.floorPlayLists && floor.floorPlayLists.filter(x => x.rowIndex === rowIndex)) || [];
    const games = (floor && floor.mainMenuGames && floor.mainMenuGames.filter(x => x.rowIndex === rowIndex)) || [];

    return [
        ...playlists.map(x => ({ id: x.playListId, type: "Playlist", sort: x.sortIndex, item: x } as MenuItem)),
        ...games.map(x => ({ id: x.gameId, type: 'Game', sort: x.sortIndex, item: x } as MenuItem))
    ]
}

export function notUndefined<T>(x: T | undefined): x is T {
    return x !== undefined;
}

export function escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function dragNdropOrder<T> (sourceId: string | number, targetId: string | number, idField: (x: T) => string | number, collection: T[]): T[]{
    const currentArray = collection ? [...collection] : [];
    const sourceIndex = currentArray.findIndex(x => idField(x) === sourceId);
    const targetIndex = currentArray.findIndex(x => idField(x) === targetId);
    const itemToMove = currentArray[sourceIndex];

    currentArray.splice(sourceIndex, 1);
    currentArray.splice(targetIndex, 0, itemToMove);
    return currentArray;
}

type UseTemporaryValueResult<T> = [T | undefined, (value: T | undefined) => void]; 
export const useTemporaryValue = <T extends unknown> (ms?: number) : UseTemporaryValueResult<T> => {
    const [value, _setValue] = useState<T>(); 
    const setValue = (value: T | undefined) => {
        _setValue(value); 
        setTimeout(() => _setValue(undefined), ms ?? 1000); 
    }; 
    return [value, setValue]; 
}


export const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        const updateSize = () => setSize([window.innerWidth, window.innerHeight]);
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

interface WindowingProps{
    min: number;
    max: number; 
    width: number; 
    spaceAbove: number; 
    spaceBelow: number;
}

export const useVisibleRows = (rowHeight: number, total: number, containerRef: RefObject<HTMLElement>): WindowingProps => {
    const [, windowHeight] = useWindowSize();
    const visibleRows = Math.round(windowHeight / rowHeight);
    const [values, setValues] = useState<WindowingProps>({min: 0, max: 0, width: 0, spaceAbove: 0, spaceBelow: 0});

    const onScroll = useCallback(() => {
        const y = Math.round(containerRef.current?.getBoundingClientRect()?.top ?? 0);
        const min = Math.min(Math.min(Math.max(0, Math.round(y * -1 / rowHeight)), total - visibleRows));
        const max = Math.min(Math.max(0, min + visibleRows), total);
        const spaceAbove = min * rowHeight;
        const spaceBelow = (total - min - Math.min(visibleRows, total)) * rowHeight;
        
        setValues(s => ({
            min, 
            max,
            spaceAbove,
            spaceBelow,
            width: Math.max(containerRef.current?.clientWidth ?? 0, s.width)
        }));
    }, [containerRef, rowHeight, visibleRows, total]);

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        onScroll();
        return () => window.removeEventListener("scroll", onScroll);
    }, [onScroll]);

    return values;
}

export const useIndexTraverser = <T>(elements: T[], timer: number ): T | undefined => {
    const [index, setIndex] = useState<number>(0);
    const [timeId, setTimeId] = useState<ReturnType<typeof setTimeout>>();
    useEffect(() => {
        setTimeId(t => {
            t && clearTimeout(t);
            return setTimeout(() => setIndex((index + 1) % (elements.length || 1)), timer);
        });
    }, [index, elements.length, timer]);
    useEffect(() => () => timeId && clearTimeout(timeId), [timeId]);
    return elements[index < elements.length ? index : 0]; // if elements array is shortened externally, the current index can be out of bounds
}


export const useAutoFitSize = <T extends HTMLElement> (itemWidth: number, spacing: number, containerRef: React.RefObject<T>) => {

    const [_itemWidth, setItemWidth] = useState(itemWidth);

    const totalWidth = containerRef.current?.offsetWidth;
    const [windowWidth] = useWindowSize();
    useEffect(() => {
        if(totalWidth){
            const totalWidthWithMargin = totalWidth + spacing - 1; //-1 pixel to avoid layoutrounding errors
            const noe = Math.round(totalWidthWithMargin / (itemWidth + spacing) - 0.2); //-0.2 To bias the round function to floor more than it ceils
            const width = (totalWidthWithMargin - (noe*spacing)) / noe;
            setItemWidth(width);
        }
        
    }, [totalWidth, windowWidth, itemWidth, spacing]);
    return _itemWidth;
    
}

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

// intended any value
// eslint-disable-next-line
export const advancedFilter = <T extends Record<string, any>> (elements: T[], filter: Partial<Record<keyof T, string|boolean|unknown>>): T[] => {
    for(const [k, v] of Object.entries(filter ?? [])){
        
        const key = k as (keyof T);
        if(key && typeof v === 'string' && v){
            elements = elements?.filter(f => {
                const value = f[key];
                if((typeof value === 'string')){
                    return value.search(new RegExp(escapeRegExp(v ?? ''), "ig")) !== -1;
                } 
                if(typeof value === 'number'){
                    if(isNaN(value)) return false;
                    const rangeV = typeof v === "string" ? parseFloat(v.substring(1)) : 0;
                    if(!isNaN(parseFloat(v))) return (value.toString()).search(new RegExp(escapeRegExp(v ?? ''), "ig")) !== -1;
                    if(v.startsWith("<")){
                        return value < rangeV;
                    }
                    if(v.startsWith(">")){
                        return value > rangeV;
                    }
                }
                return false;
            });
        } 
        if(key && typeof v === 'boolean'){
            elements = elements?.filter(f => f[key] === v);
        }
    }

    return elements;
} 

export const findMax = <T1 extends unknown, T2 extends unknown> (items: T1[], selector: (item: T1) => T2) => {
    if (!items.length) return undefined; 
    let max = selector(items[0]); 
    for (const i of items) {
        const temp = selector(i); 
        if (temp > max) max = temp; 
    }
    return max; 
}

export const MathClamp = (num: number, min: number, max: number) => Number.isNaN(num) ? min : Math.min(Math.max(num, min), max);

export const AddOrUpdate = <T extends {id: string | undefined}> (items: T[], item: T): T[] => [...new Map<string, T>([...items, item].map(x => [x?.id ?? '', x])).values()];

export const arrayUniqueByKey = <T,> (items: T[], key: keyof T) => [...new Map(items.map(item => [item[key], item])).values()];

export const capitalize = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const errorStringsFromError = (errors: undefined | Dict<string[]>, fieldname: string) => {
    if(!errors) return undefined;
    return errors[fieldname] ?? errors[capitalize(fieldname)];
}

export const regexMatch = (searchTerm: string, ...values: string[]) => {
    return values.filter(v => v.search(new RegExp(escapeRegExp(searchTerm ?? ''), "ig")) !== -1).length;
}

export const filterWithSearchTerm = <T extends unknown> (rawArray: T[], field: (itm: T) => string, term: string|undefined) => {
    if(!term) return rawArray;
    return rawArray.filter(x => regexMatch(term, field(x)));
} 

export const updateArrayItemsById = <T extends {id: string}> (src: T[], ...newItems: T[]) => {
    const itemDict = toDict(newItems, x => x.id, x => x);
    return src.map(x => itemDict[x.id] ?? x );
}

export const insertOrUpdateItem = <T extends {id: string}> (src: T[], newItem: T) => {
    let found = false;
    
    for (let i = 0; i < src.length; i++) {
        if(src[i].id === newItem.id){
            found = true;
            src = [...src];
            src[i] = newItem;
            break;
        }
    }
    if(!found) return [...src, newItem];
    return src;
}

export const insertOrUpdateItemByKey = <T,> (src: T[], newItem: T, key: keyof T) => {
    let found = false;
    
    for (let i = 0; i < src.length; i++) {
        if(src[i][key] === newItem[key]){
            found = true;
            src = [...src];
            src[i] = newItem;
            break;
        }
    }
    if(!found) return [...src, newItem];
    return src;
}


export const trimChar = (src: string|undefined, char: string) => {
    if(!src) return "";

    while(src.startsWith(char)){
        src = src.slice(char.length);
    }
    while(src.endsWith(char)){
        src = src.slice(0, src.length - char.length);
    }
    return src;
}
export const updateArrayItemsByIndex = <T extends unknown> (src: T[] | undefined, ...newItems: {item: T, index: number}[]) => {
    if(!src) return undefined; 
    const itemDict = toDict(newItems, x => x.index.toString(), x => x.item);
    return src.map((x, i) => itemDict[i.toString()] ?? x );
}

export const generateArray = <T extends unknown> (count: number, element: T) => {
    const array = []; 
    for (let i = 0;  i < count; i++){
        array.push(element); 
    }
    return array; 
}

export const applyPlaylistOverride = (playlist: PlayListType , po: PlaylistOverride) =>
{
    const pl = {...playlist};
    if (po == null) return pl;
    pl.games.filter(x => !po.gamesToRemove.includes(x.gameId));
    pl.nestedPlaylistIds.filter(x => !po.playlistsToRemove.includes(x.id));
    var sort = Math.max(...pl.combinedSort.map(x => x.sort)) || Number.MAX_VALUE;
    pl.games.concat(
        po.gamesToAdd
            .filter(x => !pl.games?.map(x => x.gameId).includes(x))
            .map(x => ({gameId: x, sort: ++sort}))
        );

    pl.nestedPlaylistIds.concat(
        po.playlistsToAdd
            .filter(x => !pl.nestedPlaylistIds?.map(x => x.id).includes(x))
            .map(x => ({id: x, sort: ++sort}))
        );

    for (let i = 0; i < (po.gameOrder?.length ?? 0); i++)
    {
        var game = pl.games.find(x => x.gameId === po.gameOrder[i]);
        if (game != null) game.sort = i;
        else
        {
            const playlist = pl.nestedPlaylistIds.find(x => x.id === po.gameOrder[i]);
            if(playlist!= null) playlist.sort = i;
        } 
    }

    pl.name = po.name?.content ?? pl.name;
    pl.fontFamily = po.name?.fontFamily ?? pl.fontFamily ;
    pl.fontColorHex = po.name?.fontColorHex ?? pl.fontColorHex;
    pl.fontSize = po.name?.fontSize ?? pl.fontSize;
    pl.description = po.description ?? pl.description;
    pl.labelColorHex = po.labelColorHex ?? pl.labelColorHex;
    pl.showLabel = po.showLabel ?? pl.showLabel;
    pl.showTitle = po.showTitle ?? pl.showTitle;
    pl.backgroundColorHex = po.backgroundColorHex ?? pl.backgroundColorHex;
    pl.image = po.image ?? pl.image;
    pl.viewMode = po.playlistsToAdd.length ? "Grid" : pl.viewMode;
    
    return pl;
}

export const arrayToClassName = (classes: (string | undefined | false)[]): string|undefined => {
    const filtered = classes.filter(x => x);
    return filtered.length ? filtered.join(" ") : undefined;
}

export const distinct = <T extends unknown> (a: T[]) => [...new Set(a)];

/** Returns any elements in a that doesn't have an id match in b */
export const difference = <T extends {id: string}> (a: T[], b: T[]) => a.filter(x => !b.find(f => f.id === x.id));
/** Returns any elements in a and b that doesn't have an id match the other */
export const symmetricDifference = <T extends {id: string}> (a: T[], b: T[]) => difference(a,b).concat(difference(b,a));

export const firstOrUndefined = <T> (elements: T[]) => elements.length ? elements[0] : undefined;
/** Returns the first element in a that doesn't have an id match in b or undefined if no such element */
export const firstDifference = <T extends {id: string}> (a: T[], b: T[]) => firstOrUndefined(difference(a, b));

//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
export const getRandomInt = (min: number, max: number) => {
    const minCeiled = Math.ceil(min);
    const maxFloored = Math.floor(max);
    return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); // The maximum is exclusive and the minimum is inclusive
}

export const GetRandomItems = <T,> (items: T[], numberOfItems: number) => {
    const shuffledItems = [...items].sort(() => 0.5 - Math.random());
    return shuffledItems.slice(0, Math.min(items.length, numberOfItems));
}

export const preciseHumanDuration = (seconds: number) => {
    const duration = moment.duration(seconds, "seconds");
    const hours = duration.asHours().toFixed(0);
    return `${hours !== "0" ? `${hours}Hours ` : ''}${duration.minutes()}Mins`;
} 