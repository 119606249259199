import React from "react"
import { Category } from "../../../model/Game/WordFinder/Category";
import categoryBackgroundImg from '../../../images/games/wordFinder/categoryBackground.png';
import { arrayToClassName } from "../../../services/CustomFunctions";
import TextView from "../../TextView";
import { DbOrUploadSound } from "../../../services/ImageHelper";

interface Props {
    onClick?: () => void; 
    category: Category; 
    hideSoundIcon?: boolean; 
}

const CategoryPreview = (props : Props) => {
    const {onClick, category, hideSoundIcon} = props;

    return(
        <div className={arrayToClassName(['category-preview', onClick && "clickable"])} onClick={onClick}>
            <img className='category-title-background' src={categoryBackgroundImg} alt="background"/>
            <TextView 
                element='div' 
                text={category.title} 
                className='category-title'
            />
            {!hideSoundIcon && <DbOrUploadSound className='sound-icon' audio={category.sound}/>}
        </div>
    );
}

export default CategoryPreview; 