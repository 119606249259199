import { useContext } from "react";
import { AccountContext } from "../../api/AccountContext";
import { ActiveContext, SecureContext } from "../../components/_MyFloor/MyFloorApp";

const useLogout = () => {
    const clearAccountCache = AccountContext.useClearCache();
    const {setMe, setMyOrg} = useContext(SecureContext);
    const {setActiveOrg} = useContext(ActiveContext);

    return () => {
        localStorage.removeItem("myfloorjwt");
        localStorage.removeItem("myfloorjwt_expire");
        clearAccountCache();
        setMe(undefined);
        setMyOrg(undefined);
        setActiveOrg(undefined);
    }
}

export default useLogout;