import React, { FormEvent, useContext, useState } from 'react';
import { accountHasAnyPermission } from '../../../model/AccountType';
import { isFetchError } from '../../../services/FetchHelper';
import Dialog from "../../Dialog";
import { SettingsProps } from '../../EditGameComponent';
import { GeneralError } from '../../Error/GeneralError';
import { SubmitButton } from "../../forms/FormGroup";
import { DocumentFormGroup } from '../../forms/FormGroups/DocumentFormGroup';
import Translate from '../../Helper/Translate';
import { SecureContext } from '../../_MyFloor/MyFloorApp';
import { WordFinderGame } from '../../../model/Game/WordFinder/WordFinderGame';
import { GameSettings } from '../../../api/BaseGameContext';
import { WordFinderContext } from '../../../api/Game/WordFinderContext';


const WordFinderSettings = (props: SettingsProps<WordFinderGame, GameSettings>) => {
    const { gameId, settings: _settings, onClose, gameUpdated } = props;
    const {me} = useContext(SecureContext);
    const [settings, setSettings] = useState(_settings);
    const [editSettings, loading, error] = WordFinderContext.useUpdateSettings();
    

    const submit = async (e: FormEvent) => {
        e.preventDefault();
        const result = await editSettings(gameId, settings);
        if(!isFetchError(result)) gameUpdated(result);
    }

    return(
        <Dialog className='settings' onClose={onClose} loading={loading}>
            <h2><Translate id='settings'/></h2>
            <form onSubmit={submit}>
                {accountHasAnyPermission(me, 'Editor','Admin') &&
                    <DocumentFormGroup value={settings.document} name='document_upload' onChange={document => setSettings(x => ({...x, document}))} />
                }
                <GeneralError error={error} />
                <SubmitButton split />
            </form>
            
        </Dialog>
    )
};

export default WordFinderSettings;