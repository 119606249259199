import React from "react"
import { Word } from "../../../model/Game/WordFinder/Word";
import { ImageHintPreview } from "./HintPreview";
import { DbOrUploadSound } from "../../../services/ImageHelper";
import { arrayToClassName } from "../../../services/CustomFunctions";

interface Props {
    word : Word;
    onClick?: () => void;
    hasInvalidChars : boolean;
}


const WordPreview = (props : Props) => {
    const {word, hasInvalidChars, onClick} = props;

    return(
        <div className={arrayToClassName(['word-finder-word-preview',  hasInvalidChars && 'invalid', word.hintImage && 'image', onClick && "clickable"])} onClick={onClick}>
            <DbOrUploadSound className='sound-icon' audio={word.successSound} />
            <div dir={'auto'} className={'word-finder-word-to-find-preview'}>{word.contentToFind}</div>
            {word.hintImage && 
                <ImageHintPreview hintImage={word.hintImage}/>
            }
        </div>
    );
}

export default WordPreview;