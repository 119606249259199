import React, {FormEvent, useContext, useMemo, useState} from 'react';
import Dialog from "./Dialog";
import { AuthContext } from '../api/AuthContext';
import { isFetchError } from '../services/FetchHelper';
import { SubmitButton } from './forms/FormGroup';
import InputFormGroup from './forms/FormGroups/InputFormGroup';
import { useTranslation } from 'react-i18next';
import { GlobalModalContext } from './_MyFloor/MyFloorApp';
import { useNavigate, useSearchParams } from 'react-router-dom';


const ResetPassword = () => {
    const {popMsg} = useContext(GlobalModalContext);
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const token = useMemo(() => params.get("token"), [params]);

    const [model, setModel] = useState<{password: string; passwordRepeat: string;}>({password: "", passwordRepeat: ""});
    const [invokeReset, loading, error] = AuthContext.useResetPassword();

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if(token){
            const result = await invokeReset(token, model.password, model.passwordRepeat);
            if(!isFetchError(result)){
                popMsg("pop_change", "pop_msg_user_password_change");
                navigate("/");
            }
        }
        
    }

    return (
        <Dialog onClose={() => {}} loading={loading}>
            <h1>{t('choose_new_password')}</h1>
            <form onSubmit={onSubmit}>
                <InputFormGroup
                    name={'password'}
                    type={'password'}
                    value={model.password}
                    onChange={e => setModel(x => ({...x, [e.target.name]: e.target.value}))}
                    placeholder={'password'}
                    inputIcon={'lock'}
                    errors={error}
                    noLabel
                />
                <InputFormGroup
                    name={'passwordRepeat'}
                    type={'password'}
                    value={model.passwordRepeat}
                    onChange={e => setModel(x => ({...x, [e.target.name]: e.target.value}))}
                    placeholder={'password_repeat'}
                    inputIcon={'lock'}
                    errors={error}
                    noLabel
                />

                {error?.status === 401 && <div className='form-error'>{t('token_expired')}</div>}
                <SubmitButton text='reset_password' split/>
            </form>
        </Dialog>
    );
}

export default ResetPassword;