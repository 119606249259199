import React, {useState} from 'react';
import Dropzone from 'react-dropzone';
import 'react-image-crop/dist/ReactCrop.css';
import {SoundUploadType} from "../../../model/SoundUploadType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import toWav from 'audiobuffer-to-wav';
import {SubmitButton} from "../FormGroup";
import Translate from '../../Helper/Translate';

interface Props{
    onSubmit: (s: SoundUploadType) => void;
    accept?: string;
}
interface State{
    audio?: string;
    audioFile?: File;
    canRecord: boolean;
    mediaRecorder?: MediaRecorder;
    recording?: boolean;
}

const SoundForm = (props: Props) => {
    const [state, setState] = useState<State>({canRecord: 'MediaRecorder' in window});

    const submit = () => {
        if(!state.audioFile) return;
        if(!state.audio) return;

        const data = {
            soundFile: state.audioFile,
            sound: state.audio,
            salt: Math.floor(Math.random()*Math.floor(999999999))
        };
        props.onSubmit(data);
    };
    

    const fileDropped = (acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        if (file){
            const src = URL.createObjectURL(file);
            setState({...state, audio: src, audioFile: file});
        }
    };


    const startMediaRecorder = () => {
        if(!state.recording){
            navigator.mediaDevices.getUserMedia({ audio: true, video: false })
                .then(mediaRecorderStarted);
        }
    }
    const stopMediaRecorder = () => {
        if(state.mediaRecorder){
            state.mediaRecorder.stream.getTracks().forEach(x => x.stop());
        }
    }


    const mediaRecorderStarted = (stream: MediaStream) => {
        const mr = new MediaRecorder(stream, {mimeType: 'audio/webm'});
        mr.addEventListener('dataavailable', async (e) => {
            const blob = e.data;
            const audioContext = new AudioContext();
            await audioContext.decodeAudioData(await blob.arrayBuffer(), (ab) => {
                const wav = toWav(ab, {float32: true});
                const file = new File([wav], "recording.wav");
                setState({
                    ...state,
                    audio: URL.createObjectURL(file),
                    audioFile: file,
                    recording: false
                });
            })
        });
        mr.start();
        setState({...state, mediaRecorder: mr, recording: true});
    }

    return(
        <div className='sound-form'>
            <h2><Translate id='sound'/></h2>
            <div className='drop-container'>
                <div>
                    <Dropzone onDrop={fileDropped} accept={props.accept || 'audio/mpeg,audio/mp3,audio/wav,audio/x-wav'}>
                        {({getRootProps, getInputProps, isDragActive}) => {
                            return (
                                <div {...getRootProps()} className={`dropzone${isDragActive ? ' active' : ''} `}>
                                    <input {...getInputProps()}/>
                                    <p><Translate id='dropzone_upload_text_sound' /></p>
                                </div>
                            )
                        }}
                    </Dropzone>
                </div>
            </div>
            {state.canRecord &&
                <div className='browser-record'>
                    <h2><Translate id={'record_audio'} /></h2>
                    <span className={`control-icon${state.recording ? ' recording' :''}`} onClick={startMediaRecorder}>
                        <FontAwesomeIcon icon='microphone' />
                    </span>
                    { state.recording &&
                        <span className='control-icon' onClick={stopMediaRecorder}>
                            <FontAwesomeIcon icon='stop' />
                        </span>
                    }

                </div>
            }
            <div className='sound-container'>
                {state.audio && !state.recording &&
                    <audio key={state.audioFile?.lastModified} controls >
                        <source src={state.audio} />
                    </audio>
                }
            </div>
            <SubmitButton split text={'submit'} onClick={submit} disabled={!state.audio} />
        </div>

    );
}

export default SoundForm;