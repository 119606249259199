import * as React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { DbImage } from '../model/DbImage';
import { ImageUploadType } from '../model/ImageUploadType';
import { DbSound } from '../model/DbSound';
import { SoundUploadType } from '../model/SoundUploadType';
import { useEffect, useState } from 'react';
import { ImageUploadRequest } from '../model/Request/ImageUploadRequest';
import { DocumentUploadType } from '../model/DocumentUploadType';
import { DbDocument } from '../model/DbDocument';

export function isImageUploadType(image?: ImageUploadType | DbImage | null): image is ImageUploadType{
    return !!image && 'base64Image' in image && 'previewBlob' in image;
}
export function isDbImage(image?: ImageUploadType | DbImage | null): image is DbImage{
    return !!image && 'id' in image;
}
export function isDbAudio(audio?: SoundUploadType | DbSound | null): audio is DbSound {
    return !!audio && 'id' in audio;
}
export function isDbDocument(document: DocumentUploadType | DbDocument | undefined): document is DbDocument{
    return !!document && 'id' in document;
}

export function convertToUpload(image: ImageUploadType): ImageUploadRequest{
    const {previewBlob, salt, ...upload} = image;
    return upload;
}

export const getImgSrc = (image?: ImageUploadType | DbImage | null | false, realSize?: boolean) => {
    if (!image) return false;
    if (!realSize){
        return isDbImage(image) ? image.thumbPath : image.previewBlob;
    } 
    else{
        return isDbImage(image) ? image.imagePath : image.previewBlob;
    }
};

interface DbOrUploadImageProps {
    image?: DbImage | ImageUploadType | null | false;
    alt?: string;
    className?: string;
    realSize?: boolean;
    tempImage?: string;
    style?: React.CSSProperties;
}
export const DbOrUploadImage = (props: DbOrUploadImageProps) => {
    const {image, alt, className, realSize, tempImage, style} = props; 
    const imgSrc = getImgSrc(image, !!realSize) || tempImage || null;
    if (!imgSrc) return null;

    return(
        <img src={imgSrc} alt={alt || ''} className={className || ''} style={style} draggable={false}/>
    )
};

interface ImageWithMultipliedColorProps{
    image: DbImage | ImageUploadType | null | false | undefined; 
    color: string; 
}
export const ImageWithMultipliedColor = (props: ImageWithMultipliedColorProps) => {
    const {image, color} = props; 
    if (!image) return null; 
    const img = `url(${isDbImage(image) ? (image as DbImage).imagePath : image.previewBlob})`; 
    return (
        <div 
            className={'color-multiplied-img'} 
            style={{maskImage: img, backgroundImage: img, backgroundColor: color}}
        >
            <DbOrUploadImage image={image} realSize className='invisible' style={isImageUploadType(image) ? {width: Math.min(image.width, image.previewWidth), height: Math.min(image.height, image.previewHeight)} : undefined}/>
        </div>
    ); 
}

export const getAudioSrc = (audio?: DbSound | SoundUploadType | null | false) => {
    return audio ? (isDbAudio(audio) ? audio.soundPath : audio.sound) : '';
};

interface DbOrUploadSoundProps {
    audio?: DbSound | SoundUploadType | null | false;
    alt?: string;
    className?: string;
    image?: string;
    stopImage?: string;
    children?: React.ReactNode;
}

export const DbOrUploadSound = (props: DbOrUploadSoundProps) => {
    const [audio, setAudio] = useState<HTMLAudioElement>(new Audio(getAudioSrc(props.audio)));
    const { playing, toggle } = useAudio(audio);
    
    useEffect(() => {
        setAudio(new Audio(getAudioSrc(props.audio)));
    },[props.audio])
    if (!props.audio) return null;

    return(
        <div className={props.className} onClick={e => {e.stopPropagation(); toggle()}}>
            { !playing
                ? props.image 
                    ? <img src={props.image} alt={props.alt || ''}/>
                    : <FontAwesomeIcon icon='volume-up' />
                : props.stopImage
                    ? <img src={props.stopImage} alt={'stop'}/>
                    : <FontAwesomeIcon icon='volume-down'/>
            }
            {props.children}
        </div>
    )
};

const useAudio = (audio: HTMLAudioElement) => {
    const [playing, setPlaying] = useState(false);

    const toggle = (() => {
        if(audio){
            audio.currentTime = 0;
            setPlaying(p => !p)
        }
    });

    useEffect(() => {
        if(audio){
            playing ? audio.play() : audio.pause();
        }
    },[playing, audio]);

    useEffect(() => {
        if(audio){
            audio.addEventListener('ended', () => setPlaying(false));
        }
        return () => {
            if(audio){
                audio.removeEventListener('ended', () => setPlaying(false));
                audio.pause();
            }
        };
    }, [audio]);

    return {playing, toggle};
};

export const getReducedAspectRatio = (width: number, height:  number) => {
    const findGcd = (a: number, b: number): number => {
        return b ? findGcd(b, a % b) : a;
    };
    const gcd = findGcd(width, height);
    return [width / gcd, height / gcd];
}