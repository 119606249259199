import { useCallback } from "react";
import { ContextFunc, useFetchGet } from "../services/FetchHelper";

export interface ITagContext{
    useSuggestions: ContextFunc<string[], [string]>;
}

export const TagContext: ITagContext = {
    useSuggestions: () => {
        const [rawInvoke, loading, error] = useFetchGet<string[]>();
        const invoke = useCallback((name: string) => rawInvoke(`api/tags/suggesttags/${name}`), [rawInvoke]);
        return [invoke, loading, error];
    }
}