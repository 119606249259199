class MyFloorEventHub extends HTMLElement {
    public addEventListener<T extends CustomEvent>(
        type: string,
        listener: (this: MyFloorEventHub, ev: T) => void,
        options?: boolean | AddEventListenerOptions
    ){
        super.addEventListener(type, listener as EventListener, options);
    }

    public removeEventListener<T extends CustomEvent>(
        type: string, 
        listener: (this: MyFloorEventHub, ev: T) => void,
        options?: boolean | EventListenerOptions
    ){
        super.removeEventListener(type, listener as EventListener, options);
    }
}

customElements.define('myfloor-event-hub', MyFloorEventHub);

const myfloorEventHub = new MyFloorEventHub();
export default myfloorEventHub;