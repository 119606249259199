import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { RefObject } from 'react';
import { errorStringsFromError } from '../../services/CustomFunctions';
import { FetchError } from '../../services/FetchHelper';
import Translate from '../Helper/Translate';

interface Props{
    fieldname: string;
    errors: FetchError|undefined;
    refForward?: RefObject<HTMLDivElement>; 
}

const StandaloneFieldError = (props: Props) => {
    const {fieldname, errors, refForward} = props;

    const errorStrings = errorStringsFromError(errors?.body, fieldname);
    
    if(!errorStrings) return null;
    return (
        <div className='scroll-margin' ref={refForward}>
            {errorStrings &&
                <div className='standalone-error'>
                    {errorStrings.map(x => <div key={x} className='form-error'><FontAwesomeIcon icon={'exclamation-triangle'} /> <Translate id={x}/></div>)}
                </div>
            }
           
        </div>
        
    )
};

export default StandaloneFieldError;
