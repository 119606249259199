import React, {memo, ReactNode} from 'react';
import TopMenu from './Menu/TopMenu';


interface Props {
    children: ReactNode;
    navMenu: ReactNode;
    buttons?: ReactNode;
    globalModals: ReactNode;
}


const Layout = (props: Props) => {
    return (
        <>
            <TopMenu buttons={props.buttons}/>
            {props.navMenu}
            <div className={'content'}>
                {props.children}
                {props.globalModals}
            </div>
        </>
    );
};

export default memo(Layout);