import React, { FormEvent, useState } from 'react';
import {
    AgeRangeSelect,
    CheckBox,
    ColorAndFont,
    ColorPicker,
    ImageFormButton,
    SelectLanguage,
    SelectList,
    Slider,
    SubmitButton,
    TagInputField
} from '../FormGroup';
import {useConfirmDeleteAsync} from "./ConfirmationForm";
import {Dialog} from "../../Dialog";
import Game from "../../ModelPreview/Game";
import FieldErrors from "../FieldErrors";
import {courseList} from "../../../model/Subject";
import {accountHasAnyPermission, AccountType} from "../../../model/AccountType";
import { GeneralError } from '../../Error/GeneralError';
import InputFormGroup from '../FormGroups/InputFormGroup';
import TextAreaFormGroup from '../FormGroups/TextAreaFormGroup';
import { defaultLanguageList } from '../../../model/LocalizationVariables';
import { isFetchError } from '../../../services/FetchHelper';
import { isDbImage } from '../../../services/ImageHelper';
import { UpdateGameRequest } from '../../../model/Request/UpdateGameRequest';
import { BaseGame as BaseGameModel} from '../../../model/Game/BaseGame';
import { SortByCustom } from '../../../services/SortFunctions';
import { IBaseGameContext } from '../../../api/BaseGameContext';
import Translate from '../../Helper/Translate';
import { useTranslation } from 'react-i18next';
import { PermissionService } from '../../../services/PermissionService';

interface Props<T extends BaseGameModel> {
    game: BaseGameModel;
    onGameUpdated: (game: T) => void;
    onClose: () => void;
    onDeleted: () => void;
    gameContext: IBaseGameContext<T>;
    me: AccountType;
}

const BaseGame = <T extends BaseGameModel> (props: Props<T>) => {
    const {onGameUpdated, onClose, onDeleted, game, gameContext, me} = props;
    const {t} = useTranslation();

    const canPublish = PermissionService.useCanPublish(game);
    const canPublic = PermissionService.useCanPublic(game);

    const [model, setModel] = useState<UpdateGameRequest>(game);
    const [invoke, loading, error] = gameContext.useGameUpdate();
    const [trashInvoke] = gameContext.useGameTrash();
    const [confirmDelete, showConfirmDelete, trashLoading] = useConfirmDeleteAsync(() => trashInvoke(game.id), onDeleted, "game_delete_confirm");

    const isLoading = loading || trashLoading;

    const showLabel = model.showLabel === undefined ? true : !!model.showLabel;
    const labelColorHex = model.labelColorHex || '#000000';
    const showTitle = model.showTitle === undefined ? true : !!model.showTitle;

    const onSubmit = async (event: FormEvent) => {
        event.preventDefault();
        const data: UpdateGameRequest = {
            id: model.id,
            title: model.title,
            fontColorHex: model.fontColorHex,
            fontFamily: model.fontFamily,
            fontSize: model.fontSize,
            description: model.description ?? "",
            isPublic: model.isPublic,
            isPublished: model.isPublished,
            labelColorHex: model.labelColorHex || '#000000',
            backgroundColorHex: model.backgroundColorHex,
            showTitle: model.showTitle === undefined ? false : model.showTitle,
            showLabel: model.showLabel === undefined ? false : model.showLabel,
            image: isDbImage(model.image) ? undefined : model.image,
            languageCode: model.languageCode,
            tags: model.tags || "",
            minAge: model.minAge || 0,
            maxAge: model.maxAge || 0,
            courseName: model.courseName || '',
            preventCopy: model.preventCopy, 
            requiresLicense: model.requiresLicense,
            isVerified: model.isVerified
        };
        const result = await invoke({id: model.id, model: data});
        if(!isFetchError(result)){
            onGameUpdated(result);
        }
    };

    const setIsPublished = (isPublished: boolean) => {
        const isPublic = isPublished ? model.isPublic : false;
        setModel({...model, isPublished, isPublic});
    }

    return (
        <Dialog
            className='my-games-edit-dialog'
            onClose={onClose}
            icon={'trash'}
            onIconClick={showConfirmDelete}
            loading={isLoading}
        >
            <Game game={{...game, ...model}} noMenu onlyImage />
            <div className='clear-fix'/>
            <form className='basegame-form' onSubmit={onSubmit}>
                <GeneralError error={error} />
                <div className="form-left-part">
                    <h2><Translate id='base_info_icon_base'/></h2>
                    <div className='color-image-group'>
                        {model.image && <ColorPicker
                            name={'backgroundColorHex'}
                            colorValue={model.backgroundColorHex || '#FFFFFFFF'}
                            onColorChange={(e) => setModel({...model, [e.target.name]: e.target.value})}
                        />}
                        <ImageFormButton
                            name={'image'}
                            imageSubmit={(image) => setModel({...model, image})}
                            previewWidth={220}
                            previewHeight={150}
                            className='float-right'
                            noRemove
                            value={model.image}
                            fixedAspect={true}
                        />
                        <div className='clear-fix' />
                    </div>
                    {error && 
                        <div className='form-group'>
                            <FieldErrors errors={error} fieldname={'image'} />
                        </div>
                    }
                    <div className='clear-fix'/>
                    <div className='title-section'>
                        <InputFormGroup
                            name='title'
                            placeholder='title'
                            type='text'
                            value={model.title || ''}
                            errors={error}
                            onChange={(e) => setModel({...model, title: e.target.value})}
                            noLabel
                        />
                        {
                            model.title &&
                            <CheckBox name='showTitle' active={showTitle} onChange={(e) => setModel({...model, showTitle: e.target.value})} />
                        }

                        {
                            showTitle &&
                            <div className='sub-section'>
                                <Slider 
                                    min={16} 
                                    max={32} 
                                    name='fontSize' 
                                    value={model.fontSize} 
                                    onChange={(e) => setModel({...model, fontSize: e.target.value})}
                                    noUnsavedChanges
                                />
                                <ColorAndFont
                                    colorValue={model.fontColorHex}
                                    onColorChange={(e) => setModel({...model, fontColorHex: e.target.value})}
                                    fontFamilyValue={model.fontFamily}
                                    onFontFamilyChange={(e) => setModel({...model, fontFamily: e.target.value})}
                                />
                                <div className='label-form-group'>
                                    <CheckBox
                                        className='checkbox-group'
                                        name='showLabel'
                                        active={showLabel}
                                        onChange={(e) => setModel({...model, showLabel: e.target.value})}
                                    />
                                    {showLabel &&
                                    <ColorPicker
                                        name='labelColorHex'
                                        colorValue={labelColorHex}
                                        onColorChange={(e) => setModel({...model, labelColorHex: e.target.value})}
                                    />
                                    }
                                    <div className='clear-fix'/>
                                </div>
                            </div>
                        }
                    </div>
                    <TextAreaFormGroup
                        name='description'
                        placeholder='game_description'
                        value={model.description || ''}
                        errors={error}
                        onChange={(e) => setModel({...model, description: e.target.value})}
                        noLabel
                    />
        
                    <CheckBox 
                        name='isPublished' 
                        active={model.isPublished} 
                        onChange={e => setIsPublished(e.target.value)} 
                        disabled={!canPublish}
                        errors={error}
                    />
                    {model?.isPublished &&
                        <>
                            <CheckBox
                                name='isPublic'
                                active={model.isPublic}
                                onChange={(e) => setModel({...model, isPublic: e.target.value})}
                                errors={error}
                                disabled={!canPublic}
                            />
                            <CheckBox 
                                hoverMsg={'prevent_copy_hover'} 
                                name='preventCopy' 
                                active={model.preventCopy} 
                                onChange={(e) => setModel({...model, preventCopy: e.target.value})} 
                                errors={error}
                                errorKey='preventCopy'
                            />
                            <CheckBox 
                                hoverMsg={'requires_license_hover'} 
                                name='requires_license' 
                                active={model.requiresLicense} 
                                onChange={e => setModel({...model, requiresLicense: e.target.value})} 
                                errors={error}
                                errorKey='requiresLicense'
                            />
                        </>
                    }
                </div>
                <div className={`form-right-part ${model.isPublished ? '' : 'hidden'}`}>
                    <h2><Translate id='base_info_category' /></h2>
                    <SelectLanguage
                        name={'languageCode'}
                        defaultValue={model.languageCode || ''}
                        onChange={(e) => setModel({...model, languageCode: e.target.value})}
                        labelName={'language_game'}
                        labelWidth100
                        errors={error}
                    />

                    <div>
                        <SelectList
                            name={'courseName'}
                            defaultValue={model.courseName || ''}
                            options={
                                [
                                    {name: "course_choose", value: "", translate: true},
                                    ...courseList.map(c => {return{name: t(c), value: c, translate: false}}).sort((a,b) => SortByCustom(a,b, "name", true)),
                                    ...defaultLanguageList(me?.language).map(l => {
                                        return {name: l.name, value: l.value, translate: false}
                                    })
                                ]
                            }
                            onChange={(e) => setModel({...model, courseName: e.target.value})}
                            labelName={'subject'}
                            labelWidth100
                            capitalize
                        />
                        {accountHasAnyPermission(me, 'ContentVerification') && 
                            <CheckBox
                                name='isVerified'
                                active={model.isVerified}
                                onChange={(e) => setModel({...model, isVerified: e.target.value})}
                                errors={error}
                            />
                        }
                        
                        <AgeRangeSelect
                            minAge={model.minAge}
                            maxAge={model.maxAge}
                            onChange={(e) => setModel(m => ({...m, [e.target.name]: e.target.value}))}
                            labelWidth100
                            labelName={'agegroup'}
                        />
                    </div>

                    <TagInputField
                        name={'tags'}
                        type={'text'}
                        value={model.tags || ""}
                        onChange={(e) => setModel({...model, tags: e.target.value})}
                        placeholder={'tags_placeholder'}
                        label={'add_tags'}
                        labelWidth100
                    />
                </div>
                <div className='clear-fix'/>

                <SubmitButton text={'submit'} split />
                {confirmDelete}
            </form>
        </Dialog>
    );
};

export default BaseGame;