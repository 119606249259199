import React from 'react';
import { createBaseGameContext} from '../../api/BaseGameContext';
import { GameType, GameTypesMyFloorStatic } from '../../model/Game/GameType';
import { useTranslation } from 'react-i18next';
import ItemContainer from '../ModelPreview/ItemContainer';
import { isFetchError } from '../../services/FetchHelper';
import { useNavigate } from 'react-router-dom';
import { GameIcons } from '../Helper/GameIcons';
import Translate from '../Helper/Translate';
import { SecureContext } from '../_MyFloor/MyFloorApp';
import { accountIsAdmin } from '../../model/AccountType';

const CreateStaticGame = function () {    

    return (
        <div className='create-static-game'>
            <h1>Welcome to the static game creation page</h1>
            <p>Press to create a static (activity) game</p>
            <ItemContainer 
                items={GameTypesMyFloorStatic.sort((a, b) => a.localeCompare(b))} 
                heading={undefined} 
                itemRender={(x: GameType, i: number) =>  <StaticGameCreateButton key={i} type={x} />} 
                className={"game-chooser"}
            />
        </div>
    );
}

interface Props {
    type: GameType
}
const StaticGameCreateButton = (props: Props) => {
    const gameContext = createBaseGameContext(props.type as GameType); 
    const [createGame] = gameContext.useCreate();
    const {t} = useTranslation(); 
    const navigate = useNavigate(); 
    const {me} = React.useContext(SecureContext); 
    const isAdmin = accountIsAdmin(me); 
    if (!isAdmin) navigate(`/mygames`); 

    return(
        <div className={"game-choice"}>
            <img 
                src={GameIcons[props.type]} 
                alt={t(`gametype_${props.type}`)} 
                className="clickable" 
                onClick={() => 
                    createGame().then(x => {
                        if (!isFetchError(x)) {
                            navigate(`/workshop/${props.type}/edit/${x.id}`);                          
                        }
                    })
                }
            />
            <div className='game-name' style={{color: "black"}}><Translate id={`gametype_${props.type}`} /></div>
        </div>
    )
}
export default CreateStaticGame;