import { useCallback } from "react";
import { ChartData } from "../model/Stats/PieData";
import { ContextFunc, useFetchGet } from "../services/FetchHelper";
import { StatsFilters } from "../model/Stats/StatsFilters";
import { GameType } from "../model/Game/GameType";
import { AccountType } from "../model/AccountType";
import { MinimalOrganization } from "../model/Response/MinimalOrganization";
import { BaseGame } from "../model/Game/BaseGame";
import { DecoratedSession } from "../model/Stats/DecoratedSession";
import { CustomerActivity } from "../model/Stats/CustomerActivity";

export interface IStatsContext{
    useGameType: ContextFunc<ChartData<GameType>, [StatsFilters]>;
    useAuthors: ContextFunc<ChartData<AccountType>, [StatsFilters]>;
    useGamesPlayed: ContextFunc<ChartData<string>, [StatsFilters]>;
    usePlaylistGamesPlayed: ContextFunc<ChartData<BaseGame>, [StatsFilters, string]>;
    useOrgActivity: ContextFunc<ChartData<MinimalOrganization>, [StatsFilters]>;
    usePopularGames: ContextFunc<ChartData<BaseGame>, [StatsFilters]>;
    usePopularWeekdays: ContextFunc<ChartData<string>, [StatsFilters]>;
    useSubjects: ContextFunc<ChartData<string>, [StatsFilters]>;
    useAuthorOrgs: ContextFunc<ChartData<MinimalOrganization>, [StatsFilters]>;
    useLatestGames: ContextFunc<DecoratedSession[], [StatsFilters]>;
    useGameUsageByOrg: ContextFunc<ChartData<MinimalOrganization>, [StatsFilters, string]>;
    useCustomerActivity: ContextFunc<CustomerActivity, [string]>
}

export const StatsContext: IStatsContext = {
    useGameType: () => {
        const [rawInvoke, loading, error] = useFetchGet<ChartData<GameType>>();
        const invoke = useCallback((filters: StatsFilters) => rawInvoke(`api/stats/gameType${generateQueryString(filters)}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useAuthors: () => {
        const [rawInvoke, loading, error] = useFetchGet<ChartData<AccountType>>();
        const invoke = useCallback((filters: StatsFilters) => rawInvoke(`api/stats/authors${generateQueryString(filters)}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useGamesPlayed: () => {
        const [rawInvoke, loading, error] = useFetchGet<ChartData<string>>();
        const invoke = useCallback((filters: StatsFilters) => rawInvoke(`api/stats/PlayedPrDay${generateQueryString(filters)}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    usePlaylistGamesPlayed: () => {
        const [rawInvoke, loading, error] = useFetchGet<ChartData<BaseGame>>();
        const invoke = useCallback((filters: StatsFilters, playlistId: string) => rawInvoke(`api/stats/playlist/${playlistId}/games${generateQueryString(filters)}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useOrgActivity: () => {
        const [rawInvoke, loading, error] = useFetchGet<ChartData<MinimalOrganization>>();
        const invoke = useCallback((filters: StatsFilters) => rawInvoke(`api/stats/activeOrg${generateQueryString(filters)}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    usePopularGames: () => {
        const [rawInvoke, loading, error] = useFetchGet<ChartData<BaseGame>>();
        const invoke = useCallback((filters: StatsFilters) => rawInvoke(`api/stats/games${generateQueryString(filters)}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    usePopularWeekdays: () => {
        const [rawInvoke, loading, error] = useFetchGet<ChartData<string>>();
        const invoke = useCallback((filters: StatsFilters) => rawInvoke(`api/stats/weekdays${generateQueryString(filters)}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useSubjects: () => {
        const [rawInvoke, loading, error] = useFetchGet<ChartData<string>>();
        const invoke = useCallback((filters: StatsFilters) => rawInvoke(`api/stats/subjects${generateQueryString(filters)}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useAuthorOrgs: () => {
        const [rawInvoke, loading, error] = useFetchGet<ChartData<MinimalOrganization>>();
        const invoke = useCallback((filters: StatsFilters) => rawInvoke(`api/stats/authorOrgs${generateQueryString(filters)}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useLatestGames: () => {
        const [rawInvoke, loading, error] = useFetchGet<DecoratedSession[]>();
        const invoke = useCallback((filters: StatsFilters) => rawInvoke(`api/stats/latestGames${generateQueryString(filters)}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useGameUsageByOrg: () => {
        const [rawInvoke, loading, error] = useFetchGet<ChartData<MinimalOrganization>>();
        const invoke = useCallback((filters: StatsFilters, gameId: string) => rawInvoke(`api/stats/game/${gameId}/orgs${generateQueryString(filters)}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useCustomerActivity: () => {
        const [rawInvoke, loading, error] = useFetchGet<CustomerActivity>();
        const invoke = useCallback((managerOrgId: string) => rawInvoke(`api/stats/${managerOrgId}/customerActivity`), [rawInvoke]);
        return [invoke, loading, error];
    }
}

const generateQueryString = (filters: StatsFilters) => {
    let query = "?";
    query += `startEpoch=${filters.startEpoch}&`;
    query += `endEpoch=${filters.endEpoch}&`;
    query += filters.orgId ? `orgid=${filters.orgId}&` : "";
    query += `includeChildren=${filters.includeChildren}&`;
    query += filters.ownerId ? `ownerId=${filters.ownerId}&` : "";
    query += filters.floorId ? `floorId=${filters.floorId}&` : "";
    query += filters.category ? `category=${filters.category}&` : "";
    filters.gameTypes.forEach(g => query += `gameTypes=${g}&`);
    return query;
}