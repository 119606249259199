import * as React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/da';
import 'moment/locale/en-gb';
import 'moment/locale/he';
import './mobile.css';
import 'pure-react-carousel/dist/react-carousel.es.css';
import MyFloorApp from "./components/_MyFloor/MyFloorApp";
import { AuthContext } from './api/AuthContext';
import { isFetchError } from './services/FetchHelper';
import { getExpireTime, getToken, setToken } from './services/LocalStorageService';

library.add(fas, far);

export const eulaVersion = "2";

const App = () =>{
    const [reAuth, loadingReAuth] = AuthContext.useRefreshLogin();
    const [, setTimeout] = useState<NodeJS.Timeout>();

    useEffect(() => {
        moment.locale("en");
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            //refresh token if there is a token and its 2mins from expiring
            const expiretime = getExpireTime();
            const token = getToken();
            const now = moment();
            const twoMinInTheFuture = now.add(2, "minutes");
            if(!loadingReAuth && token && expiretime && expiretime.isBefore(twoMinInTheFuture)){
                reAuth().then(result => {
                    if(!isFetchError(result)){
                        const token = result.token;
                        const expiretime = moment(result.expiretime).toISOString();
                        setToken(token, expiretime)
                    }
                });
            }
        }, 30000);//run every 30sec
        
        setTimeout(x => {
            x && clearInterval(x);
            return interval;
        });
    }, [loadingReAuth, reAuth])



    return <MyFloorApp />;
}

export default App;
