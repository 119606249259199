import { EChartsType, init } from 'echarts';
import React, { useEffect, useState } from 'react';

const useEchart = (refObject: React.RefObject<HTMLDivElement|null>, loading: boolean) => {
    const [chart, setChart] = useState<EChartsType>();
    useEffect(() => {
        setChart(old => old ? old : init(refObject.current, undefined, {renderer: "canvas"}));
    },[refObject]);

    useEffect(() => {
        if(loading){
            chart?.showLoading();
        }
        else{
            chart?.hideLoading();
        }
        
    }, [loading, chart]);

    useEffect(() => {
        const resize = () => {
            chart?.resize();
        }
        window.addEventListener("resize", resize);
        return () => window.removeEventListener("resize", resize)
    },[chart]);

    return chart;
}

export default useEchart;