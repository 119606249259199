import { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { escapeRegExp, updateArrayItemsById } from "../../services/CustomFunctions";
import InputFormGroup from "../forms/FormGroups/InputFormGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusSquare, faPlusSquare, faSquare } from "@fortawesome/free-regular-svg-icons";
import { SelectList } from "../forms/FormGroup";
import { TableColumn } from "./SimpleTable";

const useFilterTypes = <T extends {id: string}> (item: T|undefined, columns: TableColumn<T>[], onTypesFound: (types: TypeType[]) => void) => {
    const [type, setType] = useState<TypeType[]|undefined>(item && columns ? columns.map(x => x.filterValue ? typeof x.filterValue(item) : undefined) : undefined);
    if(!type && item && columns){
        const types = columns.map(x => x.filterValue ? typeof x.filterValue(item) : undefined);
        setType(types);
        onTypesFound(types);
    }
}

type TypeType = ("string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function" | undefined);

export interface FilterType<T extends {id: string}>{
    id: string,
    inputValue: (string|boolean|undefined);
    valueF: TableColumn<T>["filterValue"];
    type: TypeType;
    filterDropDown?: {name: string, value: string}[];
}
interface DropDownFilter<T extends {id: string}>{
    id: string,
    inputValue: string;
    valueF: (x:T) => string;
    type: "string";
    filterDropDown: {name: string, value: string}[];
}
interface StringFilter<T extends {id: string}>{
    id: string,
    inputValue: string;
    valueF: (x:T) => string;
    type: "string";
}
interface BooleanFilter<T extends {id: string}>{
    id: string,
    inputValue: boolean|undefined;
    valueF: (x:T) => boolean;
    type: "boolean";
}

const isDropDownFilter = <T extends {id: string}> (x: FilterType<T>): x is DropDownFilter<T> => x.type === "string" && x.filterDropDown !== undefined;
const isStringFilter = <T extends {id: string}> (x: FilterType<T>): x is StringFilter<T> => x.type === "string";
const isBooleanFilter = <T extends {id: string}> (x: FilterType<T>): x is BooleanFilter<T> => x.type === "boolean";

export const applyFilters = <T extends {id: string}> (items: T[], filters: FilterType<T>[]): T[] => {
    let fItems = items;
    //apply all the stringFilters ot fItems
    filters
        .filter(isStringFilter)
        .filter(x => x.inputValue)
        .forEach(({inputValue, valueF}) => {
            fItems = fItems.filter(x => valueF(x).search(new RegExp(escapeRegExp(inputValue), "ig")) !== -1);
        });

    //apply all the boolean filters to fItems
    filters
        .filter(isBooleanFilter)
        .filter(x => x.inputValue !== undefined)
        .forEach(({inputValue, valueF}) => {
            fItems = fItems.filter(x => valueF(x) === inputValue);
        });

    return fItems;
}

export const columnsToFilters = <T extends {id: string}> (columns: TableColumn<T>[]) : FilterType<T>[] => 
    columns.map(x => ({
        id: x.id, 
        inputValue: x.defaultFilter || undefined, 
        valueF: x.filterValue, 
        type: undefined,
        filterDropDown: x.filterDropDown
    }));

export const useTableFilters = <T extends {id: string}> (item: T|undefined, columns: TableColumn<T>[], onFilterChange: (x: FilterType<T>[]) => void): [ReactNode, FilterType<T>[]] => {
    const [filters, setFilters] = useState<FilterType<T>[]>(columnsToFilters(columns));

    const [, setTimeoutId] = useState<ReturnType<typeof setTimeout>|null>(null);
    useFilterTypes(item, columns, t => setFilters(f => f.map((x,i) => ({...x, type: t?.[i]}))));

    const debounceCallback = (x: SetStateAction<FilterType<T>[]>) => {
        setFilters(x);
        const tId = setTimeout(() => onFilterChange((typeof x === "function" ? x(filters) : x)), 500);
        setTimeoutId(old => {
            if(old) {
                clearTimeout(old);
            }
            return tId;
        })
    }

    const filterNode = useFilterNode(filters, debounceCallback);
    return [filterNode, filters];
}

export const useFilterNode = <T extends { id: string; }> (filters: FilterType<T>[], setFilters: Dispatch<SetStateAction<FilterType<T>[]>>) => {
    const tr = (
        <tr>
            {filters.map(f => {
                if(isDropDownFilter(f)){
                    return(
                        <th key={f.id}>
                            <SelectList 
                                name="header_filter" 
                                noLabel 
                                defaultValue={f.inputValue ?? ""} 
                                options={f.filterDropDown} 
                                noUnsavedChanges 
                                onChange={e => setFilters(x => updateArrayItemsById(x, {...f, inputValue: e.target.value || undefined}))}  
                            />
                        </th>
                    )
                }
                else if(isStringFilter(f)){
                    return(
                        <th key={f.id}>
                            <InputFormGroup
                                name={"header_filter"} 
                                noLabel
                                type={"text"} 
                                value={f.inputValue ?? ""}
                                noUnsavedChanges 
                                onChange={e => setFilters(x => updateArrayItemsById(x, {...f, inputValue: e.target.value || undefined}))} 
                            />
                        </th>
                    )
                }  
                else if(isBooleanFilter(f)){
                    return (
                        <th 
                            key={f.id}
                            onClick={() => {
                                setFilters(x => updateArrayItemsById(
                                    x, 
                                    {...f, inputValue: f.inputValue === undefined ? true : f.inputValue ? false : undefined}
                                ))
                            }}
                        >
                            <div className="form-group">
                                <FontAwesomeIcon className="filter-icon" icon={f.inputValue === undefined ? faSquare : f.inputValue ? faPlusSquare : faMinusSquare} />
                            </div>
                        </th>
                    )
                }                          
                return <th key={f.id} />;
            })}
        </tr>
    )

    return filters.find(x => x.type !== undefined) ? tr : undefined;
}

export const useFilteredItems = <T extends {id: string}> (items: T[], columns: TableColumn<T>[]): [T[], ReactNode] => {
    const [filteredItems, setFilteredItems] = useState(items);
    const [filterNode] = useTableFilters(items[0], columns, x => setFilteredItems(applyFilters(items, x)));

    return [filteredItems, filterNode];
}