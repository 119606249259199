import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { PlayListType } from '../../model/PlayListType';
import { PlaylistOverride, PlaylistOverrideEdit } from '../../model/PlaylistOverride';
import { BaseGame } from '../../model/Game/BaseGame';
import { GameContext } from '../../api/Game/GameContext';
import { isFetchError } from '../../services/FetchHelper';
import PlayListPreview from '../ModelPreview/PlayListPreview';
import { applyPlaylistOverride } from '../../services/CustomFunctions';
import Translate from '../Helper/Translate';
import { PlaylistContext } from '../../api/PlaylistContext';
import ItemContainer from '../ModelPreview/ItemContainer';
import Game from '../ModelPreview/Game';
import { SortByCustom } from '../../services/SortFunctions';
import { Button } from '../forms/FormGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MinimalOrganization } from '../../model/Response/MinimalOrganization';
import Dialog from '../Dialog';
import { useConfirmDeleteAsync } from '../forms/games/ConfirmationForm';
import { CategoryTagLike } from '../Organization/OrgCategoryPicker';
import { SecureContext } from '../_MyFloor/MyFloorApp';
import { accountIsAdmin } from '../../model/AccountType';
import ItemContainerTable from '../Collections/ItemContainerTable';

interface Props{
    playlist: PlayListType;
    onClose: () => void;
    onRemoved: (orgId: string) => void;
    orgs: MinimalOrganization[];
    orgId?: string;
}

const iconFields: (keyof PlaylistOverride)[] = ["backgroundColorHex", "image", "description", "name", "showLabel", "showTitle", "labelColorHex"];

const OverrideDiff = (props: Props) => {
    const {playlist: _playlist, orgs, onClose, onRemoved, orgId} = props;
    const {me} = useContext(SecureContext);
    const [games, setGames] = useState<BaseGame[]>([]);
    const [playlists, setPlaylists] = useState<PlayListType[]>([]);
    const [playlist, setPlaylist] = useState<PlayListType|undefined>(!_playlist.isOverridden ? _playlist : undefined);
    const [override, setOverride] = useState<PlaylistOverride>();

    const [getGames, loadingGames] = GameContext.useMany();
    const [getPlaylists, loadingPlaylists] = PlaylistContext.useMany();
    const [getOverride, loadingOverride] = PlaylistContext.useOverride();
    const [getPlaylist, loadingPlaylist] = PlaylistContext.useOverridePlaylist();
    const [updateOverride, loadingUpdate] = PlaylistContext.useUpdateOverride();
    const [removeOverride, loadingRemove] = PlaylistContext.useRemoveOverride();

    useEffect(() => {
        if(orgId && playlist){
            getOverride(playlist.id, orgId).then(x => !isFetchError(x) && setOverride(x));
        }
    }, [orgId, playlist, getOverride]);

    const onSelect = (orgId: string) => {
        if(orgId && playlist){
            getOverride(playlist.id, orgId).then(x => !isFetchError(x) && setOverride(x));
        }
        else{
            setOverride(undefined);
        }
    }

    const overrideOrg = orgs.find(x => x.id === override?.organizationId);

    const [confirmNode, showConfirm] = useConfirmDeleteAsync(
        async () => { if(overrideOrg) return await removeOverride(_playlist.id, overrideOrg.id);},
        () => {overrideOrg && onRemoved(overrideOrg.id); onClose();}, 
        "playlist_override_confirm_delete"
    );

    const fetchUpdateOverride = (model: PlaylistOverrideEdit) => {
        if(overrideOrg){
            updateOverride(_playlist.id, overrideOrg.id, model).then(x => !isFetchError(x) && setOverride(x));
        }
    } 

    useEffect(() => {
        if(!playlist){
            getPlaylist(_playlist.id, _playlist.organizationId).then(x => !isFetchError(x) && setPlaylist(x));
        }
    }, [playlist, getPlaylist, _playlist]);

    useEffect(() => {
        if(playlist && override){
            const gameIds = [...playlist.games.map(x => x.gameId), ...override.gamesToAdd];
            if(gameIds.length){
                getGames(gameIds).then(x => !isFetchError(x) && setGames(x));
            }
            const playlistIds = [...playlist.nestedPlaylistIds.map(x => x.id), ...override.playlistsToAdd];
            if(playlistIds.length){
                getPlaylists(playlistIds).then(x => !isFetchError(x) && setPlaylists(x));
            }
        }
    }, [override, override?.gamesToAdd, playlist, getGames, override?.playlistsToAdd, getPlaylists]);

    const iconDifferent = override && iconFields.find(field => override[field] !== undefined && override[field] !== null);

    const gamesToAdd = override?.gamesToAdd ?? [];
    const playlistsToAdd = override?.playlistsToAdd ?? [];
    const gameOrder = override?.gameOrder ?? [];

    const playlistContent = [
        ...(playlist?.combinedSort ?? []).map(x => {
            const index = gameOrder.indexOf(x.id);
            return {id: x.id, sort: index < 0 ? x.sort : index }
        }),
        ...gamesToAdd.filter(x => !(playlist?.combinedSort??[]).find(p => p.id === x)).map(x => ({id: x, sort: gameOrder.length ? gameOrder.indexOf(x) : Number.MAX_VALUE})),
        ...playlistsToAdd.filter(x => !(playlist?.combinedSort??[]).find(p => p.id === x)).map(x => ({id: x, sort: gameOrder.length ? gameOrder.indexOf(x) : Number.MAX_VALUE}))
    ].sort((a,b) => SortByCustom(a,b, "sort", true));


    return(
        <Dialog 
            loading={loadingGames||loadingPlaylist||loadingRemove||loadingUpdate||loadingPlaylists||loadingOverride} 
            onClose={onClose} 
        >
            {playlist &&
                <div className='override-diff'>
                    <ItemContainerTable 
                        heading={<h1><Translate id='override_diff_heading' /></h1>}
                        subheading={<h2><Translate id='override_org_modify' /></h2>}
                        pageSize={10}
                        items={orgs}
                        onRowClick={o => onSelect(o.id)}
                        rowClass={o => override?.organizationId === o.id ? 'active' : ''}
                        columns={[
                            {id: "name", value: x => x.name },
                            accountIsAdmin(me) ? {id: "systemName", value: x => x.systemName } : undefined,
                            {id: "country", value: x => x.country },
                            {id: "categories", value: x => x.categories.map(x => <CategoryTagLike cat={x} />), tdClass: "items"},                            
                        ]}
                    />
                    {overrideOrg && override &&
                        <>         
                            <div className='compare flex gap'>
                                <section>
                                    <h2><Translate id='original' /></h2>
                                    <PlayListPreview playlist={playlist} onlyImage noMenu />
                                    <div className='description'>{playlist.description}</div>
                                </section>
                                <div className='arrow'>
                                    <FontAwesomeIcon icon={'arrow-right'} />
                                </div>
                                <section>
                                    <h2>{overrideOrg.name}</h2>
                                    <PlayListPreview playlist={applyPlaylistOverride(playlist, override)} onlyImage noMenu/>
                                    <div className='description'>{override.description ?? playlist.description}</div>
                                </section>
                            </div>
                            <div>
                                {iconDifferent && 
                                    <Button 
                                        name='override_icon_title_restore' 
                                        icon={'trash-restore'}
                                        onClick={() => fetchUpdateOverride({
                                            ...override, 
                                            reuseImage: false,
                                            showLabel: undefined,
                                            description: undefined,
                                            name: undefined,
                                            image: undefined,
                                            backgroundColorHex: undefined,
                                            labelColorHex: undefined,
                                            showTitle: undefined
                                        })} 
                                    />
                                }
                            </div>
                            <ItemContainer
                                heading={<h2>{override.name?.content ?? playlist.name}</h2>}
                                subheading={
                                    <div className='flex gap'>
                                        {!!override.gameOrder.length && 
                                            <Button 
                                                name='override_order_restore' 
                                                icon={'trash-restore'} 
                                                onClick={() => fetchUpdateOverride({
                                                    ...override,
                                                    reuseImage: true,
                                                    image: undefined,
                                                    gameOrder: []
                                                })} 
                                            /> 
                                        }
                                        <Button icon='trash-restore' name='override_restore_all' onClick={showConfirm} />
                                    </div>
                                }
                                items={playlistContent}
                                itemRender={x => {
                                    const game = games.find(g => g.id === x.id);
                                    const playlist = !game && playlists.find(p => p.id === x.id);
                                    if(!game && !playlist) return null;
                                    return (
                                        <OverrideItem
                                            key={x.id}
                                            id={x.id}
                                            added={override.gamesToAdd.includes(x.id) || override.playlistsToAdd.includes(x.id)}
                                            removed={override.gamesToRemove.includes(x.id) || override.playlistsToRemove.includes(x.id)}
                                            previewNode={
                                                <>
                                                    {game && <Game game={game} noMenu onlyImage width={120} hideBadges />}
                                                    {playlist && <PlayListPreview playlist={playlist} noMenu onlyImage width={120} hideBadges />} 
                                                </>
                                            }
                                            onClick={() => fetchUpdateOverride({
                                                ...override,
                                                reuseImage: true,
                                                image: undefined,
                                                gamesToRemove: override.gamesToRemove.filter(old => old !== x.id),
                                                playlistsToRemove: override.playlistsToRemove.filter(old => old !== x.id),
                                                gamesToAdd: override.gamesToAdd.filter(old => old !== x.id),
                                                playlistsToAdd: override.playlistsToAdd.filter(old => old !== x.id)
                                            })}
                                        />
                                    )}
                                }
                            />
                        </>
                    }
                    {confirmNode}
                </div>
            }
        </Dialog>
    )
}

export default OverrideDiff;

interface OverrideItemProps{
    id: string;
    added: boolean;
    removed: boolean;
    previewNode: ReactNode;
    onClick: () => void;
}

const OverrideItem = (props: OverrideItemProps) => {
    const {id, added, removed, previewNode, onClick} = props;

    return(
        <div key={id} className={`override-item${added ? ' added' : ''}${removed ? ' removed' : ''}`}>
            <div className='description'><Translate id={added ? 'override_added' : removed ? 'override_removed' : 'original'} /></div>
            {previewNode}
            {(added || removed) &&
                <div className='action-container'>
                    <Button 
                        name={added ? 'override_remove' : 'override_restore'}
                        icon={added ? 'trash' : 'trash-restore'} 
                        onClick={onClick} 
                    />
                </div>
            }
        </div>
    )
}