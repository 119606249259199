import { useEffect, useState } from "react";
import { isFetchError } from "../../../services/FetchHelper";
import { PlaylistContext } from "../../../api/PlaylistContext";
import Dialog from "../../Dialog";
import Translate from "../../Helper/Translate";
import { FloorWithPlaylist } from "../../../model/PlaylistStats";
import { CategoryTagLike } from "../../Organization/OrgCategoryPicker";
import { GeneralError } from "../../Error/GeneralError";
import ItemContainerTable from "../../Collections/ItemContainerTable";

interface Props{
    playlistId: string;
    onClose: () => void;
}

const FloorWithPlaylistOverview = (props: Props) => {
    const {onClose, playlistId} = props;
    const [fetchFloors, loading, error] = PlaylistContext.useStatsFloors();
 
    const [floors, setFloors] = useState<FloorWithPlaylist[]>([]);

    useEffect(() => {
        fetchFloors(playlistId).then(x => !isFetchError(x) && setFloors(x));
    }, [playlistId, fetchFloors])

    return(
        <Dialog onClose={onClose} loading={loading}>
            <h1><Translate id='playlist_on_floors_title' /></h1>
            <GeneralError error={error} />
            <ItemContainerTable
                items={floors} 
                columns={[
                    {id: "title_organization", value: x => x.orgName},
                    {id: "license_floor_name", value: x => x.floorName},
                    {id: "license_country", value: x => x.country},
                    {id: "org_categories", value: x => x.categories?.map(c => <CategoryTagLike cat={c} />), tdClass: "flex-items"}
                ]} 
                pageSize={20}            
            />
        </Dialog>
    )
}

export default FloorWithPlaylistOverview;