import React, { useMemo, useRef, useState } from 'react';
import { PlayListType } from '../../model/PlayListType';
import { filterWithSearchTerm, useAutoFitSize } from '../../services/CustomFunctions';
import InputFormGroup from '../forms/FormGroups/InputFormGroup';
import Translate from '../Helper/Translate';
import { IconMenuPointProps } from '../ModelPreview/IconMenu';
import ItemContainer from '../ModelPreview/ItemContainer';
import PlayListPreview from '../ModelPreview/PlayListPreview';
import { SortByCustom } from '../../services/SortFunctions';

interface Props{
    heading: string;
    playlists: PlayListType[];
    loading?: boolean;
    menuPoints?: (p: PlayListType) => IconMenuPointProps[];
    descriptionText?: string;
    desiredElementWidth?: number;
    sort: {key: keyof PlayListType, asc: boolean};
    autoSize?: boolean;
}

export const PlaylistOverview = (props: Props) => {
    const {heading, playlists, loading, menuPoints, descriptionText, desiredElementWidth: _dew, autoSize, sort} = props;
    const desiredElementWidth = _dew ?? 220;
    const [searchTerm, setSearchTerm] = useState("");

    const containerRef = useRef<HTMLDivElement>(null)
    const itemWidth = useAutoFitSize(desiredElementWidth, 10, containerRef);

    const filteredPlaylists = useMemo(() => filterWithSearchTerm(playlists, x => x.name, searchTerm), [playlists, searchTerm]);
    const sortedPlaylists = useMemo(() => filteredPlaylists.sort((a,b) => SortByCustom(a,b,sort.key, sort.asc)), [filteredPlaylists, sort]);

    return(
        <div className='playlist-overview'>
            <ItemContainer
                containerRef={containerRef}
                heading={
                    <div className='flex space-between'>
                        <h1><Translate id={heading}/>  </h1>
                        <form>
                            <InputFormGroup noLabel placeholder='search' labelWidth100 inputIcon={'search'} name='search' type='text' onChange={e => setSearchTerm(e.target.value)} value={searchTerm} />
                        </form>
                    </div>
                }
                subheading={descriptionText && <h3><Translate id={descriptionText} /></h3>}
                loading={loading}
                items={sortedPlaylists}
                itemRender={value => <PlayListPreview width={autoSize ? itemWidth : desiredElementWidth} key={value.id} playlist={value} customMenu={menuPoints && menuPoints(value)} />}
            />
        </div>
    )
}

export default PlaylistOverview;