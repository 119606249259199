import React, { useState } from 'react';
import Translate from '../../Helper/Translate';
import ItemContainerTable from '../../Collections/ItemContainerTable';
import InputFormGroup from '../FormGroups/InputFormGroup';
import { filterWithSearchTerm } from '../../../services/CustomFunctions';


interface Props<T extends {id: string}>{
    name: string;
    pool: T[];
    nameFunc: (x: T) => string;
    value: string[];
    onChange: (e: string[]) => void;
}

const PoolPicker = <T extends {id: string},> (props: Props<T>) => {
    const {name, pool, value, onChange, nameFunc} = props;

    const [search, setSearch] = useState<string>("");

    const filteredPool = filterWithSearchTerm(pool, nameFunc, search);


    return(
        <div className='form-group bulk-picker'>
            <div className='label'><Translate id={name} /></div>
            <InputFormGroup name='search' noLabel value={search} onChange={e => setSearch(e.target.value)} type='text' inputIcon={'search'} noUnsavedChanges  />
            <div className='flex'>
                <ItemContainerTable 
                    className='scroll'
                    items={(filteredPool).filter(x => !value.includes(x.id))}
                    pageSize={100}
                    columns={[
                        {id: "org_picker_available", value: nameFunc}
                    ]}
                    onRowClick={x => onChange([...value, x.id])}
                />
                <ItemContainerTable 
                    className='scroll'
                    items={(pool || []).filter(x => value.includes(x.id))}
                    pageSize={100}
                    columns={[
                        {id: "org_picker_picked", value: nameFunc}
                    ]}
                    onRowClick={x => onChange(value.filter(t => t !== x.id))}
                />
            </div>
        </div>
    )
}

export default PoolPicker;