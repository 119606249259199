import React, {MouseEvent, ReactNode, useCallback, useState} from 'react';
import { FetchError, isFetchError } from '../../../services/FetchHelper';
import Translate from '../../Helper/Translate';
import { Loading } from '../../Loading';

interface Props {
    text: string;
    textData?: {[key: string]: string|number};
    confirmText?: string;
    cancelText?: string;
    onConfirm: () => void;
    onCancel: () => void;
    noTranslate?: boolean;
    loading?: boolean;
    closeOnConfirm?: boolean;
}

export const ConfirmationForm = (props: Props) => {
    const {loading, closeOnConfirm, onCancel} = props;

    return(
        <div className='confirm-overlay' onClick={onCancel}>
            <div className='confirm' onClick={e => e.stopPropagation()}>
                {loading && <Loading visible={loading} />}
                <div className='confirm-text' dir='auto'>{props.noTranslate ? props.text : <Translate id={props.text} data={props.textData} />}</div>
                <div className='confirm-buttons'>
                    <button 
                        autoFocus 
                        className='btn' 
                        onKeyPress={e => {e.preventDefault(); if(e.key === "Enter") props.onConfirm(); closeOnConfirm && onCancel();}} 
                        onClick={e => {e.preventDefault(); props.onConfirm(); closeOnConfirm && onCancel();}}
                    >
                        <Translate id={props.confirmText ?? 'yes'} />
                    </button>
                    <button className='btn' onClick={props.onCancel}><Translate id={props.cancelText ?? 'no'} /></button>
                </div>
            </div>
        </div>
    );
};

interface InfoBoxProps{
    text: string;
    textData?: {[key: string]: string|number};
    onClose: (e: MouseEvent<HTMLElement>) => void;
}

export const InfoBox = (props: InfoBoxProps) => {
    return(
        <div className='confirm-overlay' onClick={props.onClose}>
            <div className='confirm'>
                <div className='confirm-text' dir='auto'><Translate id={props.text} data={props.textData} /></div>
                <div className='confirm-buttons'>
                    <span className='btn' onClick={props.onClose}><Translate id={'ok'} /></span>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationForm;

export const useConfirmDeleteAsync = <T extends unknown> (_onConfirm: undefined|null|(() => Promise<T|FetchError>), onConfirmComplete: (x: T) => void, text?: string): [ReactNode, () => void, boolean] => {
    const [confirmDelete, setConfirmDelete] = useState(false);
    const showConfirmDelete = useCallback(() => setConfirmDelete(true), [setConfirmDelete]);
    const [loading, setLoading] = useState(false);
    
    const onConfirm = async () => {
        setConfirmDelete(false);
        setLoading(true);
        const result = await (_onConfirm && _onConfirm());
        setLoading(false);
        if(result && !isFetchError(result)){
            onConfirmComplete(result);
        }
    }

    const node = confirmDelete ? <ConfirmationForm 
        text={text ?? 'confirm_delete'}
        cancelText='no' 
        confirmText='yes' 
        onCancel={() => setConfirmDelete(false)} 
        onConfirm={onConfirm} 
    /> : null;

    return [node, showConfirmDelete, loading];
}

export const useConfirmDelete = (_onConfirm: () => void, text?: string): [ReactNode, () => void] => {
    const [confirmDelete, setConfirmDelete] = useState(false);

    const onConfirm = async () => {
        setConfirmDelete(false);
        _onConfirm();
    }

    const node = confirmDelete ? <ConfirmationForm 
        text={text ?? 'confirm_delete'}
        cancelText='no' 
        confirmText='yes' 
        onCancel={() => setConfirmDelete(false)} 
        onConfirm={onConfirm} 
    /> : null;

    return [node, () => setConfirmDelete(true)];
}