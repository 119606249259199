export const convertHexToRgba = (hex: string|null, alpha = 1) => {

    if(hex === null) return("rgba(255,255,255,0)");

    if (hex.length === 3) {
        hex = hex.split('').map(function(el){
            return el + el + '';
        }).join('');
    }
    const arr = hex.match(/\w\w/g);
    if(arr === null) return("rgba(255,255,255,0)");

    const [r, g, b] = arr.map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
};