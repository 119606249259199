import React, { useContext, useEffect, useState } from 'react';
import { GlobalModalContext, SecureContext } from '../_MyFloor/MyFloorApp';
import { accountHasAnyPermission } from '../../model/AccountType';
import TopMenuButton from '../Menu/TopMenuButton';
import { Ad } from '../../model/Advertisement/Ad';
import AdForm, { adTypeNames } from './AdForm';
import { OrganizationContext } from '../../api/OrganizationContext';
import { isFetchError } from '../../services/FetchHelper';
import { AdContext } from '../../api/AdContext';
import ItemContainerTable from '../Collections/ItemContainerTable';
import Translate from '../Helper/Translate';
import moment from 'moment';
import { DbOrUploadImage } from '../../services/ImageHelper';
import ConfirmationForm from '../forms/games/ConfirmationForm';
import { Button } from '../forms/FormGroup';
import { insertOrUpdateItem } from '../../services/CustomFunctions';

export const AdIndex = () => {
    const {me} = useContext(SecureContext);
    const {setLayoutButtons} = useContext(GlobalModalContext);
    const [editAd, setEditAd] = useState<Ad|null>();

    const [types, setTypes] = useState<string[]>([]); 
    const [countries, setCountries] = useState<string[]>([]); 
    const [ads, setAds] = useState<Ad[]>([]);
    const [confirmRemove, setConfirmRemove] = useState<Ad>();

    const [getTypes] = OrganizationContext.useOrgTypes();
    const [getCountries] = OrganizationContext.useOrgCountries();
    const [getAds, loadingAds] = AdContext.useAll();
    const [removeAd, loadingRemoveAd] = AdContext.useRemove();

    useEffect(() => {
        getTypes().then(x => !isFetchError(x) && setTypes(x));
        getCountries().then(x => !isFetchError(x) && setCountries(x));
        getAds().then(x => !isFetchError(x) && setAds(x));
    }, [getTypes, getCountries,getAds]);

    useEffect(() => {
        setLayoutButtons(
            <TopMenuButton icon={'plus'} action={() => setEditAd(null)}  />
        )
    }, [setLayoutButtons]);

    const deleteClick = (ad: Ad) => {
        removeAd(ad.id).then(r => !isFetchError(r) && setAds(old => old.filter(x => x.id !== ad.id)));
    }

    if(!accountHasAnyPermission(me, "Admin")) return <div>This is a restricted section</div>;

    return(
        <div className='ad-index'>
            <ItemContainerTable
                items={ads}
                heading={<h1><Translate id='ad_index_title' /></h1>}
                pageSize={100}
                loading={loadingAds||loadingRemoveAd}
                onRowClick={item => setEditAd(item)}
                columns={[
                    {id: "image", value: x => <DbOrUploadImage image={x.image} />},
                    {id: "type", value: x => <Translate id={adTypeNames[x.adType]} />},
                    {id: "start", value: x => moment.unix(x.startEpoch).format("ll"), cmpValue: x => x.startEpoch},
                    {id: "language", value: x => x.target.language},
                    {id: "actions", value: x => <div className='actions'><Button icon={'trash'} onClick={() => setConfirmRemove(x)} ><Translate id='delete' /></Button></div>}
                ]}
            />
            {editAd !== undefined &&
                <AdForm 
                    model={editAd} 
                    onClose={() => setEditAd(undefined)} 
                    orgTypes={types} 
                    orgCountries={countries}
                    onUpdate={a => setAds(old => insertOrUpdateItem(old, a))}
                />
            }
            {confirmRemove && 
                <ConfirmationForm 
                    text='delete_question' 
                    onCancel={() => setConfirmRemove(undefined)}
                    onConfirm={() => deleteClick(confirmRemove)}
                    closeOnConfirm
                />
            }
        </div>
    )
}

export default AdIndex;