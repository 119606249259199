export type Dict<T> = {[key: string]: T};

export const DictGetValues = <T>(o: {[key: string]: T}): Array<T> => {
    return Object.keys(o)
        .map(key => o[key])
        .filter(x => x !== undefined);
};

export const DictRemoveKey = <T>(o: {[key: string]: T}, k: string): {[key: string]: T} => {
    const copy = {...o};
    delete copy[k];
    return copy;
}