import React from 'react'; 
import verifiedImage from '../../images/content_verification/verified_mark.svg';
import verifiedImageBig from '../../images/content_verification/verified_mark_big.svg';
import Translate from '../Helper/Translate';

export const VerifiedMark = () => 
    <div className={'verified-mark'} >
        <div className="hover-trigger">
            <img src={verifiedImageBig} alt=''/>
            <div className='hover-msg'><Translate id='verified_game_detailed'/></div>
        </div>
    </div>; 

export const VerifiedMarkTag = () => {
    return(<div className="tag verified-tag-box">
        <Translate id="verified_content_tag" />
        <div className='verified-mark-tag'>
            <img src={verifiedImage} alt=''/>
        </div>
    </div>); 
}
