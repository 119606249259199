import React, { useContext } from 'react';
import FloorChooser from '../FloorChooser';
import { isFetchError } from '../../../services/FetchHelper';
import { Floor } from '../../../model/Floor';
import { PlayListType } from '../../../model/PlayListType';
import { GlobalModalContext } from '../../_MyFloor/MyFloorApp';
import { FloorContext } from '../../../api/FloorContext';

interface Props {
    playlist: PlayListType;
    onClose: () => void;
    floorUpdated?: (f: Floor) => void;
}

const AddPlaylistToFloor = (props: Props) => {
    const {playlist, floorUpdated, onClose} = props;
    const {showPlaylistToFloorBulk, popMsg} = useContext(GlobalModalContext);

    const [invoke, loading, error] = FloorContext.useAddPlaylist();

    const onChoose = async (floorId: string, rowIndex: number) => {
        const result = await invoke(floorId, playlist.id, rowIndex);
        if(!isFetchError(result)){
            popMsg("pop_added", "pop_msg_floor_playlist_add", {name: playlist?.name || ''});
            floorUpdated && floorUpdated(result);
            onClose();
        }
    }
    
    if(!playlist) return null;

    return <FloorChooser 
        error={error}
        loading={loading}
        onChoose={onChoose} 
        onClose={onClose}
        onBulkClick={() => showPlaylistToFloorBulk(playlist)}
    />
};

export default AddPlaylistToFloor;