import React from 'react';
import { StatsContext } from '../../api/StatsContext';
import { getFullName } from '../../model/AccountType';
import { StatsFilters } from '../../model/Stats/StatsFilters';
import Author from '../ModelPreview/Author';
import CombiChart from './BaseCharts/CombiChart';
import { preciseHumanDuration } from '../../services/CustomFunctions';

interface Props{
    filters: StatsFilters;
    showDuration?: boolean;
}

const AuthorsChart = (props: Props) => {
    const {filters, showDuration} = props;

    return(
        <CombiChart
            dataFunc={StatsContext.useAuthors}
            filters={filters}
            initialType='pie'
            label={x => x ? getFullName(x.data) : "Unknown"}
            title='chart_most_played_authors'
            showDuration={showDuration}  
            toolTip={e => 
                <div>
                    <div><span style={{color: e.color}}>&#11044;</span> {showDuration ? preciseHumanDuration(e.data.duration) : e.data.totalValue} ({e.percent}%)</div>
                    <Author account={e.data.data} />
                </div>
            }
            onClick={data => {
                const author = data.data
                if(author){
                    window.open(`/library/profile/${author.id}`);
                }
            }}
        />
    )
}

export default AuthorsChart;