import React, { useContext } from 'react';
import { PlayListType } from '../../model/PlayListType';
import { BaseGame } from '../../model/Game/BaseGame';
import { Loading } from '../Loading';
import Game from '../ModelPreview/Game';
import leftarrow from '../../images/left-arrow.png';
import rightarrow from '../../images/right-arrow.png';
import exitImg from '../../images/grid_exit.png';
import controlStub from '../../images/controlbackground-stub.png';
import PlayListPreview from '../ModelPreview/PlayListPreview';
import { notUndefined } from '../../services/CustomFunctions';
import { SecureContext } from '../_MyFloor/MyFloorApp';

interface Props{
    loading?: boolean;
    playlist: PlayListType;
    games: BaseGame[];
    playlists: PlayListType[];
}

const PlaylistFloorGridPreview = (props: Props) => {
    const {loading, playlist, games, playlists} = props;
    const {isRtl} = useContext(SecureContext);

    const combinedItems = playlist.combinedSort.map(x => {
        const game = games.find(g => g.id === x.id) ?? undefined;
        const p = !game ? [...playlists, playlist].find(p => p.id === x.id) : undefined;
        return game || p;
    });

    return(
        <div className='playlist-floor-preview grid' dir={isRtl ? 'rtl' : undefined}>
            <img className='exit-img' src={exitImg} alt='close playlist' />
            <Loading visible={loading} />
            <div className='overlay'>
                <div className='items' style={{backgroundColor: playlist.backgroundColorHex ?? "rgb(128,128,128)"}}>
                {combinedItems.filter(notUndefined).filter(x => x.isPublished).filter((_,i) => i < 9).map(item => {
                        if(itemIsGame(item)){
                            return <Game key={item.id} game={item} width={69} onlyImage hideBadges noMenu />;
                        }else{
                            return <PlayListPreview key={item.id} playlist={item} width={69} onlyImage hideBadges noMenu />
                        } 
                    })}
                </div>
            </div>
            <div className='name'>{playlist.name}</div>
            <DummyRow rowNumber={0} />
            <DummyRow rowNumber={1} />
            <DummyRow rowNumber={2} />
            <div className='pull-up-menu-container'>
                <img className='pull-up-menu' src={controlStub} alt='pull up menu'/>
            </div>
        </div>
    )
}
export default PlaylistFloorGridPreview;

const itemIsGame = (model: PlayListType|BaseGame): model is BaseGame => {
    return "gameType" in model;
}

interface DummyRowProps{
    rowNumber: number;
}
const DummyRow = (props: DummyRowProps) => {
    const {rowNumber} = props;
    return(
        <div className={`row no-${rowNumber}`}>
            <img className='arrow left'  src={leftarrow}  alt='left-navigation' />
            <div className='rect' />
            <div className='rect' />
            <div className='rect' />
            <div className='rect' />
            <div className='rect' />
            <img className='arrow right' src={rightarrow} alt='right-naviation' />
        </div>
    )
}