import { useCallback, useEffect, useState } from "react";
import { getToken } from "./LocalStorageService";

export class FetchError extends Error {
    public isFetchError: boolean;
    public status: number;
    public isJson: boolean;
    public rawBody?: string;
    public body?: { [key: string]: string[] };

    constructor(status: number, statusText: string, isJson: boolean, body?: { [key: string]: string[] }, rawBody?: string) {
        super(statusText);
        this.status = status;
        this.isJson = isJson;
        this.body = body;
        this.rawBody = rawBody;
        this.isFetchError = true;
    }

    toString() {
        return JSON.stringify({ status: this.status, isJson: this.isJson, body: this.body });
    }
}

export function isFetchError<T>(error: FetchError|T): error is FetchError{
    return error && (error as FetchError).isFetchError;
}

export type ContextReturn<T,TArgs extends Array<unknown>> = [
    (...args: TArgs) => Promise<T|FetchError>,
    boolean,
    FetchError|undefined
]
export type ContextFunc<T,TArgs extends unknown[]> = () => ContextReturn<T,TArgs>;

type UseFetchResult<T> = [
    (url: string, body?: unknown) => Promise<T|FetchError>,
    boolean,
    FetchError | undefined,
    (onSuccess: SuccessFunc<T>) => void,
];

type SuccessFunc<T> = ((m: T) => void) | undefined;

export const useFetchGet    = <T,>(onSuccess?: SuccessFunc<T>) => useFetch<T>('GET', onSuccess);
export const useFetchPut    = <T,>(onSuccess?: SuccessFunc<T>) => useFetch<T>('PUT', onSuccess);
export const useFetchPost   = <T,>(onSuccess?: SuccessFunc<T>) => useFetch<T>('POST', onSuccess);
export const useFetchDelete = <T,>(onSuccess?: SuccessFunc<T>) => useFetch<T>('DELETE', onSuccess);

interface INameable{
    name: string;
}

export const hasName = (obj: unknown): obj is INameable => {
    return (obj as INameable).name !== undefined
        && typeof (obj as INameable).name === "string";
}

const useFetch = <T extends unknown>(method: 'GET' | 'POST' | 'DELETE' | 'PUT', onSuccess?: SuccessFunc<T>, url?: string): UseFetchResult<T> => {
    const [_onSuccces, _setOnSuccess] = useState<SuccessFunc<T>>(() => onSuccess);
    const [setOnSuccess] = useState(() => (f: SuccessFunc<T>) => _setOnSuccess(() => f));
    const [loading, setLoading] = useState(() => false);
    const [error, setError] = useState<FetchError>();
    const [abortController, setAbortController] = useState(new AbortController());
    const [_url] = useState(url);

    //make sure invoke function will only be created once
    const invoke = useCallback(async (url: string, body?: unknown): Promise<T|FetchError> => {
        setLoading(true);
        setError(undefined);
        try {
            const ac = new AbortController();
            setAbortController(a => {a.abort(); return ac;});
            const r = await fireRequest<T>(method, ac, url || _url || '', body);
            if(_onSuccces !== undefined) _onSuccces(r);
            setLoading(false);
            return r;
        }
        catch (e: unknown) {
            if (hasName(e) && e.name === 'AbortError') {
                return new FetchError(1, "Request was aborted", false);
            }
            else {
                setLoading(false);
                if(e instanceof FetchError){
                    setError(e);
                    return e;
                } 
            }
            return new FetchError(0, "Unknown error", false);
        }
    }, [method, _onSuccces, _url]);

    //Cleanup
    useEffect(() => {
        return () => {
            abortController.abort();
        }
    }, [abortController]);

    return [invoke, loading, error, setOnSuccess];
}

export const useFetchPdf = (onSuccess?: SuccessFunc<void>,): UseFetchResult<void> => {
    const [_onSuccces, _setOnSuccess] = useState<SuccessFunc<void>>(() => onSuccess);
    const [setOnSuccess] = useState(() => (f: SuccessFunc<void>) => _setOnSuccess(() => f));
    const [error, setError] = useState<FetchError>();
    const [loading, setLoading] = useState(false);
    const invoke = useCallback(async (url: string) => {
        setLoading(true);
        try{
            const x = await fetch(url, {
                method: 'get',
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                    'Accept': 'application/json, text/plain, */*'
                }
            });

            if(x.status !== 200){
                setError(new FetchError(0, "Unknown error", false));
                return new FetchError(0, "Unknown error", false);
            }
            else{
                x.blob().then(blob => {
                    const a = document.createElement('a');
                    const url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = "invoice.pdf";
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                });
                if(_onSuccces !== undefined) _onSuccces();
                return;
            }
        }
        catch(e){
            return new FetchError(0, "Unknown error", false);
        }
        finally{
            setLoading(false);
        }
    }, [_onSuccces]);

    return [invoke, loading, error, setOnSuccess];
}

const fireRequest = async <T extends unknown>(method: 'GET' | 'POST' | 'PUT' | 'DELETE', abortController: AbortController, url: string, body?: unknown) => {
    if (method === 'GET') {
        return await fetchGet<T>(url, abortController);
    }
    else if (method === 'POST') {
        return await fetchPost<T>(url, body, abortController);
    }
    else if (method === 'PUT') {
        return await fetchPut<T>(url, body, abortController);
    }
    else if (method === 'DELETE') {
        return await fetchDelete<T>(url, abortController);
    }
    else {
        throw new Error(`Unsupport HTTP Verb ${method}`);
    }
}

const tryParse = (s: string) => {
    if(!s) return undefined;
    try{
        const parsed = JSON.parse(s);
        return typeof parsed === 'object' ? parsed : undefined;
    }
    catch(_){
        return undefined;
    }
}

export const handleResponse = async <T extends unknown>(r: Response) => {
    try {
        if (r.ok) {
            //Only try to serialzie if we get json back
            const contentType = r.headers.get("Content-Type");
            if (contentType && contentType.indexOf("/json") !== -1) {
                return r.json() as Promise<T>;
            }

            return {} as T; //return promise with empty object in resolve
        }

        const raw = await r.text();
        const parsed = tryParse(raw);
        throw new FetchError(r.status, r.statusText, !!parsed, parsed, raw.startsWith('"') && raw.endsWith('"') ? raw.substring(1, raw.length-1) : raw);
    }
    catch (e) {
        if (e instanceof FetchError) {
            throw e;
        }
        throw new FetchError(r.status, r.statusText, false);
    }
}

const fetchGet = async <T extends unknown>(url: string, abortController?: AbortController) => {
    const token = getToken();

    const r = await fetch(url, {
        method: 'get',
        mode: 'same-origin',
        signal: abortController?.signal,
        headers: {
            Accept: 'application/json, text/plain, */*',
            ...(token && { Authorization: `Bearer ${token}` })
        },
    });

    return await handleResponse<T>(r);
}

const fetchPost = async <T extends unknown>(url: string, body: unknown, abortController?: AbortController) => {
    const token = getToken();
    const r = await fetch(url, {
        method: 'post',
        mode: 'same-origin',
        body: body instanceof FormData ? body : JSON.stringify(body),
        signal: abortController?.signal,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            ...(token && { Authorization: `Bearer ${token}` }),
            ...(!(body instanceof FormData) && { 'Content-Type': 'application/json' })
        }
    });

    return await handleResponse<T>(r);
}

const fetchPut = async <T extends unknown>(url: string, body: unknown, abortController?: AbortController) => {
    const token = getToken();
    const r = await fetch(url, {
        method: 'put',
        mode: 'same-origin',
        signal: abortController?.signal,
        body: body instanceof FormData ? body : JSON.stringify(body),
        headers: {
            'Accept': 'application/json, text/plain, */*',
            ...(token && { Authorization: `Bearer ${token}` }),
            ...(!(body instanceof FormData) && { 'Content-Type': 'application/json' })
        }
    });
    return await handleResponse<T>(r);
}

const fetchDelete = async <T extends unknown>(url: string, abortController?: AbortController) => {
    const token = getToken();
    const r = await fetch(url, {
        method: 'delete',
        mode: 'same-origin',
        signal: abortController?.signal,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            ...(token && { Authorization: `Bearer ${token}` })
        }
    }
    );

    return await handleResponse<T>(r);
}