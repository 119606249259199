import React, { ReactNode, DragEvent } from 'react';
import Sortable from '../../DragnDrop';
import { DragState } from './PlaylistExplorer';

interface ExplorerItemProps{
    children?: ReactNode|undefined;
    className?: string;
    withDnD?: {
        id: string;
        type: string;
        acceptDropTypes: string[];
        dragStarted: () => void;
        onDrag: (e: DragEvent) => void;
        dragEnded: () => void;
        itemDropped: (src: string) => void;
        dragState: DragState|undefined;
    }
}

const ExplorerItem = (props: ExplorerItemProps) => {
    const {children, withDnD, className} = props;
    if(withDnD){
        const {id, type, acceptDropTypes, itemDropped, dragStarted, dragEnded, onDrag, dragState} = withDnD;
        const isDragging = id === dragState?.draggedItem.id;
        const showDropHint = !isDragging && acceptDropTypes.find(x => x === dragState?.dragType);
        return (
            <Sortable
                type={type} 
                id={id} 
                onSort={() => {}} 
                onSortCommit={itemDropped}
                dragStarted={dragStarted}
                onDrag={onDrag}
                end={dragEnded}
                followMouseOnDrag
                className={`explorer-item ${showDropHint ? 'dropable' : ''}${className ? ` ${className}` : ''}`}
                noGhost
                acceptDropTypes={acceptDropTypes}
                style={isDragging ? {} : {position: 'relative'}}
            >
                {children}
            </Sortable>
        )
    }
    return <>{children}</>;
}

export default ExplorerItem;