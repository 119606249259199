import React, { MouseEvent, useContext, useEffect, useMemo, useState } from 'react';
import Dialog from '../Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, SelectList, SubmitButton } from './FormGroup';
import { SortByCustom, SortByName } from '../../services/SortFunctions';
import { AddPlaylistBulkType } from '../../model/Request/playlist/AddPlaylistBulk';
import { isFetchError } from '../../services/FetchHelper';
import { AddPlaylistBulkResponse } from '../../model/Response/AddPlaylistBulkResponse';
import PlayListPreview from '../ModelPreview/PlayListPreview';
import { Floor } from '../../model/Floor';
import InputFormGroup from './FormGroups/InputFormGroup';
import { escapeRegExp } from '../../services/CustomFunctions';
import { SecureContext } from '../_MyFloor/MyFloorApp';
import { PlayListType } from '../../model/PlayListType';
import Translate from '../Helper/Translate';
import { OrganizationContext } from '../../api/OrganizationContext';
import { MinimalOrganization } from '../../model/Response/MinimalOrganization';
import { FloorContext } from '../../api/FloorContext';
import { OrganizationCategory } from '../../model/Organization';
import OrgCategoryPicker, { CategoryTagLike } from '../Organization/OrgCategoryPicker';
import { RadioGroup } from './RadioGroup';
import ItemContainerTable from '../Collections/ItemContainerTable';

interface Props{
    playlist: PlayListType;
    onClose: () => void;
}

interface State {
    sort: { sortKey: keyof MinimalOrganization; ascending: boolean };
    filters: { type?: string; country?: string, state?: string, city?: string, categories?: OrganizationCategory[], matchAllCategories?: boolean};
    searchTerm: string;
}

const AddPlaylistBulk = (props: Props) => {
    const {playlist, onClose} = props;
    const {me} = useContext(SecureContext);
    const [state, setState] = useState<State>({ sort: { sortKey: "systemName", ascending: true }, searchTerm: "", filters: {} });
    const [orgs, setOrgs] = useState<MinimalOrganization[]>([]);
    const [floors, setFloors] = useState<Floor[]>([]);
    const [excluded, setExcluded] = useState<Array<string>>([]);
    const [rowIndex, setRowIndex] = useState(-1);
    const [position, setPosition] = useState(0);
    const [response, setResponse] = useState<AddPlaylistBulkResponse>();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [invokeGetAll, loadingGetAll] = FloorContext.useAll();
    const [getOrgs, loadingOrgs] = OrganizationContext.useAllMinimal();
    const [addBulk] = FloorContext.useAddPlaylistBulk();

    useEffect(() => {
        if(playlist){
            invokeGetAll().then(x => !isFetchError(x) && setFloors(x));
            getOrgs().then(x => !isFetchError(x) && setOrgs(x))
        }
    },[playlist, invokeGetAll, getOrgs]);

    const {sort, filters, searchTerm} = state;
    
    const allOrgs = useMemo(() => { 
        if(!me) return [];
        if(!playlist) return [];
        const isAdmin = (me.permissions && me.permissions.includes("Admin")) || undefined;
        const canSeeOtherThanOwn = me.permissions && (isAdmin || me.permissions.includes("ChildOrganizations"));
        const visibleOrganizations = canSeeOtherThanOwn ? orgs : [];
        return visibleOrganizations;
    },[me,playlist, orgs]);


    const filteredOrgs = useMemo(() => {
        const regex = new RegExp(escapeRegExp(searchTerm), "ig");
        let orgs = allOrgs.filter(o => o.systemName.search(regex) !== -1);
        if (filters.type) orgs = orgs.filter(o => o.type === filters.type);
        if (filters.country) orgs = orgs.filter(o => o.country === filters.country);
        if (filters.state) orgs = orgs.filter(o => o.state === filters.state);
        if (filters.city) orgs = orgs.filter(o => o.city === filters.city); 
        if (filters.categories?.length){
            if(filters.matchAllCategories){
                orgs = orgs.filter(o => filters.categories?.every(c => o.categories?.includes(c)));
            }
            else{
                orgs = orgs.filter(o => o.categories.some(c => filters.categories?.includes(c)));
            }
        } 
        return orgs;
    },[searchTerm, filters, allOrgs]) 

    const filteredFloors = useMemo(() => {
        return filteredOrgs
        .sort((a, b) => SortByCustom(a, b, sort.sortKey, sort.ascending))
        .flatMap(o => floors.filter(x => x.organizationId === o.id).map((f, i) => ({ ...f, org: o, index: i })));
    }, [filteredOrgs, sort, floors]);

    if(!playlist) return null;
    if(!me) return null;

    const onFloorClick = (e: MouseEvent|null, floorId: string) => {
        const isExcluded = !!excluded.find(x => x === floorId);
        if(isExcluded){
            if(e?.shiftKey){
                let newExclude = [...excluded];
                for(
                    let index = filteredFloors.findIndex(x => x.id === floorId) ?? 0;
                    index >= 0 && excluded.find(x => x === filteredFloors[index].id);
                    index--
                ){
                    newExclude = newExclude.filter(x => x !== filteredFloors[index].id);
                }
                setExcluded(newExclude);
            }else{
                setExcluded(ex => ex.filter(x => x !== floorId));
            }
        }
        else{
            if(e?.shiftKey){
                const newExclude = [...excluded];
                for(
                    let index = filteredFloors.findIndex(x => x.id === floorId) ?? 0; 
                    index >= 0 && !excluded.find(x => x === filteredFloors[index].id); 
                    index--
                ){
                    newExclude.push(filteredFloors[index].id);
                }
                setExcluded(newExclude);
            }
            else{
                setExcluded(ex => ([...ex, floorId]));
            }
        }
    }
    //unique org types, for type selectlist
    const uniqueOrgTypes = [...new Set(allOrgs.map(x => x.type))].filter(x => x).map(x => ({ name: x, value: x, translate: false }));
    uniqueOrgTypes.unshift({ name: 'bulk_type', value: '', translate: true  });

    //unique org countries, for countries selectlist
    const uniqueOrgCountries = [...new Set(allOrgs.map(x => x.country))].filter(x => x).map(x => ({ name: x, value: x, translate: false })).sort(SortByName);
    uniqueOrgCountries.unshift({ name: 'bulk_country', value: '', translate: true  });
    const uniqueOrgStates =  [...new Set(allOrgs.map(x => x.state))].filter(x => x).map(x => ({ name: x, value: x, translate: false })).sort(SortByName);
    uniqueOrgStates.unshift({name: 'bulk_state', value: '', translate: true});
    const uniqueOrgCities =  [...new Set(allOrgs.map(x => x.city))].filter(x => x).map(x => ({ name: x, value: x, translate: false })).sort(SortByName);
    uniqueOrgCities.unshift({name: 'bulk_city', value: '', translate: true});
    
    const selectedFloors = filteredFloors.filter(f => !excluded.find(x => x === f.id));

    const addClick = () => {
        setIsSubmitted(true);
        setResponse(undefined);
        const content: AddPlaylistBulkType = {
            rowIndex,
            position,
            playlistId: playlist.id || '',
            floors: selectedFloors.map(x => ({ floorId: x.id})) 
        }

        addBulk(content).then(r => {
            if(!isFetchError(r)){
                setResponse(r); 
                setIsSubmitted(false);
                setExcluded(exc => ([...exc, ...r.floorResults.filter(x => x.isSuccess).map(x => x.floorId)]));
            }
        });
    }

    const removeAll = () => setExcluded(filteredFloors.map(x => x.id));
    const selectAll = () => setExcluded([]);
    const floorFailed = (floorId: string) => response && response.floorResults.filter(x => !x.isSuccess).find(x => x.floorId === floorId);
    const selectFailed = () => setExcluded(ex => ex.filter(x => !floorFailed(x)));

    return(
        <Dialog onClose={onClose} className='bulk-dialog' loading={isSubmitted || loadingGetAll || loadingOrgs}>
            <div className='well'>
                <h1><Translate id='playlist_to_floor' /></h1>
                <PlayListPreview playlist={playlist} noMenu />
                <div className='clear-fix'/>
            </div>

            <h2><Translate id='bulk_filters' /></h2>
            <form className={'bulk-form'} onSubmit={e => e.preventDefault()}>
                <div className='filters flex'>
                    <InputFormGroup
                        labelWidth100
                        name='bulk_system_name'
                        type='text'
                        value={searchTerm}
                        onChange={(e) => setState(s => ({ ...s, searchTerm: e.target.value }))}
                        disabled={isSubmitted}
                        noUnsavedChanges
                    />
                    <SelectList
                        labelWidth100
                        name='bulk_country'
                        options={uniqueOrgCountries}
                        onChange={(e) => setState(s => ({ ...s, filters: { ...s.filters, country: e.target.value } }))}
                        disabled={isSubmitted}
                        noUnsavedChanges
                        defaultValue={state.filters.country}
                    />
                    <SelectList
                        labelWidth100
                        name='bulk_state'
                        options={uniqueOrgStates}
                        onChange={(e) => setState(s => ({ ...s, filters: { ...s.filters, state: e.target.value !== "" ? e.target.value : undefined} }))}
                        disabled={isSubmitted}
                        noUnsavedChanges
                        defaultValue={state.filters.state}
                    />
                    <SelectList
                        labelWidth100
                        name='bulk_city'
                        options={uniqueOrgCities}
                        onChange={(e) => setState(s => ({ ...s, filters: { ...s.filters, city:  e.target.value !== "" ? e.target.value : undefined} }))}
                        disabled={isSubmitted}
                        noUnsavedChanges
                        defaultValue={state.filters.city}
                    />
                    <SelectList
                        labelWidth100
                        name='bulk_type'
                        options={uniqueOrgTypes}
                        onChange={(e) => setState(s => ({ ...s, filters: { ...s.filters, type: e.target.value } }))}
                        disabled={isSubmitted}
                        defaultValue={state.filters.type}
                        noUnsavedChanges
                    />
                    <OrgCategoryPicker
                        value={filters.categories ?? []} 
                        onChange={categories => setState(old => ({...old, filters: {...old.filters, categories}}))} 
                    />
                    <RadioGroup
                        label='org_cat_match_options'
                        options={[{name: "org_cat_match_any", value: false}, {name: "org_cat_match_all", value: true}]} 
                        onChange={e => setState(s => ({...s, filters: {...s.filters, matchAllCategories: e}}))} 
                        value={!!state.filters.matchAllCategories}                        
                    />
                </div>
            
                <h2><Translate id='bulk_placement_options' /></h2>
                <div className='flex'>
                    <SelectList
                        labelWidth100
                        name={'bulk_row'}
                        defaultValue={rowIndex.toString()}
                        options={[{ name: 'bulk_row', value: '-1', translate: true }, { name: '1', value: '0' }, { name: '2', value: '1' }, { name: '3', value: '2' }]}
                        onChange={(e) => setRowIndex(parseInt(e.target.value))}
                        disabled={isSubmitted}
                        noUnsavedChanges
                    />
                    <SelectList
                        labelWidth100
                        name={'bulk_position'}
                        defaultValue={position.toString()}
                        options={[{ name: '1', value: '0' }, { name: '2', value: '1' }, { name: '3', value: '2' }, { name: '4', value: '3' }, { name: '5', value: '4' }, { name: '6', value: '5' }, { name: '7', value: '6' }, { name: '8', value: '7' }, { name: '9', value: '8' }, { name: '10', value: '9' }, { name: 'bulk_pos_last', value: '-1', translate: true }]}
                        onChange={(e) => setPosition(parseInt(e.target.value))}
                        disabled={isSubmitted}
                        noUnsavedChanges
                    />
                </div>
            </form>
            
            
            <h2><Translate id={'bulk_floors_selected'} data={{ count: selectedFloors.length, total: filteredFloors.length}} /></h2>
            <div className='flex'>
                <Button className='margin-right' name='bulk_selectall' onClick={selectAll} />
                <Button className='margin-right' name='bulk_removeall' onClick={removeAll} />
                {response && <Button name='bulk_selectfailed' onClick={selectFailed} />}
            </div>
            
            <ItemContainerTable
                className='floor-table'
                columns={[
                    {
                        id: "selected", 
                        hideHeader: true,
                        value: f => <input
                            type='checkbox'
                            checked={!excluded.find(x => x === f.id)}
                            onChange={() => onFloorClick(null, f.id)}
                        />
                    },
                    { id: "country", value: x => x.org.country },
                    { id: "city", value: x => x.org.city },
                    { id: "systemName", value: x => x.org.systemName },
                    { id: "type", value: x => x.org.type },
                    { id: "categories", value: f => <div className='org-catgories'>{f.org.categories.map(x => <CategoryTagLike key={x} cat={x} />)}</div> },
                    { id: "license_name", value: x => x.name},
                    { 
                        id: "status", 
                        hideHeader: !response,
                        cmpValue: f => response?.floorResults.find(fr => fr.floorId === f.id)?.errorMessage ?? "",
                        value: f => {
                            const r = response && response.floorResults.find(fr => fr.floorId === f.id);
                            return(r &&
                                <div className={r.isSuccess ? (!r.errorMessage ? 'green' : 'yellow') : 'red' }>
                                    <FontAwesomeIcon icon={r.isSuccess ? 'check' : 'ban'} /> <Translate id={r.errorMessage || 'bulk_success'} data={{pos: r.position}} />
                                </div>
                            )  
                        }
                    }

                ]}
                pageSize={50}
                items={filteredFloors}
                defaultSort={{value: "systemName", asc: true}}

            />
            <SubmitButton split disabled={isSubmitted || rowIndex === -1} onClick={addClick}/>
        </Dialog>
    )
}

export default AddPlaylistBulk;