import React from 'react';
import { StatsContext } from '../../api/StatsContext';
import { StatsFilters } from '../../model/Stats/StatsFilters';
import CombiChart from './BaseCharts/CombiChart';

interface Props{
    filters: StatsFilters;
    showDuration?: boolean;
}

const GamesPlayedChart = (props: Props) => {
    const {filters, showDuration} = props;

    return(
        <CombiChart 
            dataFunc={StatsContext.useGamesPlayed} 
            filters={filters} 
            initialType='line'
            label={x => x?.data ?? "Unknown"} 
            title='chart_games_played'
            showDuration={showDuration}  
        />
    )
}

export default GamesPlayedChart;