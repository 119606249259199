export interface Price{
    value: number;
    currency: Currency;
}

export interface BulkPrice extends Price {
    bulkAmount: number;
}

export enum Currency{
    DKK = 'DKK',
    USD = 'USD',
    EUR = 'EUR',
    SEK = 'SEK',
    NOK = 'NOK',
    GBP = 'GBP'
}


export const equalPrice = (a?: Price, b?: Price) => a?.value === b?.value && a?.currency === b?.currency;