import React, { useEffect, useState } from 'react';
import { StatsContext } from '../../api/StatsContext';
import { StatsFilters } from '../../model/Stats/StatsFilters';
import { CombiChartWithData } from './BaseCharts/CombiChart';
import { ChartData } from '../../model/Stats/PieData';
import { isFetchError } from '../../services/FetchHelper';
import InputFormGroup from '../forms/FormGroups/InputFormGroup';
import { BaseGame } from '../../model/Game/BaseGame';
import Game from '../ModelPreview/Game';
import { preciseHumanDuration } from '../../services/CustomFunctions';

interface Props{
    filters: StatsFilters;
    showDuration?: boolean;
}

const PlaylistGamesChart = (props: Props) => {
    const {filters, showDuration} = props;

    const [getData, loading] = StatsContext.usePlaylistGamesPlayed();
    const [data, setData] = useState<ChartData<BaseGame>>();
    const [playlistId, setPlaylistId] = useState<string>("");

    useEffect(() => {
        if(filters.orgId && playlistId){
            getData(filters, playlistId).then(x => !isFetchError(x) ? setData(x) : setData(undefined));
        }
    },[filters, getData, playlistId])

    return(
        <div className='chart-with-input'>
            <InputFormGroup 
                name='playlistId'
                label='form_playlist_id'
                value={playlistId}
                onChange={e => setPlaylistId(e.target.value)}
                type='text'
                noUnsavedChanges
            />
            <CombiChartWithData
                label={x => x?.data?.title ?? "Unknown"}
                initialType='pie'
                data={data} 
                loading={loading}
                toolTip={e => (
                    <div>
                        <div><span style={{color: e.color}}>&#11044;</span> {showDuration ? preciseHumanDuration(e.data.duration) : e.data.totalValue} ({e.percent}%)</div>
                        {e.data.data && <Game game={e.data.data} />}
                    </div>
                )}
                onClick={data => {
                    const game = data.data;
                    if(game){
                        window.open(`/workshop/${game.gameType}/edit/${game.id}`);
                    }
                }} 
                showDuration={showDuration}
            />
        </div>
        
    )
}

export default PlaylistGamesChart;