import React, { ReactNode } from 'react';
import { AccountType } from '../../model/AccountType';
import Translate from '../Helper/Translate';
import { Link } from 'react-router-dom';
import Author from './Author';
import { DbDocument } from '../../model/DbDocument';
import { Button } from '../forms/FormGroup';
import moment from 'moment';
import { VerifiedMark } from '../WorkShop/VerifiedMark';

interface Props{
    preview: ReactNode;
    contentPreview?: ReactNode;
    tagList?: ReactNode;
    stats?: ReactNode;
    owner?: AccountType;
    ownerId?: string;
    authorIds: string[];
    authorClick?: () => void;
    title: string;
    description: string;
    document?: DbDocument;
    createdTime: number|string;
    editedTime: number|string;
    isVerified?: boolean;
}


const maxVisibleAuthors = 8;

const DetailedItem = (props: Props) => {
    const {preview, owner, ownerId, authorIds, title, description, document, tagList, createdTime, editedTime, contentPreview, stats, authorClick, isVerified} = props;
    const tooManyAuthors = authorIds.length > maxVisibleAuthors;

    return(
        <div className='detailed-preview'>
            <div className='part'>
                {preview}
                {(owner || ownerId) &&
                    <div className='authors'>
                        <h2><Translate id='playlist_authors' /></h2>
                        <Link to={`/library/profile/${owner?.id || ownerId}`}>
                            <Author account={owner} accountId={ownerId} />
                        </Link>
                        <div className='authors-container'>
                            {authorIds && authorIds.slice(0, tooManyAuthors ? 7 : 8).map(x =>
                                <Link key={x} to={`/library/profile/${x}`}>
                                    <Author accountId={x} small hideName />
                                </Link>
                            )}
                            {tooManyAuthors &&
                                <div className={`author-preview small`} onClick={authorClick}>
                                    <div className='fake-img'>...</div>
                                </div>
                            }
                        </div>
                    </div>
                }
                <div className='text-content'>
                    <h2 dir='auto'>{title}</h2>
                    <div dir='auto'>{description}</div>
                    {document &&
                        <Button link={document.documentPath} target={'_blank'} name={'document_download'} className={'document-btn'} icon={'file-pdf'}  />
                    }
                    <div className='details'>
                        {tagList}
                        {!!createdTime &&
                            <div className='edit-date' dir='auto'>
                                <label><Translate id='createdDateTime' /></label> {moment(createdTime).format('LL')}
                            </div>
                        }
                        {!!editedTime &&
                            <div className='edit-date' dir='auto'>
                            <label><Translate id='editedDateTime' /></label> {moment(editedTime).format('LL')}
                            </div>
                        }
                    </div>
                </div>
            </div>
            {isVerified && <VerifiedMark />}
            {contentPreview &&
                <div>
                    <h2><Translate id='preview' /></h2>
                    {contentPreview}
                </div>
            }
            {stats}
        </div>
    )
}

export default DetailedItem;