import React, { useContext } from 'react';
import Game from "../ModelPreview/Game";
import {FeaturedGame} from "../../model/FeaturedGame";
import {Button} from "../forms/FormGroup";
import {DbOrUploadImage} from "../../services/ImageHelper";
import TextView from "../TextView";
import Author from "../ModelPreview/Author";
import {Loading} from "../Loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PlayListPreview from "../ModelPreview/PlayListPreview";
import { TextAlignment } from '../../model/Game/TextType';
import { GlobalModalContext, SecureContext } from '../_MyFloor/MyFloorApp';
import { AccountContext } from '../../api/AccountContext';
import { useTranslation } from 'react-i18next';
import missingImage from '../../images/missing_banner.png';

interface Props{
    fg: FeaturedGame;
}

const FeaturedGameView = (props: Props) => {
    const {fg} = props;
    const {t} = useTranslation();
    const {me} = useContext(SecureContext);
    const {showGameToPlaylist, showPlaylistToFloor} = useContext(GlobalModalContext);

    const account = fg.account;
    const following = account && me && me.followings.includes(account.id);
    const [invokeFollow, loadingFollow] = AccountContext.useFollowAuthor();
    const [invokeUnfollow, loadingUnfollow] = AccountContext.useUnfollowAuthor();
    const followFunction = () => {
        if(following && account) invokeUnfollow(account);
        else if(account) invokeFollow(account);
    };
    const followLoad = loadingFollow || loadingUnfollow;

    return(
        <div className='featured-game'>
            <DbOrUploadImage image={fg.image} tempImage={missingImage} className='hero-image' alt='hero-img' realSize />
            <div className={'featured-content'}>
                <div className='centered' style={{background: `linear-gradient(to bottom, rgba(25, 25, 25, 0.3) ${100 - fg.shadowAmount}%, rgba(30, 30, 30, 0.8) ${100 - fg.shadowAmount + 25}%, #1e1e1e)`}}>
                    <div className='game-info'>
                        
                        <div className='text'>
                            {fg.showLabel &&
                                <div style={{
                                    textAlign: fg.labelText?.textAlignment === TextAlignment.Left
                                        ? 'left'
                                        : fg.labelText?.textAlignment === TextAlignment.Center
                                            ? 'center'
                                            : "right"}}
                                >
                                    <TextView className={`featured-type${fg.showLabelTextShadow ? ' shadow' : ''}`} element={'h1'} text={fg.labelText} style={{ background: fg.labelColorHex }} />
                                </div>
                            }
                            {fg.showText &&
                                <TextView element='h1' className={fg.showTextShadow ? 'shadow' : ''} text={fg.text} overrideFontSize={`calc(${fg.text?.fontSize || 0}px + 2vw)`} />
                            }
                            {fg.showDescription &&
                                <TextView element='p' className={fg.showDescriptionTextShadow ? 'shadow' : ''} text={fg.descriptionText} overrideFontSize={`calc(${fg.descriptionText?.fontSize || 0}px + 0.5vw)`} />
                            }
                        </div>
                        
                        <div className={fg.showContentShadow ? 'content-shadow' : ''}>
                            {fg.game &&
                                <div className='game-wrapper wrapper'>
                                    <Game game={fg.game} noMenu />
                                </div>
                            }
                            {fg.playlist &&
                                <div className='playlist-wrapper wrapper'>
                                    <PlayListPreview playlist={fg.playlist} noMenu />
                                </div>
                            }
                            {account &&
                                <div className='account-wrapper wrapper'>
                                    <Author account={account} fullImage hideName />
                                </div>
                            }
                        </div>
                    </div>
                    <div className='game-actions'>
                        {fg.game &&
                            <>
                                <Button icon={'file-import'} name={'see_content'} link={`/workshop/${fg.game?.gameType}/edit/${fg.game?.id}`} />
                                {fg.showAddButton && <Button icon={'plus'} name={'playlist_add_to'} onClick={() => showGameToPlaylist(fg.game)} />}
                            </>
                        }
                        { fg.playlist &&
                            <>
                                <Button icon={'file-import'} name={'see_content'} link={`/workshop/playlist/edit/${fg.playlistId}`} />
                                {fg.showAddButton &&  <Button icon={'plus'} name={'floor_add'} onClick={() => showPlaylistToFloor(fg.playlist)} />}
                            </>
                        }
                        {account &&
                            <>
                                <Button icon={'file-import'} name={'account_public'} link={`/library/profile/${fg.accountId}`} />
                                {account.id !== me?.id &&
                                <div className='follow-btn-container'>
                                    <button className='follow btn' onClick={following ? undefined : followFunction} disabled={!!(followLoad || following)}>
                                        <Loading visible={followLoad} noBackground/>
                                        <FontAwesomeIcon icon={following ? 'check' : 'user-plus'} className='icon'/>
                                        {t(following ? 'pp_following' : 'account_follow' )}
                                    </button>
                                </div>
                                }
                            </>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
};

export default FeaturedGameView;