import { useCallback, useState } from "react";
import { Acknowledged } from "../model/Acknowledged";
import { ContextFunc, ContextReturn, FetchError, handleResponse, hasName, useFetchPost } from "../services/FetchHelper"
import { AccountType } from "../model/AccountType";

interface TokenResponse{
    token: string;
    expiretime: string;
}
interface LoginResponse extends TokenResponse{
    account: AccountType
}

interface IAuthContext{
    useLogin: ContextFunc<LoginResponse, [string, string]>;
    useRefreshLogin: ContextFunc<TokenResponse, []>;
    useRequestPassword: ContextFunc<Acknowledged, [string]>;
    useResetPassword: ContextFunc<Acknowledged, [string, string, string]>;
}

export const AuthContext: IAuthContext = {
    useLogin: (): ContextReturn<LoginResponse, [string, string]> => {
        const [rawInvoke, loading, error] = useFetchPost<LoginResponse>();
        const invoke = useCallback((username: string, password: string) => rawInvoke('api/account/authenticate', { username, password }), [rawInvoke]);
        return [invoke, loading, error];
    },
    useRefreshLogin: (): ContextReturn<TokenResponse, []> => {
        const [rawInvoke, loading, error] = useFetchPost<TokenResponse>();
        const invoke = useCallback(() => rawInvoke('api/account/ReAuthenticate'), [rawInvoke]);
        return [invoke, loading, error];
    },
    useRequestPassword: (): ContextReturn<Acknowledged, [string]> => {
        const [rawInvoke, loading, error] = useFetchPost<Acknowledged>();
        const invoke = useCallback((email: string) => rawInvoke('api/account/requestPasswordReset', { email }), [rawInvoke]);
        return [invoke, loading, error];
    },
    useResetPassword: (): ContextReturn<Acknowledged, [string, string, string]> => {
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState<FetchError>(); 
        const invoke = useCallback(async (token: string, password: string, passwordRepeat: string) => {
            setLoading(true);
            try{
                const r = await fetch('api/account/resetpassword', {
                    method: 'post',
                    mode: 'same-origin',
                    body: JSON.stringify({password, passwordRepeat}),
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        ...(token && { Authorization: `Bearer ${token}` }),
                        ...({ 'Content-Type': 'application/json' })
                    }
                })
                const handledResponse = await handleResponse<Acknowledged>(r);
                setLoading(false);
                return handledResponse;
            }
            catch (e: unknown) {
                setLoading(false);
                if (hasName(e) && e.name === 'AbortError') {
                    return new FetchError(1, "Request was aborted", false);
                }
                else {
                    if (e instanceof FetchError) {
                        setError(e);
                        return e;
                    }
                    return new FetchError(0, "Unknown error", false);
                }
            }
        }, []);

        return [invoke, loading, error];
    }
}