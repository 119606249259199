import { useContext } from "react";
import { AccountType, accountHasAnyPermission } from "../model/AccountType";
import { Organization } from "../model/Organization";
import { PermissionType } from "../model/Permission/PermissionType";
import { PlayListType } from "../model/PlayListType";
import { SecureContext } from "../components/_MyFloor/MyFloorApp";
import useActiveOrganization from "./CustomHooks/UseActiveOrganization";

export const hasPermission = (me: AccountType|null|undefined, ...permissionsToCheck: PermissionType[]) => {
    permissionsToCheck = [...permissionsToCheck, 'Admin'];
    return accountHasAnyPermission(me, ...permissionsToCheck);
}

const isParent = (meOrg: Organization, organizationId: string) => {
    return !!meOrg.childOrganizationIds.find(x => x === organizationId);
}

const hasChildAndIsParent = (me: AccountType, meOrg: Organization, organizationId: string) => {
    return hasPermission(me, "ChildOrganizations") && isParent(meOrg, organizationId);
}

interface AuthoredModel{
    ownerId: string;
    authorIds: string[];
    organizationId: string;
    isPublic: boolean;
    isPublished: boolean;
}

const updateItem = (me: AccountType|undefined, meOrg: Organization|undefined, model: AuthoredModel|null) => {
    if(!me || !meOrg) return false;
    if(!model) return true;
    if(hasPermission(me, 'Admin')) return true;
    if(me.id === model.ownerId) return true;
    if(model.authorIds.find(x => x === me.id)) return true;
    if(hasPermission(me, 'Editor') && (model.organizationId === me.organizationId || isParent(meOrg, model.organizationId))) return true;

    return false;
}

const overridePlaylist = (me: AccountType|undefined, playlist: PlayListType) => {
    if(!me) return false;
    if(accountHasAnyPermission(me, "Floor") && playlist.organizationId !== me.organizationId) return true;

    return false;
}

const updatePlaylist = (me: AccountType|undefined, meOrg: Organization|undefined, model: PlayListType|null) => {
    if(!me || !meOrg) return false;
    if(!model) return true;
    if(updateItem(me, meOrg, model)) return true;
    //You can edit your organiaztions version of a playlist, as long as you have the floor permission and the playlist originally doesn't belong to your organization.
    //The change will in this instance not be made on the playlist it self but on a override object, that is applied to the playlist when fetched from the database.
    //Yep its weird ¯\_(ツ)_/¯.
    if(overridePlaylist(me,model)) return true;

    return false;
}

const useCanPublish = (_model: AuthoredModel|null) => {
    const {me, myOrg} = useContext(SecureContext);
    const canPublic = useCanPublic(_model);
    if(!me) return false;
    const model: AuthoredModel = _model ?? {ownerId: me.id, authorIds: [], isPublic: false, isPublished: false, organizationId: me.organizationId };
    if(!updateItem(me, myOrg, model)) return false;
    if(model.isPublished && model.isPublic && !canPublic) return false;
    if(hasPermission(me, 'Publish', 'Editor')) return true;
    return false;
}

const useCanPublic = (_model: AuthoredModel|null) => {
    const {me, myOrg} = useContext(SecureContext);
    if(!me) return false;
    const model: AuthoredModel = _model ?? {ownerId: me.id, authorIds: [], isPublic: false, isPublished: false, organizationId: me.organizationId };
    if(!updateItem(me, myOrg, model)) return false;
    if(hasPermission(me, 'Public', 'Editor')) return true;
    return false;
}

const playlistInPlaylist = (me: AccountType|undefined, meOrg: Organization|undefined, playlist: PlayListType) => {
    return hasPermission(me, 'PlaylistInPlaylist') && updateItem(me, meOrg, playlist);
}

const useIsUserEditor = () => {
    const {me, myOrg} = useContext(SecureContext);
    const [activeOrg] = useActiveOrganization();
    if (!me) return false; 
    if (hasPermission(me, "Admin")) return true; 
    if (hasPermission(me, "Users") && activeOrg && activeOrg.id === me.organizationId) return true; 
    if (myOrg && activeOrg && hasChildAndIsParent(me, myOrg, activeOrg.id)) return true; 
    return false; 
}; 

export const PermissionService = {
    playlistInPlaylist,
    updatePlaylist,
    useCanPublic,
    useCanPublish,
    overridePlaylist, 
    useIsUserEditor,
    updateItem
}