import React, { useContext } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BreadCrumbElement, { CrumbProps } from "./BreadCrumbElement";
import { SecureContext } from '../../_MyFloor/MyFloorApp';
import { isRtlActive } from '../../../services/TextHelper';


interface Props{
    crumbs: CrumbProps[];
}

const BreadCrumb = (props: Props) => {
    const {crumbs} = props;
    const {me} = useContext(SecureContext);

    const isRtl = isRtlActive(me?.language);

    return(
        <div className='bread-crumb flex' dir={isRtl ? 'rtl' : 'ltr'}>
            {crumbs.map((p,i) => 
                <React.Fragment key={p.id}>
                    <BreadCrumbElement {...p} />
                    {i !== crumbs.length - 1 && <div className='seperator'><FontAwesomeIcon icon={isRtl ? 'angle-left' : 'angle-right'} /></div>}
                </React.Fragment>
            )}
        </div>
    )
}

export default BreadCrumb;