import { useEffect, useState } from "react";
import { isFetchError } from "../../../services/FetchHelper";
import { PlaylistContext } from "../../../api/PlaylistContext";
import Dialog from "../../Dialog";
import Translate from "../../Helper/Translate";
import { OverrideWithPlaylist } from "../../../model/PlaylistStats";
import { CategoryTagLike } from "../../Organization/OrgCategoryPicker";
import { GeneralError } from "../../Error/GeneralError";
import { Button } from "../../forms/FormGroup";
import ItemContainerTable from "../../Collections/ItemContainerTable";

interface Props{
    playlistId: string;
    onClose: () => void;
}

const OverridesWithPlaylistOverview = (props: Props) => {
    const {onClose, playlistId} = props;
    const [fetchOverrides, loading, error] = PlaylistContext.useStatsOverrides();
 
    const [overrides, setOverrides] = useState<OverrideWithPlaylist[]>([]);

    useEffect(() => {
        fetchOverrides(playlistId).then(x => !isFetchError(x) && setOverrides(x));
    }, [playlistId, fetchOverrides])

    return(
        <Dialog onClose={onClose} loading={loading}>
            <h1><Translate id='playlist_on_overrides_title' /></h1>
            <GeneralError error={error} />
            <ItemContainerTable
                items={overrides} 
                columns={[
                    {id: "actions", value: x => <Button onClick={onClose} link={`/workshop/playlist/edit/${x.playlistId}`} name="link" />},
                    {id: "title_organization", value: x => x.orgName},
                    {id: "playlist_title", value: x => x.playlistName},
                    {id: "license_country", value: x => x.country},
                    {id: "org_categories", value: x => x.categories?.map(c => <CategoryTagLike cat={c} />), tdClass: "flex-items"}
                ]} 
                pageSize={20}            
            />
        </Dialog>
    )
}

export default OverridesWithPlaylistOverview;