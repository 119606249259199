import React, { ReactNode } from 'react';
import { Button, ButtonType } from '../forms/FormGroup';

interface Props{
    title?: ReactNode;
    menuPoints: ((Omit<ButtonType,'className'> & {active: boolean})|undefined)[];
}

const Subnavigation = (props: Props) => {
    const {menuPoints, title} = props;

    return(
        <div className='sub-navigation'>
            {title && <div className='title'>{title}</div>}
            {menuPoints.map(x => x && 
                <Button
                    {...x}
                    key={x.name}
                    className={`btn-black${x.active ? ' active' : ''}`} 
                />
            )}
        </div>
    )
}

export default Subnavigation;