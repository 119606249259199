import React from 'react';
import { arrayToClassName } from "../../services/CustomFunctions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Translate from '../Helper/Translate';

interface Props{
    onClick: () => void; 
    disabled?: boolean; 
    onHoverMsg?: string; 
    className?: string; 
}

const NewGameItemButton = (props: Props) => {
    const {onClick, disabled, onHoverMsg, className} = props; 
    return(
        <div className={arrayToClassName(['new-game-item', 'hover-trigger', disabled && 'disabled', className])} onClick={!disabled ? onClick : undefined}>
            <div className='new-game-item-btn'><FontAwesomeIcon icon='plus' /></div>
            {onHoverMsg && <div className="hover-msg"><Translate id={onHoverMsg}/></div>}
        </div>
    ); 
}

export default NewGameItemButton; 