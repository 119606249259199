import { BaseGame } from "../BaseGame";
import { Category } from "./Category";

export interface WordFinderGame extends BaseGame {
    categories: Category[];
}

export const WordFinderConstants = {
    minGridSize: 5,
    maxGridSize: 10, 
    maxCategoryCount: 5, 
    hintImgSize: 140
}