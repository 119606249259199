import React, {useEffect, useState} from 'react';
import Dialog from "../Dialog";
import Author from "../ModelPreview/Author";
import {Loading} from "../Loading";
import ConfirmationForm from "./games/ConfirmationForm";
import {AccountType, isAccountType} from '../../model/AccountType';
import InputFormGroup from './FormGroups/InputFormGroup';
import { FetchError, isFetchError } from '../../services/FetchHelper';
import { GeneralError } from '../Error/GeneralError';
import Translate from '../Helper/Translate';
import { PlaylistContext } from '../../api/PlaylistContext';
import { PlayListType } from '../../model/PlayListType';
import { SearchResult } from '../../model/Response/SearchResult';
import { AccountContext } from '../../api/AccountContext';

interface Props {
    chooseFunction: (account: AccountType) => void;
    chooseDelete?: (account: AccountType) => void;
    loading?: boolean;
    visible: boolean;
    onClose: () => void;
    ownerId: string;
    authorIds?: string[];
    error: FetchError|undefined;
}

const AuthorChooser = (props: Props) => {
    const {error, ownerId, loading, visible, onClose, authorIds, chooseDelete, chooseFunction} = props;
    const [searchTerm, setSearchTerm] = useState("");
    const [, setSearchFire] = useState<ReturnType<typeof setTimeout>>();
    const [confirmRemove, setConfirmRemove] = useState<AccountType>();
    const [searchResult, setSearchResults] = useState<SearchResult<AccountType> & {searchTerm: string}>();
    const [owner, setOwner] = useState<AccountType>();

    const [searchAccounts, loadingSearch] = AccountContext.useSearch();
    const [getAccount, loadingAccount] = AccountContext.useAccount();

    useEffect(() => {
        getAccount(ownerId, true).then(x => !isFetchError(x) && setOwner(x));
    }, [ownerId, getAccount]);

    useEffect(() => {
        searchTerm && setSearchFire(x => {
            if(x) clearTimeout(x);
            return setTimeout(() => 
                searchAccounts({searchString: searchTerm, ascending: true, sort: 'firstName'})
                    .then(x => !isFetchError(x) && setSearchResults({...x, searchTerm}))
            , 500);
        });
    }, [searchTerm, searchAccounts]);

    if(!visible) return null;


    return(
        <Dialog onClose={onClose} className='author-chooser-dialog' loading={loading||loadingAccount}>
            <h2><Translate id='current_authors'/></h2>
            <div className='authors'>
                {owner && isAccountType(owner) &&
                    <Author account={owner} hideName />
                }
                {authorIds && authorIds.map(x => {
                    return (
                        <div key={x} className='relative clickable float' >
                            <Author accountId={x} small hideName icon={'trash'} onClick={chooseDelete ? acc => setConfirmRemove(acc) : undefined} />
                        </div>
                    )
                })}
                <div className='clear-fix'/>
            </div>
            <h2><Translate id='author_choose'/></h2>
            <InputFormGroup
                name='search'
                type='text'
                placeholder='search'
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                noLabel
                noUnsavedChanges
            />

            <div className='authors-container'>
                <Loading visible={loadingSearch} noBackground/>
                {searchResult?.items.map(acc => {
                    return <Author
                        key={acc.id}
                        account={acc}
                        onClick={() => chooseFunction(acc)}
                    />
                })}
                <div className='clear-fix'/>
            </div>
            {confirmRemove &&
                <ConfirmationForm
                    text='remove_author_confirm'
                    confirmText='yes'
                    cancelText='no'
                    onConfirm={() => {
                        if(chooseDelete) chooseDelete(confirmRemove);
                        setConfirmRemove(undefined);
                    }}
                    onCancel={() => setConfirmRemove(undefined)}
                />
            }
            <GeneralError error={error} />
        </Dialog>
    )
};

export default AuthorChooser;


interface PlaylistProps{
    playlist: PlayListType;
    onClose: () => void;
    onUpdated: (p: PlayListType) => void;
}

export const AuthorsForPlaylist = (props: PlaylistProps) => {
    const {playlist, onClose, onUpdated} = props;
    const [addAuthor, loadingAddAuthor, errorAddAuthor] = PlaylistContext.useAddAuthor();
    const [removeAuthor, loadingRemoveAuthor, errorRemoveAuthor] = PlaylistContext.useRemoveAuthor();

    return(
        <AuthorChooser
            chooseFunction={author => addAuthor(playlist.id, author.id).then(r => !isFetchError(r) && onUpdated(r))}
            chooseDelete={author => removeAuthor(playlist.id, author.id).then(r => !isFetchError(r) && onUpdated(r))}
            loading={loadingAddAuthor || loadingRemoveAuthor}
            onClose={onClose}                    
            ownerId={playlist.ownerId}
            authorIds={playlist.authorIds}
            error={errorAddAuthor || errorRemoveAuthor}
            visible
        />
    )
}

export const OwnerForPlaylist = (props: PlaylistProps) => {
    const {playlist, onClose, onUpdated} = props;
    const [changeOwner, loadingChangeOwner, errorChangeOwner] = PlaylistContext.useChangeOwner();

    return(
        <AuthorChooser
            chooseFunction={author => changeOwner(playlist.id, author.id).then(r => !isFetchError(r) && onUpdated(r))}
            loading={loadingChangeOwner}
            onClose={onClose}                    
            ownerId={playlist.ownerId}
            authorIds={playlist.authorIds}
            error={errorChangeOwner}
            visible
        />
    )
}