import React, { useContext, useEffect, useState } from 'react';
import { Button, CheckBox, SelectList } from '../forms/FormGroup';
import GameTypesChart from '../StatCharts/GameTypesChart';
import GamesPlayedChart from '../StatCharts/GamesPlayedChart';
import { Organization, OrganizationCategories, OrganizationCategory } from '../../model/Organization';
import { GlobalModalContext, SecureContext } from '../_MyFloor/MyFloorApp';
import moment from 'moment';
import AuthorsChart from '../StatCharts/AuthorsChart';
import GamesChart from '../StatCharts/GamesChart';
import { FloorContext } from '../../api/FloorContext';
import { Floor } from '../../model/Floor';
import { isFetchError } from '../../services/FetchHelper';
import Translate from '../Helper/Translate';
import GameTypePickerIcons, { ChartGameTypeContent, ChartGameTypes, ChartGameTypesStatic } from '../forms/FormGroups/GameTypePickerIcon';
import { StatsFilters } from '../../model/Stats/StatsFilters';
import CombiChart from '../StatCharts/BaseCharts/CombiChart';
import { StatsContext } from '../../api/StatsContext';
import { useTranslation } from 'react-i18next';
import { getLanguageName } from '../../model/LocalizationVariables';
import LatestGamesChart from '../StatCharts/LatestGames';
import useActiveOrganization from '../../services/CustomHooks/UseActiveOrganization';
import PlaylistGamesChart from '../StatCharts/PlaylistGamesChart';
import DatePickerFormGroup from '../forms/FormGroups/DatePickerFormGroup';
import GameOrgUsageChart from '../StatCharts/GameOrgUsageChart';
import { InfoBox } from '../forms/games/ConfirmationForm';

const OrganizationCharts = () => {
    const categoryInSessionsDate = moment("2024-08-20");
    const {me} = useContext(SecureContext);
    const {setLayoutButtons} = useContext(GlobalModalContext);
    const {t} = useTranslation();
    const setChosenOrg = (org: Organization|undefined) => {
        setFilters(x => ({
            ...x,
            orgId: org?.id,
            includeChildren: org?.type === "Reseller",
            ownerId: undefined,
            floorId: undefined
        }));
    };
    const [activeOrg, orgPicker, topMenuButton] = useActiveOrganization(setChosenOrg);

    const [getFloors] = FloorContext.useOrgFloors();
    const [showDuration, setShowDuration] = useState<boolean>(false);
    const [floors, setFloors] = useState<Floor[]>([]);
    const [timePeriod, _setTimePeriod] = useState<string>("30");
    const [categoryWarning, showCategoryWarning] = useState<"show"|"dismissed"|"initial">("initial");
    const setTimePeriod = (s: string) => {
        _setTimePeriod(s);
        if(s !== "custom"){
            setFilters(x => ({...x, startEpoch: moment().add(Number.parseInt(s) * -1, "days").unix(), endEpoch: moment().unix()}));
        }
    }

    const [filters, setFilters] = useState<StatsFilters>({  
        orgId: activeOrg?.id,
        includeChildren: false,
        startEpoch: moment().add(Number.parseInt(timePeriod) * -1, "days").unix(),
        endEpoch: moment().unix(),
        gameTypes: [],
        category: undefined,
        ownerId: undefined,
        floorId: undefined
    })

    useEffect(() => {
        setLayoutButtons(topMenuButton);
        return () => {setLayoutButtons(undefined)};
    }, [setLayoutButtons, topMenuButton])

    useEffect(() => {
        if(activeOrg?.id){
            setFloors([]);
            getFloors(activeOrg.id).then(x => !isFetchError(x) && setFloors(x));
        }
    }, [getFloors, activeOrg?.id]);

    if(
        categoryWarning === "initial" &&
        filters.category &&
        filters.startEpoch < categoryInSessionsDate.unix()
    ){
        showCategoryWarning("show");
    }

    return(
        <div className='charts-dashboard'>
            <h1><Translate id='charts_org_title' data={{org: activeOrg?.name ?? ""}} /></h1>
            <form onSubmit={e => e.preventDefault()} >
                {floors && floors.length > 1 && 
                    <SelectList
                        name='floor'
                        options={[{name: 'stats_floor_all', value: '', translate: true}, ...floors.map(f => ({name: f.name, value: f.id}))]}
                        onChange={e => setFilters(x => ({...x, floorId: e.target.value, includeChildren: false}))}
                        defaultValue={filters.floorId}
                        noLabel
                        noUnsavedChanges
                    />
                }
                <SelectList 
                    name='time'
                    noLabel
                    noUnsavedChanges 
                    defaultValue={timePeriod} 
                    onChange={e => setTimePeriod(e.target.value)} 
                    options={[
                        {name: "chart_time_7_days", value: "7", translate: true},
                        {name: "chart_time_30_days", value: "30", translate: true},
                        {name: "chart_time_180_days", value: "180", translate: true},
                        {name: "chart_time_365_days", value: "365", translate: true},
                        {name: "chart_time_1825_days", value: "1825", translate: true},
                        {name: "custom", value: "custom"}
                    ]} 
                />
                <DatePickerFormGroup 
                    name='startEpoch'
                    noLabel
                    hideUnsaved
                    value={moment.unix(filters.startEpoch)}
                    onChangeUnix={e => {setFilters(x => ({...x, startEpoch: e.target.value})); setTimePeriod("custom");}}
                />
                <DatePickerFormGroup 
                    name='endEpoch'
                    noLabel
                    hideUnsaved
                    value={moment.unix(filters.endEpoch)}
                    onChangeUnix={e => {setFilters(x => ({...x, endEpoch: e.target.value})); setTimePeriod("custom")}}
                />
                {activeOrg?.type === "Reseller" &&
                    <CheckBox 
                        name='chart_include_child' 
                        active={filters.includeChildren} 
                        onChange={e => setFilters(x => ({...x, includeChildren: e.target.value, floorId: e.target.value ? undefined : x.floorId}))} 
                        hideUnsaved 
                    />
                }
                <CheckBox name='duration' active={showDuration} onChange={e => setShowDuration(e.target.value)} hideUnsaved />
                <div className='full-width'>
                    <GameTypePickerIcons values={filters.gameTypes} onChange={e => setFilters(x => ({...x, gameTypes: e}))} />
                </div>
                <div className='game-types-quick-selectors'>
                    <Button icon='check' name='chart_filter_gametypes_all' onClick={() => setFilters(x => ({...x, gameTypes: ChartGameTypes}))} />
                    <Button icon={'check'} name='chart_filter_gametypes_static' onClick={() => setFilters(x => ({...x, gameTypes: ChartGameTypesStatic})) } />
                    <Button icon={'check'} name='chart_filter_gametypes_content' onClick={() => setFilters(x => ({...x, gameTypes: ChartGameTypeContent})) } />
                    <Button icon='cancel' name='chart_filter_gametypes_none' onClick={() => setFilters(x => ({...x, gameTypes: []}))} />
                </div>
                <div className='full-width'>
                    <SelectList
                        name='category'
                        noLabel
                        onChange={e => setFilters(x => ({...x, category: e.target.value as OrganizationCategory ?? ""}))}
                        defaultValue={filters.category ?? ""}
                        options={[{name: 'filter_category_choose', value: ""}, ...OrganizationCategories.map(c => ({name: `org_cat_${c}`, value: c}))]}
                        noUnsavedChanges
                        translate
                    />
                </div>
            </form>
            <div className='charts'>
                <LatestGamesChart filters={filters} />
                <GameTypesChart filters={filters} showDuration={showDuration} />
                <GamesChart filters={filters} showDuration={showDuration} />
                <GamesPlayedChart filters={filters} showDuration={showDuration} />
                {me &&
                    <CombiChart 
                        dataFunc={StatsContext.useSubjects} 
                        filters={filters} 
                        initialType='pie'
                        label={x => getSubjectString(me.language, t, x?.data)} 
                        title='chart_subjects'  
                        showDuration={showDuration}
                    />
                }
                <AuthorsChart filters={filters} showDuration={showDuration} />
                <CombiChart 
                    dataFunc={StatsContext.useAuthorOrgs} 
                    filters={filters} 
                    initialType='pie'
                    label={x => x?.data?.name ?? "Unknown"}
                    title='chart_author_orgs'  
                    showDuration={showDuration}
                />
                {filters.includeChildren &&
                    <CombiChart 
                        initialType='pie' 
                        dataFunc={StatsContext.useOrgActivity} 
                        filters={filters} 
                        label={x => x?.data?.name ?? "Unknown"} 
                        title='chart_active_org' 
                        showDuration={showDuration}
                    />
                }
                <CombiChart 
                    dataFunc={StatsContext.usePopularWeekdays} 
                    filters={filters} 
                    initialType='bar'
                    label={x => x?.data ? t(`weekday_${x.data}`) : "Unknown"} 
                    title='chart_weekdays'  
                    showDuration={showDuration}
                />     
                <PlaylistGamesChart
                    filters={filters}
                    showDuration={showDuration}
                />
                <GameOrgUsageChart
                    filters={filters}
                    showDuration={showDuration}
                />  
            </div>
            {orgPicker}
            {categoryWarning === "show" &&
                <InfoBox
                    text='charts_category_warning_date'
                    textData={{date: categoryInSessionsDate.format("LL")}}
                    onClose={() => showCategoryWarning("dismissed")}
                />
            }
        </div>
    )
}

const getSubjectString = (locale: string, t: ReturnType<typeof useTranslation>['t'], sub: string|undefined) => {
    if(sub === undefined) return "Unknown";
    if(sub === "Other") return "Other";
    if(sub === "") return t("subject_none");
    if(sub.length > 3 || sub === "art") return t(sub);

    return getLanguageName(locale, sub);
}

export default OrganizationCharts;