import React, {ReactNode, useContext, useEffect, useState} from 'react';
import {BaseGame } from "../../model/Game/BaseGame";
import Author from "./Author";
import Game from "./Game";
import GameRelatedInfo from "../WorkShop/GameRelatedInfo";
import { Loading } from '../Loading';
import { isFetchError } from '../../services/FetchHelper';
import { Button } from '../forms/FormGroup';
import { GameType } from '../../model/Game/GameType';
import { GameTagList } from '../WorkShop/GameTagList';
import { getLanguageName } from '../../model/LocalizationVariables';
import SlideShow from '../WorkShop/SlideShow';
import { SecureContext } from '../_MyFloor/MyFloorApp';
import Translate from '../Helper/Translate';
import { GameIcons } from '../Helper/GameIcons';
import DetailedItem from './DetailedItem';
import { GameContext } from '../../api/Game/GameContext';

interface Props{
    gameType: GameType;
    game?: BaseGame;
    tempDescription?: string;
    tempTitle?: string;
    hideTranslations?: boolean;
    onClick?: () => void;
    showStats?: boolean;
    altPreview?: ReactNode;
    editAuthors?: () => void;
}
export interface PreviewSettingsProps<T> {
    settings: T;
    scale?: number;
}

const GameDetailed = (props: Props) => {
    const {gameType, game, tempDescription, tempTitle, hideTranslations, onClick, showStats, altPreview, editAuthors} = props;
    const {me} = useContext(SecureContext);
    const originalId = game?.parentId || game?.id;

    const [copies, setCopies] = useState<BaseGame[]>([]);
    const [getCopies, loadingCopies] = GameContext.useGetCopies();
    

    useEffect(() => {
        if(originalId && !hideTranslations){
            getCopies(originalId).then(result => !isFetchError(result) && setCopies(result));
        }
        else{
            setCopies([]);
        }
    }, [originalId, getCopies, hideTranslations]);

    useEffect(() => {
        setCopies(cp => {
            if(!game) return [];
            if (cp.find(g => g.id === game.id)) {
                return cp.map(g => g.id === game.id ? game : g);
            }
            else {
                return [...cp, game];
            }
        });
    }, [game]);

    return(
        <>
            <DetailedItem
                preview={<Game game={game} onlyImage noMenu onClick={game?.deleted ? undefined : onClick} />}
                owner={game?.owner}
                ownerId={game?.ownerId}
                authorIds={game?.authorIds ?? []}
                authorClick={editAuthors}
                title={game?.id ? game.title : tempTitle ?? ''}
                description={game?.id ? game.description : tempDescription ?? ''}
                createdTime={game?.createdDateTime ?? 0}
                editedTime={game?.editedDateTime ?? 0}
                document={game?.document}
                tagList={game && <GameTagList game={game} />}
                contentPreview={altPreview || <SlideShow path={`images/skins/${gameType}/${game?.skin || 'Default'}`} />}
                stats={showStats && game && <GameRelatedInfo gameId={game.id} />}
                isVerified={game?.isVerified}
            />
            {!hideTranslations && copies && copies.length > 0 &&
                <div className='game-copies'>
                    <Loading visible={loadingCopies} />
                    <h3><Translate id={'game_copies'}/></h3>
                    {copies && copies.map(gc => 
                        <CopiesButton
                            original={!gc.parentId}
                            key={gc.id}
                            title={gc.title}
                            active={gc.id === game?.id}
                            authorId={gc.ownerId}
                            url={`/workshop/${gc.gameType}/edit/${gc.id}`}
                            languageName={getLanguageName(me?.language, gc.languageCode)}
                            gameType={gc.gameType}
                        />
                    )}
                                    
                </div>
            }
        </>
    )
}

interface CopiesButtonProps{
    original: boolean;
    title: string;
    active: boolean;
    url: string;
    authorId: string;
    languageName: string;
    gameType: GameType
}
const CopiesButton = (props: CopiesButtonProps) => {
    const {title, active, url, authorId, original, languageName, gameType} = props;
    return(
        <Button
            className={`${active ? 'active' : ''}${original ? ' original' : ''}`}
            link={url}
        >
            <span className='float'>
                <Author accountId={authorId} className='owner-img' hideName/>
            </span>
            <span className='float'>
                
                <div className='title'>{title}</div>
                <div className='owner'>{languageName}</div>
            </span>
            <span className='float'>
                <div className='game-symbol'><img src={GameIcons[gameType]} alt={gameType}/></div>
            </span>
            <div className='clear-fix'/>
        </Button>
    )
};

export default GameDetailed;

