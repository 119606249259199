import { useEffect, useMemo, useState } from 'react';
import { PlaylistContext, PlaylistWithContent } from '../../../api/PlaylistContext';
import { EventEffects } from '../../../events/PlaylistEvents';
import { BaseGame } from '../../../model/Game/BaseGame';
import { PlayListType } from '../../../model/PlayListType';
import { notUndefined, regexMatch, toDict } from '../../../services/CustomFunctions';
import { isFetchError } from '../../../services/FetchHelper';
import { DictGetValues } from '../../../services/JsDict';
import { DragState } from './PlaylistExplorer';
import { SortByCustom } from '../../../services/SortFunctions';
import { contentDifference } from './Helpers';

const getRootPlaylists = (playlists: PlayListType[]) => {
    return playlists.filter(x => !x.parentId).sort((a,b) => SortByCustom(a, b, "name", true));
}

const getLastNode = (path: PlayListType[]) => path.length > 0 ? path[path.length -1] : undefined; 

interface ListItem{
    type: string;
    title: string;
    classNames: string;
    itm: PlayListType | BaseGame;
    isGhost: boolean;
    originalIndex: number;
}

type ReturnType = [
    visibleContent: ListItem[], 
    pathPlaylists: PlayListType[], 
    explorerItemsLoading: boolean
];

const useExplorerItems = (_playlists: PlayListType[], path: string[], typeFilter: 'game'|'playlist'|undefined, search: string, dragState: DragState|undefined) : ReturnType => {
    //Context
    const [getPlaylistContent, loadingContent] = PlaylistContext.useGetWithContent();

    //State
    const [content, setContent] = useState<{[playlistId: string]: PlaylistWithContent}>({});
    const [loadingContentId, setLoadingContentId] = useState<string[]>([]);

    //Calulated State
    const playlists = useMemo(() => [..._playlists, ...DictGetValues(content).flatMap(x => x.playlists)], [_playlists, content]);
    const rootPlaylists = useMemo(() => getRootPlaylists(_playlists),[_playlists]);
    const pathPlaylists = useMemo(() => path.map(p => playlists.find(x => x.id === p)).filter(notUndefined), [playlists, path]);

    const pathEnd = useMemo(() => getLastNode(pathPlaylists), [pathPlaylists]);
    const loadingCurrent = !!loadingContentId.find(x => x === pathEnd?.id);

    //This listener could be avoided if we were willing to fetch new content everytime a playlist is updated.
    //Purpose here is to try and see if we can update the fetchedContent from already existing state
    EventEffects.usePlaylistUpdatedEffect(p => {
        setContent(oldContent => {
            //Make sure to update all appearances of the playlist, in content dictionary 
            const content = toDict(DictGetValues(oldContent), x => x.playlist.id, x => ({ 
                playlist: x.playlist.id === p.id ? p : x.playlist,  
                playlists: x.playlists.map(x => x.id === p.id ? p : x), 
                games: x.games 
            }));

            const c = content[p.id];
            //No content exists for the updated playlist, just return current content
            if(!c){
                return content;
            }
            //calculate the difference
            const {extraGames, extraPlaylists, missingGames, missingPlaylists} = contentDifference(c, p);

            //We cannot fix, delete current content for that playlistId and refetch when needed
            if(missingGames.length > 0 || missingPlaylists.length > 0){
                delete content[p.id];
                return content;
            }

            //we can fix the fetched content simply by removing the extra games and playlists,
            return{
                ...content,
                [p.id]: {
                    playlist: p,
                    games: c.games.filter(x => !extraGames.includes(x.id)),
                    playlists: c.playlists.filter(x => !extraPlaylists.includes(x.id))
                }
            }
        })
    }, []);

    EventEffects.usePlaylistDeletedEffect(pId => {
        setContent(oldContent => 
            toDict(DictGetValues(oldContent).filter(x => x.playlist.id !== pId),
            x => x.playlist.id,
            x => ({
                playlist: x.playlist,
                games: x.games,
                playlists: x.playlists.filter(x => x.id !== pId)
            })
        ))
    },[])
    

    useEffect(() => {
        const playlistId = pathEnd && pathEnd?.id;
        if(playlistId && content[playlistId] === undefined && !loadingContentId.find(x => x === playlistId)){
            setLoadingContentId(x => ([...x, playlistId]));
            getPlaylistContent(playlistId).then(result => {
                if(!isFetchError(result)){
                    setContent(x => ({...x, [playlistId]: result}));
                    setLoadingContentId(x => x.filter(x => x !== playlistId));
                }
            })
        }
    }, [pathEnd, content, getPlaylistContent, loadingContentId]);

    const visibleItems = useMemo(() => { 
        if(pathEnd){
            const fetchedContent = content[pathEnd.id];
            if(fetchedContent){
                const playlists = toDict(fetchedContent.playlists, x => x.id, x => x);
                const games     = toDict(fetchedContent.games, x => x.id, x => x);
                const items = fetchedContent.playlist.combinedSort.map(x => {
                    const game = !typeFilter || typeFilter === 'game' ? games[x.id] : undefined;
                    if(game) return game;
                    const playlist = !typeFilter || typeFilter === 'playlist' ? playlists[x.id] : undefined;
                    if(playlist) return playlist;
                    return undefined;
                })
                return items.filter(notUndefined);
            }
            else{
                //Content is loading...
                return [];
            }
        }

        return rootPlaylists;
    },[rootPlaylists, pathEnd, content, typeFilter]); 

    const renderItems = useMemo(() => {
        const itms = visibleItems.map((x,i) => ({
            itm: x, 
            isGhost: false, 
            type: isPlaylist(x) ? 'list' : 'game', 
            title: getTitle(x), 
            classNames: `${search ? (regexMatch(search, getTitle(x), x.description, x.tags ?? "") ? 'high' : 'grey') : ''}`,
            originalIndex: i
        }));
        if(dragState?.draggedItem && dragState?.targetIndex !== undefined){
            itms.splice(dragState.targetIndex, 0, {
                itm: dragState.draggedItem, 
                isGhost: true,
                type: isPlaylist(dragState.draggedItem) ? 'list' : 'game',
                title: getTitle(dragState.draggedItem),
                classNames: 'sort-preview',
                originalIndex: -1
            });
        }
        return itms;
    },[dragState?.targetIndex, visibleItems, dragState?.draggedItem, search]);

    return [renderItems, pathPlaylists, loadingContent||loadingCurrent];
}

export default useExplorerItems;

const getTitle = (item: PlayListType|BaseGame) => isPlaylist(item) ? item.name : item.title;
const isPlaylist = (item: PlayListType|BaseGame): item is PlayListType => "games" in item;