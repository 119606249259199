export interface TextType {
    content: string;
    fontFamily: string;
    fontSize: number;
    fontColorHex: string;
    textAlignment?: TextAlignment;
}

export interface TextTypeStyle{
    fontFamily: string;
    fontSize: number;
    fontColorHex: string;
    textAlignment?: TextAlignment;
}

export enum TextAlignment{
    Left = "Left", Center = "Center", Right = "Right"
}