import { useCallback } from "react";
import { Floor } from "../model/Floor";
import { FloorContent } from "../model/Response/FloorContent";
import { ContextFunc, useFetchDelete, useFetchGet, useFetchPost, useFetchPut } from "../services/FetchHelper";
import { SortedItem } from "../model/SortedItem";
import { ActiveFloorAction } from "../model/Schedule/ScheduledAction";
import { DayOfWeek } from "../model/Schedule/DeleteScheduledActionModel";
import { ChangeAppearance } from "../model/Floor/ChangeAppearance";
import { AddPlaylistBulkType } from "../model/Request/playlist/AddPlaylistBulk";
import { AddPlaylistBulkResponse } from "../model/Response/AddPlaylistBulkResponse";

export interface CreateScheduledTaskModel{
    action: ActiveFloorAction,
    dayOfWeeks: DayOfWeek[], 
    hours: number, 
    minutes: number
}

export interface RemoveScheduledTaskModel{
    action: ActiveFloorAction,
    dayOfWeek: DayOfWeek, 
    hours: number, 
    minutes: number
}

export interface IFloorContext{
    useFloor: ContextFunc<Floor, [string]>;
    useResetFloor: ContextFunc<Floor, [string, string]>;
    useOrgFloors: ContextFunc<Floor[], [string]>;
    useFloorContent: ContextFunc<FloorContent, []>;
    useAll: ContextFunc<Floor[], []>;
    useSort: ContextFunc<Floor, [string, {rowIndex: number, menuItems: SortedItem[]}]>;
    useMoveRow: ContextFunc<Floor, [string, {id: string, rowIndex: number}]>;
    useAddPlaylist: ContextFunc<Floor, [string, string, number, number?]>;
    useAddGame: ContextFunc<Floor, [string, string, number, number?]>;
    useRemoveGame: ContextFunc<Floor, [string, string, number, number]>;
    useRemovePlaylist: ContextFunc<Floor, [string, string, number, number]>;
    useAddScheduledItem: ContextFunc<Floor, [string, CreateScheduledTaskModel]>;
    useRemoveScheduledItem: ContextFunc<Floor, [string, RemoveScheduledTaskModel]>;
    useUpdateAppearance: ContextFunc<Floor, [string, ChangeAppearance]>;
    useAddPlaylistBulk: ContextFunc<AddPlaylistBulkResponse, [AddPlaylistBulkType]>;
}

const baseUrl = "api/floor";

export const FloorContext: IFloorContext = {
    useFloor: () => {
        const [rawInvoke, loading, error] = useFetchGet<Floor>();
        const invoke = useCallback((id: string) => rawInvoke(`${baseUrl}/${id}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useResetFloor: () => {
        const [rawInvoke, loading, error] = useFetchPost<Floor>();
        const invoke = useCallback((floorId: string, templateApiKey: string) => rawInvoke(`${baseUrl}/${floorId}/reset`, { id: templateApiKey }), [rawInvoke]);
        return [invoke, loading, error];
    },
    useOrgFloors: () => {
        const [rawInvoke, loading, error] = useFetchGet<Floor[]>();
        const invoke = useCallback((orgId: string) => rawInvoke(`${baseUrl}/byorg/${orgId}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useFloorContent: () => {
        const [rawInvoke, loading, error] = useFetchGet<FloorContent>();
        const invoke = useCallback(() => rawInvoke('api/account/primaryfloorcontent'), [rawInvoke]);
        return [invoke, loading, error];
    },
    useAll: () => {
        const [rawInvoke, loading, error] = useFetchGet<Floor[]>();
        const invoke = useCallback(() => rawInvoke(`${baseUrl}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useSort: () => {
        const [rawInvoke, loading, error] = useFetchPost<Floor>();
        const invoke = useCallback(
            (floorId: string, model: { rowIndex: number; menuItems: SortedItem[]; }) => rawInvoke(`api/floor/${floorId}/sort`, model),
            [rawInvoke]
        );
        return [invoke, loading, error];
    },
    useMoveRow: () => {
        const [rawInvoke, loading, error] = useFetchPost<Floor>();
        const invoke = useCallback(
            (floorId: string, model: { id: string; rowIndex: number; }) => rawInvoke(`api/floor/${floorId}/changeRow`, model),
            [rawInvoke]);
        return [invoke, loading, error];
    },
    useAddPlaylist: () => {
        const [rawInvoke, loading, error] = useFetchPost<Floor>();
        const invoke = useCallback(
            (floorId: string, playlistId: string, rowIndex: number, sortIndex?: number) => rawInvoke(`api/floor/${floorId}/playlist`, { playlistId, rowIndex, SortIndex: sortIndex ?? -1 }),
            [rawInvoke]
        );
        return [invoke, loading, error];
    },
    useAddGame: () => {
        const [rawInvoke, loading, error] = useFetchPost<Floor>();
        const invoke = useCallback(
            (floorId: string, gameId: string, rowIndex: number, sortIndex?: number) => rawInvoke(`api/floor/${floorId}/game`, { gameId, rowIndex, SortIndex: sortIndex ?? -1 }),
            [rawInvoke]
        );
        return [invoke, loading, error];
    },
    useAddScheduledItem: () => {
        const [rawInvoke, loading, error] = useFetchPost<Floor>();
        const invoke = useCallback((floorId: string, model: CreateScheduledTaskModel) => rawInvoke(`api/floor/${floorId}/scheduledAction`, model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useRemoveScheduledItem: () => {
        const [rawInvoke, loading, error] = useFetchPost<Floor>();
        const invoke = useCallback((floorId: string, model: RemoveScheduledTaskModel) => rawInvoke(`api/floor/${floorId}/scheduledAction/remove`, model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useUpdateAppearance: () => {
        const [rawInvoke, loading, error] = useFetchPut<Floor>();
        const invoke = useCallback((floorId: string, model: ChangeAppearance) => rawInvoke(`api/floor/${floorId}/appearance`, model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useRemoveGame: () => {
        const [rawInvoke, loading, error] = useFetchDelete<Floor>();
        const invoke = useCallback(
            (floorId: string, gameId: string, rowIndex: number, sortIndex: number) =>
                rawInvoke(`api/floor/${floorId}/game/${gameId}/${rowIndex}/${sortIndex}`)
            , [rawInvoke]
        )
        return [invoke, loading, error];
    },
    useRemovePlaylist: () => {
        const [rawInvoke, loading, error] = useFetchDelete<Floor>();
        const invoke = useCallback(
            (floorId: string, playlistId: string, rowIndex: number, sortIndex: number) =>
                rawInvoke(`api/floor/${floorId}/playlist/${playlistId}/${rowIndex}/${sortIndex}`)
            , [rawInvoke]
        )
        return [invoke, loading, error];
    },
    useAddPlaylistBulk: () => {
        const [rawInvoke, loading, error] = useFetchPost<AddPlaylistBulkResponse>();
        const invoke = useCallback(
            (model: AddPlaylistBulkType) => rawInvoke('api/floor/playlist/bulk', model)
            , [rawInvoke]
        );
        return [invoke, loading, error];
    }
}