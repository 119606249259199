import moment from "moment";
import React, { CSSProperties, MouseEvent, useContext, useEffect, useState } from "react";
import { GameContext } from "../../api/Game/GameContext";
import missingImage from "../../images/games/missingimage220x150.png";
import { BaseGame } from "../../model/Game/BaseGame";
import { convertHexToRgba } from "../../services/ColorHelper";
import { isFetchError } from "../../services/FetchHelper";
import { DbOrUploadImage } from "../../services/ImageHelper";
import Translate from "../Helper/Translate";
import { Loading } from "../Loading";
import TextView from "../TextView";
import { GameTagList } from "../WorkShop/GameTagList";
import { SecureContext } from "../_MyFloor/MyFloorApp";
import Author from "./Author";
import GameMenuDefault from "./GameMenuDefault";
import IconMenu, { IconMenuPointProps } from "./IconMenu";
import { ReactComponent as LicenseExpiredSvg} from '../../images/license_expired_info.svg';

interface Props{
    game?: BaseGame;
    gameId?: string;
    count?: number;
    onClick?: (gameId: string) => void;
    onlyImage?: boolean;
    noPadding?: boolean;
    className?: string;
    noMenu?: boolean;
    menuHideEdit?: boolean;
    customMenu?: IconMenuPointProps[];
    renderCustomMenu?: (g: BaseGame) => IconMenuPointProps[];
    autoScale?: boolean;
    greyed?: boolean;
    style?: CSSProperties;
    hideBadges?: boolean;
    width?: number; 
}

const Game = (props: Props) => {
    const {game: _game, gameId, count, onClick: _onClick, onlyImage, noPadding, className, noMenu, customMenu, renderCustomMenu, width: _width, style: _style, hideBadges } = props;
    const {floorContent, isRtl} = useContext(SecureContext);
    const [showMenu, setShowMenu] = useState(false);
    const [getGame] = GameContext.useBaseGame();
    const [fetchedGame, setGame] = useState<BaseGame>();
    const game = _game || fetchedGame;

    useEffect(() => {
        if(gameId){
            getGame(gameId).then(x => !isFetchError(x) && setGame(x));
        }
    }, [gameId, getGame]);

    if(!game && !gameId) return null;

    if(!game) return <div className={`game-preview${noPadding ? ' no-padding': ''}`}><Loading visible /></div>

    const scale = _width ? _width / 220 : 1;
    const width = 220 * scale;
    const height = 150 * scale;

    const onClick = (e: MouseEvent<HTMLElement>) => {
        if(_onClick){
            e.stopPropagation();
            game.id && _onClick(game.id);
        }
        else if(!noMenu){
            setShowMenu(x => !x);
        }
    };

    const style = scale ? { ..._style, width: 220 * scale} : _style;

    return (
        <div
            className={`item-icon ${className || ''}${_onClick || !noMenu ? ' clickable' : ''}`}
            onClick={onClick}
            style={style ? style : scale !== 1 ? { width: `${width}px`} : undefined}
        >
            <GameIcon
                game={game}
                menuVisible={showMenu}
                noMenu={noMenu}
                playlistCount={count}
                customMenu={renderCustomMenu ? renderCustomMenu(game) : customMenu}
                onFloor={!!(game.id && floorContent?.gameContent?.[game.id])}
                hideBadges={hideBadges}
                width={width}
                height={height}
                scale={scale}
            />
            { !onlyImage &&
            <>
                <div className='flex space-between' dir={isRtl ? 'rtl' : undefined}>
                    <div className='title' dir="auto">{game.title}</div>
                    {game.owner && <Author account={game.owner} hideName />}
                </div>
                <div className='text-wrapper' style={{height: `${90 * scale}px`}}>
                    <GameTagList game={game} ellipsis />
                    <div className='description' dir='auto'>{game.description}</div>
                </div>
            </>  
            }
        </div>
    );
}

export default Game;

interface GameIconProps{
    game: BaseGame;
    customMenu?: IconMenuPointProps[];
    menuVisible: boolean;
    noMenu?: boolean;
    playlistCount?: number;
    onFloor?: boolean;
    hideBadges?: boolean;
    width: number;
    height: number;
    scale: number;
}

const GameIcon = (props: GameIconProps) => {
    const {game, customMenu,  menuVisible, noMenu, playlistCount, onFloor, hideBadges, width, height, scale} = props;
    const topGradColor = convertHexToRgba(game.labelColorHex || "#000000", 0.867);
    const botGradColor = convertHexToRgba(game.labelColorHex || "#000000", 0);
    const labelStyle = {
        background: `linear-gradient(to bottom, ${topGradColor}, ${botGradColor}`
    };
    const draft = !game.isPublished;
    const isNews = game.isPublic && moment(game.createdDateTime).isAfter(moment().add(-30, 'days'));
    const isUpdated = !isNews && game.isPublic && moment(game.editedDateTime).isAfter(moment().add(-14, 'days'));

    const iconstyle = {transform: `scale(${scale})`, background: game.backgroundColorHex};

    return(
        <div className={`icon-wrapper`} style={scale !== 1 ? { width: `${width}px`, height: `${height}px` } : undefined}>
            <div className='icon' style={iconstyle}>
                <div className={draft ? 'draft' : ''} style={{backgroundColor: game.backgroundColorHex || '#FFF'}}>
                    <DbOrUploadImage image={game.image} tempImage={missingImage} className='image' realSize/>
                    <img src={`/images/games/gicon/game_${game.gameType}.png`} alt='' className='gicon'/>
                    {game.showLabel && <div className='icon-label' style={labelStyle} /> }
                    {game.showTitle && 
                        <TextView 
                            element="div" 
                            className="icon-title" 
                            text={{
                                content: game.title ?? "", 
                                fontColorHex: game.fontColorHex ?? "", 
                                fontFamily: game.fontFamily ?? "Lato", 
                                fontSize: game.fontSize ?? 20
                            }}
                            defaultAlignment={'initial'} 
                        />
                    }
                    {playlistCount !== undefined && <div className='game-pl-count'>{playlistCount}</div>}
                </div>

                {!hideBadges && draft &&
                    <div className='draft-label' dir="ltr">
                        <div className='draft-title'><Translate id='draft'/></div>
                        <div className='draft-text'><Translate id='draft_info'/></div>
                    </div>
                }

                {!hideBadges && !game.isPublished &&
                    <div className='draft-label-2'>
                        <div><Translate id='draft'/></div>
                    </div>
                }

                {!hideBadges && game.isPublished && !game.isPublic &&
                    <div className='publish-label'>
                        <div className='publish-title'><Translate id='isPublished'/></div>
                    </div>
                }

                {!hideBadges && game.isPublished && game.isPublic &&
                    <div className='public-label'>
                        <div className='public-title'><Translate id='isPublic'/></div>
                    </div>
                }

                {!noMenu && !customMenu && <GameMenuDefault visible={menuVisible} game={game} />}
                {!noMenu && customMenu && <IconMenu visible={menuVisible} menuPoints={customMenu} />} 
                
                {!hideBadges &&
                    <div className='badge-container'>
                        {isNews && <div className='badge new-badge'><Translate id='new_badge' /></div>}
                        {isUpdated && <div className='badge update-badge'><Translate id='update_badge' /></div>}
                        {onFloor && <div className='badge floor-badge'><Translate id='floor_badge' /></div>}
                        {game.isUnlicensed &&
                            <div className='expired-label'>
                                <LicenseExpiredSvg />
                                <div className='text hover-trigger'>
                                    <div><Translate id='unlicensed_title' /></div>
                                    <div className="hover-msg"><Translate id={game.requiresLicense ? 'game_unlicensed_required_active_description' : 'game_unlicensed_description'} /></div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
};