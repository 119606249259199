import React, {ReactNode, useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import profile from '../../images/missing-profile.png';
import {DbOrUploadImage} from "../../services/ImageHelper";
import { GlobalModalContext, SecureContext } from '../_MyFloor/MyFloorApp';
import Translate from '../Helper/Translate';

interface Props{
    buttons: ReactNode;
}

const TopMenu = (props: Props) => {
    const {buttons} = props;
    const {me, myOrg} = useContext(SecureContext);
    const {showAccountEditForm} = useContext(GlobalModalContext);

    const [showProfileNav, setShowProfileNav] = useState<boolean>(false);
    const toggleProfileNav = () => setShowProfileNav(old => !old);

    return (
        <div className='header-container'>
            <div className='header'>
                <div className='buttons'>
                    {buttons}
                </div>
                {me &&
                    <div className={`profile ${showProfileNav ? 'active' : ''}`} onClick={toggleProfileNav}>
                        <DbOrUploadImage image={me.profileImage} tempImage={profile}/>
                        <p className='name'>{me.firstname} {me.lastname}</p>
                        <div className='profile-menu'>
                            <FontAwesomeIcon icon={showProfileNav ? 'caret-up' : 'caret-down'} />
                        </div>
                        {showProfileNav &&
                            <div className='profile-nav'>
                                <div className='menu-point-container' onClick={() => showAccountEditForm(me)}>
                                    <div className='menu-point'>
                                        <FontAwesomeIcon icon='edit'/> <Translate id='menu_edit_profile'/>
                                    </div> 
                                </div>
                                <Link to={`/library/profile/${me.id}`}>
                                    <div className='menu-point-container'>
                                        <div className='menu-point'>
                                            <FontAwesomeIcon icon='user'/> <Translate id='menu_show_public_profile'/>
                                        </div>
                                    </div>
                                </Link>
                                {myOrg &&
                                    <Link to={`/discover/org/${me.organizationId}`}>
                                        <div className='menu-point-container'>
                                            <div className='menu-point'>
                                                <FontAwesomeIcon icon='building'/> {myOrg.name}
                                            </div>
                                        </div>
                                    </Link>
                                }
                                <Link to={'/logout'}>
                                    <div className='menu-point-container'>
                                        <div className='menu-point'>
                                            <FontAwesomeIcon icon='sign-out-alt'/><Translate id='menu_logout'/>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    );
};

export default TopMenu;