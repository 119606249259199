import React, { FormEvent, useContext, useEffect, useState } from 'react';
import { GlobalModalContext, SecureContext } from './_MyFloor/MyFloorApp';
import Dialog from './Dialog';
import { CheckBox, SubmitButton } from './forms/FormGroup';
import { TrackingOptionsRequest } from '../model/Request/TrackingOptionsRequest';
import { AccountType } from '../model/AccountType';
import Translate from './Helper/Translate';
import { AccountContext } from '../api/AccountContext';
import { isFetchError } from '../services/FetchHelper';
import { GeneralError } from './Error/GeneralError';

const extractModelFromUser = (x?: AccountType) => ({
    loginHistoryConsent: x?.trackingOptions?.loginHistoryConsent?.isGiven ?? true
});

const TrackingOptions = () => {
    const {me} = useContext(SecureContext);
    const [model, setModel] = useState<TrackingOptionsRequest>(extractModelFromUser(me));
    const [updateTracking, loading, error] = AccountContext.useUpdateTrackingOptions();
    useEffect(() => {
        setModel(extractModelFromUser(me));
    }, [me]);
    const {showTrackingOptions, popMsg} = useContext(GlobalModalContext);

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        
        const result = await updateTracking(model);
        if(!isFetchError(result)){
            popMsg("consent_pop_title", "consent_pop_msg_saved");
            showTrackingOptions(false);
        }
    }

    return (
        <Dialog onClose={() => showTrackingOptions(false)} loading={loading}>
            <h2><Translate id={'tracking_consent_title'} /></h2>
            <form onSubmit={onSubmit}>
                <CheckBox 
                    name='tracking_login_history'
                    hoverMsg='tracking_login_history_explain' 
                    active={model.loginHistoryConsent}
                    onChange={e => setModel(x => ({...x, loginHistoryConsent: e.target.value}))} 
                />
                <GeneralError error={error} />
                <SubmitButton split />
            </form>

        </Dialog>

    )
}

export default TrackingOptions;