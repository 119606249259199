import React from 'react';
import { CheckBox, CheckBoxProps } from './FormGroup';
import FieldErrors from './FieldErrors';
import { FetchError } from '../../services/FetchHelper';
import Translate from '../Helper/Translate';

interface RadioGroupProps<T> {
    name?: string;
    options: (Omit<CheckBoxProps, 'active'|'onChange'|'hideUnsaved'> & {value: T})[];
    onChange: (value: T) => void;
    value: T;
    hideUnsaved?: boolean;
    errors?: FetchError;
    errorKey?: string;
    label?: string;
    direction?: "row"|"column";
}

export const RadioGroup = <T extends unknown> (props: RadioGroupProps<T>) => {   
    const {options, onChange, value, hideUnsaved, errors, errorKey: _errorKey, name, label, direction: _direction} = props;
    const direction = _direction ?? "column";
    const errorKey = _errorKey || name;
    return (
        <div className={`radio-group`}>
            {label && <div className='label'><Translate id={label} /></div>}
            <div className={direction}>
                {options.map(p => 
                    <CheckBox {...p}
                        key={p.name} 
                        onChange={() => onChange(p.value)} 
                        active={p.value === value} 
                        hideUnsaved={hideUnsaved || value !== p.value}
                    />
                )}
            </div>
            {errorKey && errors && <FieldErrors errors={errors} fieldname={errorKey} />}
        </div>
    )
}