import {AccountType, getFullName} from "../model/AccountType";
import {Organization} from "../model/Organization";
import {DayOfWeek} from "../model/Schedule/DeleteScheduledActionModel";

export const SortByFirstName = (a: AccountType, b: AccountType): number => {
    if(!a || !a.firstname) return 1;
    if(!b || !b.firstname) return -1;
    const x = a.firstname.toLowerCase();
    const y = b.firstname.toLowerCase();
    if(x < y) return -1;
    if(x > y) return 1;
    return 0;
};

export const SortByFullName = (a: AccountType, b: AccountType): number => {
    if(!a) return 1;
    if(!b) return -1;
    const x = getFullName(a); 
    const y = getFullName(b);
    if(x < y) return -1;
    if(x > y) return 1;
    return 0;
}

export const SortByName = (a: {name: string}, b: {name: string}): number => {
    if(!a || !a.name) return 1;
    if(!b || !b.name) return -1;

    const x = a.name.toLowerCase();
    const y = b.name.toLowerCase();
    if(x < y) return -1;
    if(x > y) return 1;
    return 0;
};

export const SortBySystemName = (a: Organization, b: Organization): number => {
    if(!a || !a.systemName) return 1;
    if(!b || !b.systemName) return -1;

    const x = a.systemName.toLowerCase();
    const y = b.systemName.toLowerCase();
    if(x < y) return -1;
    if(x > y) return 1;
    return 0;
};

export const SortStringCompare = (a: string, b: string, ascending: boolean) => {
    if ( a < b ){
        return ascending ? -1 : 1;
    }
    if ( a > b ){
        return ascending ? 1 : -1;
    }

    return 0;
}

export const SortNumberCompare = (a: number, b: number, ascending: boolean) => {
    return ascending ? a - b : b - a;
    
}

export const SortByCustom = <T>(a: T, b: T, field: keyof T, ascending = false) => {
    const undefA = a?.[field] === null || a?.[field] === undefined;
    const undefB = b?.[field] === null || b?.[field] === undefined;
    if(undefA && undefB) return 0;
    if(undefA) return ascending ? 1 : -1;
    if(undefB) return ascending ? -1 : 1;

    const x = a[field];
    const y = b[field];
    return SortByCustomValue(x, y, ascending);
};

const SortByCustomValue = <T> (x: T, y: T, ascending = false) => {
    if(typeof x === "string" && typeof y === "string"){
        const xS = x.toLowerCase();
        const yS = y.toLowerCase();
        if(xS < yS) return ascending ? -1 : 1;
        if(xS > yS) return ascending ? 1 : -1;
        return 0;
    }
    if(typeof x === 'number' && typeof y === 'number'){
        const nanA = isNaN(x);
        const nanB = isNaN(y);
        if(nanA && nanB) return 0;
        if(nanA) return ascending ? 1 : -1;
        if(nanB) return ascending ? -1 : 1;
    }
    if(x < y) return ascending ? -1 : 1;
    if(x > y) return ascending ? 1 : -1;
    return 0;
}

export const SortByDateEpoch = <T>(a: T, b: T, field: keyof T, ascending = false) => {
    const undefA = !a?.[field];
    const undefB = !b?.[field];
    if(undefA && undefB) return 0;
    if(undefA) return ascending ? 1 : -1;
    if(undefB) return ascending ? -1 : 1;

    const x = a[field];
    const y = b[field];
    return SortByCustomValue(x, y, ascending);
};

export const SortByWeekday = (a: DayOfWeek, b: DayOfWeek): number => {
    const order = {
        "Monday": 1,
        "Tuesday": 2,
        "Wednesday": 3,
        "Thursday": 4,
        "Friday": 5,
        "Saturday": 6,
        "Sunday": 7
    };
    return order[a] - order[b];
};