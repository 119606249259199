import { useEffect, useState } from "react";
import { isFetchError } from "../../../services/FetchHelper";
import { PlaylistContext } from "../../../api/PlaylistContext";
import Dialog from "../../Dialog";
import Translate from "../../Helper/Translate";
import { PlaylistWithPlaylist } from "../../../model/PlaylistStats";
import { GeneralError } from "../../Error/GeneralError";
import { Button } from "../../forms/FormGroup";
import ItemContainerTable from "../../Collections/ItemContainerTable";

interface Props{
    playlistId: string;
    onClose: () => void;
}

const PlaylistsWithPlaylistOverview = (props: Props) => {
    const {onClose, playlistId} = props;
    const [fetchPlaylists, loading, error] = PlaylistContext.useStatsPlaylists();
 
    const [playlists, setPlaylists] = useState<PlaylistWithPlaylist[]>([]);

    useEffect(() => {
        fetchPlaylists(playlistId).then(x => !isFetchError(x) && setPlaylists(x));
    }, [playlistId, fetchPlaylists])

    return(
        <Dialog onClose={onClose} loading={loading}>
            <h1><Translate id='playlist_on_floors_title' /></h1>
            <GeneralError error={error} />
            <ItemContainerTable
                items={playlists} 
                columns={[
                    {id: "actions", value: x => <Button onClick={onClose} link={`/workshop/playlist/edit/${x.id}`} name="link" />},
                    {id: "playlist_title", value: x => x.playlistName}
                ]} 
                pageSize={20}            
            />
        </Dialog>
    )
}

export default PlaylistsWithPlaylistOverview;