import { useEffect, useState } from "react";
import { StatsContext } from "../../api/StatsContext";
import { StatsFilters } from "../../model/Stats/StatsFilters";
import { DecoratedSession } from "../../model/Stats/DecoratedSession";
import { isFetchError } from "../../services/FetchHelper";
import ItemContainer from "../ModelPreview/ItemContainer";
import Game from "../ModelPreview/Game";
import moment from "moment";
import Translate from "../Helper/Translate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props{
    filters: StatsFilters;
}

const LatestGamesChart = (props: Props) => {
    const {filters} = props;
    const [sessions, setSessions] = useState<DecoratedSession[]>([]);
    const [getGames, loading] = StatsContext.useLatestGames();

    useEffect(() => {
        getGames({...filters, startEpoch: 0}).then(x => !isFetchError(x) && setSessions(x));
    }, [filters, getGames]);


    return(
        <ItemContainer 
            heading={<h2><Translate id="chart_latest_sessions" /></h2>} 
            loading={loading}
            items={sessions} 
            className={'scroll-x latest-games'}
            itemRender={x => 
                <div key={x.id} className="list-item">
                    <Game game={x.game} onlyImage />
                    <div className="latest-info">
                        <div><FontAwesomeIcon icon={"building"} /> {x.organization.name}</div>
                        <div><FontAwesomeIcon icon={"calendar"} /> {moment.unix(x.session.endTime).format("lll")}</div>
                        <div><FontAwesomeIcon icon={"clock"} /> {moment.duration(x.session.duration, "seconds").humanize()}</div>
                    </div>
                </div>
            } 
        />
    )
}

export default LatestGamesChart;