import React from 'react';
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import { Button } from '../forms/FormGroup';
import Translate from '../Helper/Translate';

export interface TopMenuButtonProps{
    action?: () => void;
    link?: string;
    icon: IconProp;
    hoverMsg?: string;
    disabled?: boolean;
    className?: string;
}

const TopMenuButton = (props: TopMenuButtonProps) => {
    const {action, link, icon, hoverMsg, disabled, className} = props;

    return(
        <Button link={link} icon={icon} disabled={disabled} onClick={action} className={`${hoverMsg ? ' hover-trigger' : ''} ${className || ''}`}>
            {hoverMsg && <div className='hover-msg'><Translate id={hoverMsg}/></div>}
        </Button>
    )
};

export default TopMenuButton;