import React, { MouseEvent, ReactNode, RefObject, useContext } from "react";
import { FetchError } from "../../services/FetchHelper";
import { GeneralError } from "../Error/GeneralError";
import Translate from "../Helper/Translate";
import { Loading } from "../Loading";
import { SecureContext } from "../_MyFloor/MyFloorApp";

interface Props<T>{
    loading?: boolean;
    items: T[];
    heading: ReactNode;
    subheading?: ReactNode; 
    itemRender: (t: T, i: number) => ReactNode;
    className?: string;
    error?: FetchError;
    containerRef?: RefObject<HTMLDivElement>;
    onMouseMove?: (e: MouseEvent<HTMLDivElement>) => void;
    vertical?: boolean; 
    after?: ReactNode; 
}

const ItemContainer = <T extends unknown>(props: Props<T>) => {
    const {isRtl} = useContext(SecureContext);
    const {loading, items, heading, subheading, itemRender, className, error, onMouseMove, containerRef, vertical, after} = props; 

    return(
        <div className={`item-container${className ? ` ${className}` : ''}${vertical ? ' vertical' : ''}`} dir={isRtl ? 'rtl' : 'ltr'}>
            <div >
                <div className='item-container-heading'>{heading}</div>
                {subheading && <div className='item-container-sub-heading'>{subheading}</div>}
            </div>
            {error && <GeneralError error={error} />}
            <div className='list' ref={containerRef} onMouseMove={onMouseMove}>
                <Loading visible={loading} />
                {items && items.map(itemRender)}
                {!error && (!items || items.length === 0) && <div className='secondary'><Translate id={'empty_list'} /></div>}
                {after}
            </div>
        </div>
    )
};

export default ItemContainer;