import React, { useContext } from "react";
import {BaseGame} from "../../model/Game/BaseGame";
import {accountHasAnyPermission, accountIsAuthor, accountIsOwner} from "../../model/AccountType";
import IconMenu from "./IconMenu";
import { GlobalModalContext, SecureContext } from "../_MyFloor/MyFloorApp";

interface Props{
    game: BaseGame;
    visible: boolean;
}

const GameMenuDefault = (props: Props) => {
    const {game, visible} = props;
    const {me} = useContext(SecureContext);
    const {showGameToPlaylist, showGameToFloor} = useContext(GlobalModalContext);
    const isAuthor = accountIsOwner(me, game) || accountIsAuthor(me, game);

    return <IconMenu 
        visible={visible} 
        menuPoints={[
            {link: `/workshop/${game.gameType}/edit/${game.id}`, icon: 'file-import', label: isAuthor ? 'edit_content' : 'see_content'},
            game.isPublished ? {onClick: () => showGameToPlaylist(game), icon: 'plus-square', label: 'playlist_add_to'} : undefined,
            game.isPublished && accountHasAnyPermission(me, "Admin", "Floor", "ChildOrganizations") ? {onClick: () => showGameToFloor(game), icon: 'folder-plus', label: 'game_add_to_floor'} : undefined
        ]}
    />;
}

export default GameMenuDefault;