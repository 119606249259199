import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';

export interface Curtain{
    id: string;
    node: ReactNode;
    onDismiss: () => void;
}

interface Props {
    curtains: Curtain[];
}

const CurtainsContainer = (props: Props) => {
    const {curtains} = props;

    return (
        <div className='curtains-container'>
            {curtains.map(x => {
                return(
                    <div key={x.id} className={'curtain'}>
                        {x.node}
                        <div className='close' onClick={x.onDismiss}><FontAwesomeIcon icon={'times'} /></div>
                    </div>
                )
            })}
        </div>
    )
}

export default CurtainsContainer;