import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useEchart from '../../../services/CustomHooks/UseEchart';
import { ChartData, DataPoint } from '../../../model/Stats/PieData';
import { Button } from '../../forms/FormGroup';
import { createPortal } from 'react-dom';
import { ContextFunc, isFetchError } from '../../../services/FetchHelper';
import { StatsFilters } from '../../../model/Stats/StatsFilters';
import { preciseHumanDuration } from '../../../services/CustomFunctions';
import ItemContainerTable from '../../Collections/ItemContainerTable';
import Translate from '../../Helper/Translate';

interface EChartEvent<T>{
    color: string;
    data: DataPoint<T>;
    percent: number;
}

export type ChartType = "pie"|"line"|'bar'|'table';

interface Props<T>{
    dataFunc: ContextFunc<ChartData<T>, [StatsFilters]>;
    filters: StatsFilters;
    title?: string;
    label: (x: DataPoint<T>|undefined) => string;
    raceMode?: boolean;
    toolTip?: (params: EChartEvent<T>) => ReactNode;
    onClick?: (params: DataPoint<T>) => void;
    initialType: ChartType;
    disableTypeChoice?: boolean;
    showDuration?: boolean;
}

const dateFormat = "ll";

const CombiChart = <T,> (props: Props<T>) => {
    const {dataFunc, filters} = props;
    const [getData, loading] = dataFunc();
    const [data, setData] = useState<ChartData<T>>();

    useEffect(() => {
        if(filters.orgId){
            getData(filters).then(x => !isFetchError(x) ? setData(x) : setData(undefined));
        }
    },[filters, getData])

    return (
        <CombiChartWithData
            {...props} 
            data={data} 
            loading={loading}
        />
    );
}

interface WithDataProps<T>{
    data: ChartData<T>|undefined;
    loading: boolean;
    title?: string;
    label: (x: DataPoint<T>|undefined) => string;
    raceMode?: boolean;
    toolTip?: (params: EChartEvent<T>) => ReactNode;
    onClick?: (params: DataPoint<T>) => void;
    initialType: ChartType;
    disableTypeChoice?: boolean;
    showDuration?: boolean;
}

export const CombiChartWithData = <T,> (props: WithDataProps<T>) => {
    const {data, loading, title, label, initialType, raceMode, toolTip, onClick, disableTypeChoice, showDuration} = props;
    const {t} = useTranslation();
    const containerRef = useRef<HTMLDivElement>(null);
    const chart = useEchart(containerRef, loading ?? false);

    const [mode, setMode] = useState<ChartType>(initialType);
    const [toolTipNode, setToolTipNode] = useState<{node: ReactNode, id: string}>();
    const [toolTipId] = useState(crypto.randomUUID());

    const element = toolTipNode?.id && document.getElementById(toolTipId);
    const toolTipFunc = useCallback((e: EChartEvent<T>|EChartEvent<T>[]) => {
        const params = Array.isArray(e) ? e[0] : e;
        if(toolTip){
            setToolTipNode(old => {
                if(old?.id === params.data?.id){
                    return old;
                }
                else if(params.data?.id && toolTip){
                    return {id: params.data?.id, node: toolTip(params)}
                }
                else{
                    return undefined;
                }
            }); 
            return element || `<div class='chart-tool-tip' id='${toolTipId}'></div>`;
        }
    },[toolTip, toolTipId, element]);

    useEffect(() => {
        if(chart){
            switch(mode){
                case "line":
                    chart.setOption({
                        legend: {show:true, type: "scroll", bottom: 0},
                        xAxis: {type: "time", min: data?.startEpoch ?? 0, max: data?.endEpoch ?? 0},
                        yAxis: {},
                        grid: {
                            top: title ? "40px" : 0,
                            bottom: "50px",
                            left: 0,
                            right: 0,
                            width: 'auto',
                            height: 'auto',
                        },
                        title: {text: title ? t(title) : "", show: !!title, align: 'center'},
                        tooltip: {
                            trigger: "axis", 
                            show: true, 
                            axisPointer: {
                                label: {
                                    formatter: (x: {value: number}) => moment.unix(x.value / 1000).format(dateFormat),
                                }
                            },
                            valueFormatter: (value: number) => showDuration ? preciseHumanDuration(value) : value
                        }, 
                        series: data?.data.filter(x => data.timeData[x?.id]).map(dataPoint => ({
                            type: 'line',
                            top: 0,
                            name: label(dataPoint),
                            data: data.timeData[dataPoint.id].map(x => [x.time, showDuration ? x.duration : x.value]),
                            endLabel: raceMode ? { show: true, formatter: '{a}: {@1}' } : undefined,
                            showSymbol: !raceMode
                        })),
                        animationDuration: raceMode ? 100000 : 500
                    }, true);
                    break;
                case "pie":
                    chart.setOption({
                        tooltip: toolTip 
                        ? {
                            formatter: toolTipFunc, 
                            show: true
                        } 
                        : {
                            formatter: (params: {name: string, value: number, percent: number}) => `${params.name}: ${showDuration ? preciseHumanDuration(params.value) : params.value} (${params.percent}%)`, 
                            //valueFormatter: (value: number) => showDuration ? preciseHumanDuration(value) : value,
                            show: true, 
                            trigger: "item"
                        },
                        series: [
                            {
                                type: mode, 
                                top: 25,
                                radius: ["0", "80%"],
                                data: data?.data.map(x => ({...x, name: x.id === "Other" ? "Other" : label(x), value: showDuration ? x.duration : x.totalValue})), 
                                emphasis: {label: {show: true, fontWeight: "bold"}},
                                label: {formatter: "{b} {d}%", show: true, bleedMargin: 0},
                                labelLine: {moveOverlap: "shiftY"}
                            }
                        ],
                        title: { text: title ? t(title) : undefined}
                    }, true);
                    break;
                case "bar":
                    chart.setOption({
                        tooltip: toolTip 
                        ? {
                            formatter: toolTipFunc, 
                            show: true,
                            trigger: "axis",
                        } 
                        : {formatter: "{b}: {c}", show: true, trigger: "axis"},
                        xAxis: {type: "value" },
                        yAxis: {type: "category", data: data?.data.map(x => x?.id === "Other" ? " Other" : label(x))},
                        colorBy: "data",
                        series: [
                            {
                                type: mode, 
                                data: data?.data.map(x => ({
                                    ...x, 
                                    name: x?.id === "Other" ? "Other" : label(x), 
                                    value: showDuration ? x.duration : x.totalValue
                                 })), 
                                emphasis: {label: {show: true, fontWeight: "bold"}}
                            }
                        ],
                        
                        title: { text: title ? t(title) : undefined}
                    }, true);
                    break;
            }
        }
        if(chart && onClick){
            chart.on("click", e => onClick(e.data as DataPoint<T>));
        }
        return () => {chart?.off("click")};
    } ,[data, chart, title, t, raceMode, mode, toolTip, toolTipFunc, onClick, label, showDuration]);

    return(
        <div className='chart-wrapper'>
            <div ref={containerRef} className={`chart${mode === "table" ? ' hide' : ''}`}/>
            {mode === "table" && 
                <div className='chart-table'>
                    <h2><Translate id={title} /></h2>
                    <ItemContainerTable
                        items={data?.data ?? []} 
                        columns={[
                            {id: "name", value: label},
                            {id: "amount", header: "played_count", value: x => x.totalValue},
                            {id: "duration", header: 'time_played', value: x => preciseHumanDuration(x.duration), cmpValue: x => x.duration}
                        ]}
                        defaultSort={{value: 'duration', asc: false}}
                        pageSize={16}
                        csvDownload                    
                    />
                </div> 
            }

            {!disableTypeChoice && 
                <div className='btns'>
                    <Button icon={'pie-chart'} onClick={e => setMode('pie')} />
                    <Button icon={'bar-chart'} onClick={e => setMode('bar')} />
                    <Button icon={'line-chart'} onClick={e => setMode('line')} />
                    <Button icon={'table'} onClick={e => setMode('table')} />
                </div>
            }
            {element && createPortal(toolTipNode.node, element)}
        </div>
    )
}

export default CombiChart;