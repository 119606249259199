import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FetchError } from '../../services/FetchHelper';
import { getToken } from '../../services/LocalStorageService';
import Translate from '../Helper/Translate';
import { useEffect, useRef } from 'react';
import FieldErrors from '../forms/FieldErrors';

interface Props {
    error?: FetchError;
}

const getErrorMessage = (statusCode: number) => {
    switch(statusCode){
        case 400:
            return "bad_request_error";
        case 401:
            return getToken() ? "unauthorized_error" : "login_expired";
        case 404:
            return "notfound_error";
        case 500:
            return "server_error";
        default:
            return "unknown_error";
    }
}

export const GeneralError = (props: Props) => {
    const {error} = props;
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if(error && ref.current){
            ref.current?.scrollIntoView({inline: 'nearest', block: 'nearest', behavior: 'smooth'});
        }  
    },[error])
    if(!error) return null;
    const {status, rawBody} = error;

    const msg = getErrorMessage(error.status);
    return(
        <div className='form-error general-error' ref={ref}>
            <FontAwesomeIcon icon='exclamation-triangle' /> <Translate id={msg} />
            {status === 401 &&
               <UnauthorizedError rawBody={rawBody} />
            }
            <FieldErrors errors={error} fieldname='general' />
        </div>
    )
};

interface UnauthorizedProps{
    rawBody: string|undefined;
}

const UnauthorizedError = (props: UnauthorizedProps) => {
    const {rawBody} = props;
    const reasons = rawBody ? JSON.parse(rawBody) as string[] : [];
    return(
        <>
            {!!reasons.length &&
                <ul>
                    {reasons.map(x => <li key={x}><Translate id={x} /></li>)}
                </ul>
            } 
        </>
    )
}