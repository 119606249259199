import { ReactNode } from "react";
import { TableColumn, TableSort } from "./SimpleTable";

const genericSortFunc = (a: ReactNode, b: ReactNode, asc: boolean) => {
    if ((a ?? 0) < (b ?? 0)){
        return asc ? -1 : 1;
    }
    if ((a ?? 0) > (b ?? 0)){
        return asc ? 1 : -1;
    }
    return 0;
}

export const sortTableItems = <T extends {id: string}> (items: T[], columns: TableColumn<T>[], sort: TableSort) => {
    const {cmpValue: cmpFnc, value: valueF} = columns.find(x => x.id === sort?.value) ?? {cmpValue: undefined, value: () => ""};
    if(sort.value){
        const copy = [...items];
        copy.sort((a,b) => cmpFnc ? genericSortFunc(cmpFnc(a), cmpFnc(b), sort.asc) : genericSortFunc(valueF(a), valueF(b), sort.asc));
        return copy;
    }
    return items;
}