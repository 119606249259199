import { DbImage } from "../DbImage";

export type AdType = typeof adTypes[number];
export const adTypes = ["BeforeGameStart", "AfterGameEnd", "AboveGridPlaylist", "LockScreen"] as const;

export interface Ad{
    id: string;
    adType: AdType;
    image: DbImage;
    startEpoch: number;
    target: AdTarget;
}

export interface AdTarget{
    types: string[];
    countries: string[];
    language: string;
    resellerIds: string[];
    resellerIdBlacklist: string[];
}

export const adSizes: {[key in AdType]: {width: number, height: number}} = {
    BeforeGameStart: {width: 1280, height: 800},
    AfterGameEnd: {width: 1280, height: 800},
    AboveGridPlaylist: {width: 800, height: 90},
    LockScreen: {width: 1000, height: 250}
}