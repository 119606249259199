import { distinct } from "../services/CustomFunctions";
import { getLanguageName } from "./LocalizationVariables";

export enum AlphabetType {
    Danish = "Danish",
    Swedish = "Swedish",
    Norwegian = "Norwegian",
    Hebrew = "Hebrew",
    English = "English",
    German = "German",
    Dutch = "Dutch",
    Spanish = "Spanish", 
    Malay = "Malay"
}


export const languageCodeToAlphabet: {[key: string]: AlphabetType} = {
    da: AlphabetType.Danish,
    en: AlphabetType.English,
    no: AlphabetType.Norwegian,
    sv: AlphabetType.Swedish,
    nl: AlphabetType.Dutch,
    he: AlphabetType.Hebrew,
    iw: AlphabetType.Hebrew,
    de: AlphabetType.German,
    es: AlphabetType.Spanish,
    ms: AlphabetType.Malay
}

export const alphabetTypeToLanguageName = (x: AlphabetType, locale: string) =>  {
    const getLanguageCode = (x: AlphabetType) => 
        Object.entries(languageCodeToAlphabet).find(alphabet => alphabet[1] === x)?.[0]; 
    return getLanguageName(locale, getLanguageCode(x) || 'en'); 
}

export const languageCodeToAcceptedLetters = (languageCode: string) => alphabetTypeToAcceptedLetters[languageCodeToAlphabet[languageCode]]; 

export const alphabetTypeToAcceptedLetters: {[key in AlphabetType]: string[]} = {
    Danish: [ "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k","l","m","n","o","p","q","r","s","t","u","v","w","x","z","y","æ","ø","å", " "],
    Dutch:  [ "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k","l","m","n","o","p","q","r","s","t","u","v","w","x","z","y", " "],
    English: [ "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k","l","m","n","o","p","q","r","s","t","u","v","w","x","z","y", " "],
    German:  [ "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k","l","m","n","o","p","q","r","s","t","u","v","w","x","z","y", 'ä', 'ö', 'ü', 'ß', " "],
    Hebrew: [ "ב" ,"ג" ,"ד" ,"ה" ,"ו", "ז", "ח", "ט", "י", "כ", "ל", "מ", "נ", "ס", "ע", "פ", "צ", "ק", "ר", "ש", "ת", "\"", "-", "'", "ף", "ך", "ץ","ן" ,"ם", "א", " "],
    Malay:  [ "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k","l","m","n","o","p","q","r","s","t","u","v","w","x","z","y", " "],
    Norwegian: [ "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k","l","m","n","o","p","q","r","s","t","u","v","w","x","z","y","æ","ø","å", " "],
    Spanish: [ "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k","l","m","n","o","p","q","r","s","t","u","v","w","x","z","y","ñ", " "],
    Swedish: [ "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k","l","m","n","o","p","q","r","s","t","u","v","w","x","z","y","ö","ä","å", " "]
}
export const groupByCharacterSets = (alphabets: AlphabetType[]) => {
    const items = Object.entries(alphabetTypeToAcceptedLetters).filter(x => alphabets.includes(x[0] as AlphabetType)); 
    const grouped: {alphabets: string[], characters: string[]}[] = []; 
    for (const item of items){
        const i = grouped.findIndex(x => alphabetsAreEqual(item[1], x.characters)); 
        if (i === -1){
            grouped.push({alphabets: [item[0]], characters: item[1]}); 
        }
        else grouped[i].alphabets.push(item[0]); 
    }
    return grouped.map(x => ({alphabets: x.alphabets as AlphabetType[], characters: x.characters.join("")})); 
}

export const getAlphabetTypes = (x: string[]): AlphabetType[] => {
    const res: AlphabetType[] = []; 
    for (const e of Object.entries(alphabetTypeToAcceptedLetters)){
        if(alphabetsAreEqual(x, e[1])){
            res.push(e[0] as AlphabetType);
        }
    }
    return res;
}

const alphabetsAreEqual = (_xChars: string[], _yChars: string[]): boolean => {
    const xChars = _xChars.filter(x => x.trim().length > 0); 
    const yChars = _yChars.filter(x => x.trim().length > 0); 
    if (xChars.length !== yChars.length) return false; 
    let res = true; 
    yChars.forEach(x => {if(!xChars.includes(x)) res = false;}); 
    xChars.forEach(x => {if(!yChars.includes(x)) res = false;}); 
    return res; 
}; 

export const isRtlCharacterSet = (x: string[]) => getAlphabetTypes(x).includes(AlphabetType.Hebrew);
export const containsAlphabetCharacters = (x: string, alpha: AlphabetType) => {
    const alphabet = alphabetTypeToAcceptedLetters[alpha]; 
    return !!x.split("").filter(x => x.trim().length).find(x => alphabet.includes(x)); 
}

export const hasInvalidChar = (_word: string, _allowedChars: string[], checkWhiteSpace?: boolean): boolean => {
    const word = _word.split("").filter(x => !!x.trim().length || checkWhiteSpace); 
    const allowedChars = _allowedChars.filter(x => !!x.trim().length || checkWhiteSpace); 
    return !!word.find(x => allowedChars.indexOf(x) === -1);
}

export const findInvalidChars = (_word: string, _allowedChars: string[], checkWhiteSpace?: boolean): string[] => {
    const word = _word.split("").filter(x => !!x.trim().length || checkWhiteSpace); 
    const allowedChars = _allowedChars.filter(x => !!x.trim().length || checkWhiteSpace); 
    return distinct(word.filter(x => allowedChars.indexOf(x) === -1));
}