import { useCallback, useContext } from 'react';
import { eulaVersion } from '../App';
import { GlobalModalContext, SecureContext } from '../components/_MyFloor/MyFloorApp';
import { AccountType, getFullName, LoginAction } from '../model/AccountType';
import { SearchModel } from '../model/Request/SearchModel';
import { UpdateAccountRequest } from '../model/Request/UpdateAccountRequest';
import { SearchResult } from '../model/Response/SearchResult';
import { ContextFunc, ContextReturn, isFetchError, useFetchDelete, useFetchGet, useFetchPost, useFetchPut } from '../services/FetchHelper';
import { isDbImage } from '../services/ImageHelper';
import { CreateAccountRequest } from '../model/Request/CreateAccountRequest';
import { PermissionType } from '../model/Permission/PermissionType';
import { DictGetValues } from '../services/JsDict';
import { useNavigate } from 'react-router-dom';
import { TrackingOptionsRequest } from '../model/Request/TrackingOptionsRequest';


interface IAccountContext{
    useSearch: ContextFunc<SearchResult<AccountType>, [SearchModel]>;
    useMany: ContextFunc<AccountType[], [string[]]>;
    useAccount: ContextFunc<AccountType, [string, boolean?]>;
    useUpdateAccount: ContextFunc<AccountType, [string, UpdateAccountRequest]>;
    useChangePassword: ContextFunc<AccountType, [string, string, string]>;
    useMe: ContextFunc<AccountType, []>;
    useAcceptEula: ContextFunc<AccountType, []>;
    useFollowAuthor: ContextFunc<AccountType, [AccountType]>;
    useUnfollowAuthor: ContextFunc<AccountType, [AccountType]>;
    useFollowers: ContextFunc<AccountType[], [string]>;
    useFollowings: ContextFunc<AccountType[], [string]>;
    useByOrganization: ContextFunc<{[key: string]:AccountType}, [string]>;
    useDealAdmins: ContextFunc<AccountType[], []>;
    useCreate: ContextFunc<AccountType, [CreateAccountRequest]>;
    useAdminUpdateAccount: ContextFunc<AccountType, [string, {permissions: PermissionType[], isDeactivated: boolean, userGroupId: string}]>;
    useDelete: ContextFunc<void, [string]>;
    useRandom: ContextFunc<AccountType[], [number]>;
    useDeleteUsersByGroup: ContextFunc<string[], [string]>; 
    useUpdateTrackingOptions: ContextFunc<AccountType, [TrackingOptionsRequest]>; 
    useLoginHistory: ContextFunc<LoginAction[], [string]>;
    useClearCache: () => () => void;
}

const _accountCache: {[key: string]: AccountType} = {};

const updateCache = (...accounts: AccountType[]) => accounts.forEach(a => _accountCache[a.id] = a);
const removeFromCache = (...accountIds: string[]) => accountIds.forEach(a => {delete _accountCache[a]}); 


export const getAccountUrl = (id: string) => `api/account/${id}`;

export const AccountContext: IAccountContext = {
    useSearch: (): ContextReturn<SearchResult<AccountType>, [SearchModel]> => {
        const [rawInvoke, loading, error] = useFetchPost<SearchResult<AccountType>>(x => updateCache(...x.items));
        const invoke = useCallback((model: SearchModel) => rawInvoke(`api/account/search`, model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useAccount: (): ContextReturn<AccountType, [string, boolean?]> => {
        const [rawInvoke, loading, error] = useFetchGet<AccountType>(updateCache);
        const invoke = useCallback(async (id: string, fromCache?: boolean) => (fromCache && _accountCache[id]) || rawInvoke(`api/account/${id}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useUpdateAccount: (): ContextReturn<AccountType, [string, UpdateAccountRequest]> => {
        const { me, setMe } = useContext(SecureContext);
        const onAccountReceived = (data: AccountType) => {
            updateCache(data);
            if (data?.id && me?.id === data.id) {
                setMe(data);
            }
        };
        const [rawInvoke, loading, error] = useFetchPut<AccountType>(onAccountReceived);
        const invoke = useCallback((id: string, model: UpdateAccountRequest) => rawInvoke(getAccountUrl(id), createUpdateModel(model)), [rawInvoke]);

        return [invoke, loading, error];
    },
    useChangePassword: (): ContextReturn<AccountType, [string, string, string]> => {
        const [rawInvoke, loading, error] = useFetchPost<AccountType>();
        const invoke = useCallback((oldPassword: string, password: string, passwordRepeat: string) => rawInvoke(`api/account/changePassword`, { oldPassword, password, passwordRepeat }), [rawInvoke]);
        return [invoke, loading, error];
    },
    useMe: (): ContextReturn<AccountType, []> => {
        const { setMe } = useContext(SecureContext);
        const [rawInvoke, loading, error] = useFetchGet<AccountType>(x => {
            updateCache(x);
            setMe(x);
        });
        const invoke = useCallback(() => rawInvoke('api/account'), [rawInvoke]);
        return [invoke, loading, error];
    },
    useAcceptEula: function (): ContextReturn<AccountType, []> {
        const { setMe } = useContext(SecureContext);
        const navigate = useNavigate();
        const [rawInvoke, loading, error] = useFetchPost<AccountType>(a => {
            updateCache(a);
            setMe(a);
            if (a.firstname) navigate("/");
        });
        const invoke = useCallback(() => rawInvoke(`api/account/AcceptEula/${eulaVersion}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useFollowAuthor: (): ContextReturn<AccountType, [AccountType]> => {
        const { setMe } = useContext(SecureContext);
        const { popMsg } = useContext(GlobalModalContext);
        const [rawInvoke, loading, error] = useFetchPost<AccountType>(a => {
            updateCache(a);
            setMe(a);
        });
        const invoke = useCallback(async (author: AccountType) => {
            const result = await rawInvoke(`api/account/follow/${author.id}`);
            if (!isFetchError(result)) {
                popMsg("pop_friends", "pop_msg_user_follow", { name: author ? getFullName(author) : '' });
            }
            return result;
        }, [rawInvoke, popMsg]);
        return [invoke, loading, error];
    },
    useUnfollowAuthor: (): ContextReturn<AccountType, [AccountType]> => {
        const { setMe } = useContext(SecureContext);
        const { popMsg } = useContext(GlobalModalContext);
        const [rawInvoke, loading, error] = useFetchDelete<AccountType>(a => {
            updateCache(a);
            setMe(a);
        });
        const invoke = useCallback(async (author: AccountType) => {
            const result = await rawInvoke(`api/account/follow/${author.id}`);
            if (!isFetchError(result)) {
                popMsg("pop_friends", "pop_msg_user_unfollow", { name: author ? getFullName(author) : '' });
            }
            return result;
        }, [rawInvoke, popMsg]);
        return [invoke, loading, error];
    },
    useFollowers: (): ContextReturn<AccountType[], [string]> => {
        const [rawInvoke, loading, error] = useFetchGet<AccountType[]>(x => updateCache(...x));
        const invoke = useCallback((accountId: string) => rawInvoke(`api/account/${accountId}/followers`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useFollowings: (): ContextReturn<AccountType[], [string]> => {
        const [rawInvoke, loading, error] = useFetchGet<AccountType[]>(x => updateCache(...x));
        const invoke = useCallback((accountId: string) => rawInvoke(`api/account/${accountId}/followings`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useByOrganization: (): ContextReturn<{ [key: string]: AccountType; }, [string]> => {
        const [rawInvoke, loading, error] = useFetchGet<{ [key: string]: AccountType; }>(x => updateCache(...DictGetValues(x)));
        const invoke = useCallback((orgId: string) => rawInvoke(`api/account/organization/${orgId}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useDealAdmins: (): ContextReturn<AccountType[], []> => {
        const [rawInvoke, loading, error] = useFetchGet<AccountType[]>(x => updateCache(...x));
        const invoke = useCallback(() => rawInvoke(`api/account/dealadmins`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useCreate: () => {
        const { popMsg } = useContext(GlobalModalContext);
        const [rawInvoke, loading, error] = useFetchPost<AccountType>(x => {
            updateCache(x);
            popMsg("pop_added", "pop_msg_user_created", { email: x.email });
        });
        const invoke = useCallback((model: CreateAccountRequest) => rawInvoke('api/account', model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useAdminUpdateAccount: () => {
        const [rawInvoke, loading, error] = useFetchPost<AccountType>(updateCache);
        const invoke = useCallback((id: string, model: {permissions: PermissionType[], isDeactivated: boolean, userGroupId: string}) => rawInvoke(`api/account/${id}/adminEdit`, model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useDelete: function (): ContextReturn<void, [string]> {
        const [rawInvoke, loading, error] = useFetchDelete<void>();
        const invoke = useCallback((id: string) => rawInvoke(`api/account/${id}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useRandom: () => {
        const [rawInvoke, loading, error] = useFetchGet<AccountType[]>(x => updateCache(...x));
        const invoke = useCallback((count: number) => rawInvoke(`api/account/random/${count}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useMany: function (): ContextReturn<AccountType[], [string[]]> {
        const [rawInvoke, loading, error] = useFetchPost<AccountType[]>(x => updateCache(...x));
        const invoke = useCallback((ids: string[]) => rawInvoke(`api/account/many`, ids), [rawInvoke]);
        return [invoke, loading, error];
    },
    useUpdateTrackingOptions: (): ContextReturn<AccountType, [TrackingOptionsRequest]> => {
        const { setMe } = useContext(SecureContext);
        const [rawInvoke, loading, error] = useFetchPut<AccountType>(a => {
            updateCache(a);
            setMe(a);
        });
        const invoke = useCallback((m: TrackingOptionsRequest) => rawInvoke('api/account/trackingoptions', m), [rawInvoke]);
        return [invoke, loading, error];
    },
    useLoginHistory: (): ContextReturn<LoginAction[], [string]> => {
        const [rawInvoke, loading, error] = useFetchGet<LoginAction[]>();
        const invoke = useCallback((id: string) => rawInvoke(`api/account/${id}/loginhistory`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useClearCache: () => {
        return () => {
            Object.keys(_accountCache).forEach(key => delete _accountCache[key]);
        };
    }, 
    useDeleteUsersByGroup: (): ContextReturn<string[], [string]> => {
        const [rawInvoke, loading, error] = useFetchDelete<string[]>(x => removeFromCache(...x));
        const invoke = useCallback((groupId: string) => rawInvoke(`api/account/userGroup/${groupId}/deleteAllUsers`), [rawInvoke]);
        return [invoke, loading, error];
    }
}

const createUpdateModel = (model: UpdateAccountRequest) => {
    return ({
        ...model,
        reuseProfileImage: isDbImage(model.profileImage),
        profileImage: isDbImage(model.profileImage) ? undefined : model.profileImage
    });
} 
