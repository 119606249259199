import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { PlaylistContext } from '../../api/PlaylistContext';
import { PlayListType } from '../../model/PlayListType';
import { isFetchError } from '../../services/FetchHelper';
import { Button } from '../forms/FormGroup';
import Translate from '../Helper/Translate';
import PlayListPreview from '../ModelPreview/PlayListPreview';
import { GameTagList } from '../WorkShop/GameTagList';
import { SecureContext } from '../_MyFloor/MyFloorApp';
import  MenuSampleImg  from '../../images/menu_sample.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dialog from '../Dialog';
import { PlaylistStats } from '../../model/PlaylistStats';
import { ProxyContent } from '../../model/Response/FloorContent';
import { Loading } from '../Loading';
import DetailedItem from '../ModelPreview/DetailedItem';
import PlaylistFloorPreview from './PLaylistFloorPreview';
import { toDict } from '../../services/CustomFunctions';
import PlaylistOverridePanel from './PlaylistOverridePanel';
import FloorWithPlaylistOverview from './PlaylistUsage/FloorWithPlaylistOverview';
import PlaylistsWithPlaylistOverview from './PlaylistUsage/PlaylistsWithPlaylistOverview';
import OverridesWithPlaylistOverview from './PlaylistUsage/OverridessWithPlaylistOverview';

interface Props{
    playlist: PlayListType;
    onIconClick?: () => void;
    allAuthorsClick?: () => void;
}

const PlaylistDetailed = (props: Props) => {
    const {playlist, onIconClick, allAuthorsClick} = props;
    return(
        <DetailedItem
            preview={<PlayListPreview playlist={playlist} onlyImage onClick={onIconClick} />}
            owner={playlist.owner}
            authorIds={playlist.authorIds}
            authorClick={allAuthorsClick}
            title={playlist.name}
            description={playlist.description}
            createdTime={playlist.createdDateTime}
            editedTime={playlist.editedDateTime}
            document={playlist.document}
            tagList={<GameTagList game={playlist} />}
            stats={<PlaylistRelatedInfo playlist={playlist} />}
            contentPreview={<PlaylistFloorPreview playlist={playlist} />}
        />
    )
}

export default PlaylistDetailed;

interface PlaylistRelatedInfoProps{
    playlist: PlayListType;
}

const PlaylistRelatedInfo = (props: PlaylistRelatedInfoProps) => {
    const {playlist} = props;
    const {floorContent} = useContext(SecureContext);
    const proxies = useMemo(() => floorContent?.playlistContent?.[playlist.id] ?? [], [floorContent, playlist.id]);
    const [showMenu, setShowMenu] = useState<'Location'|'Playlists'|'Floors'|'Overrides'>();
    const [playlistStats, setPlaylistStats] = useState<PlaylistStats>();

    const [fetchStats, loading] = PlaylistContext.useStats();

    useEffect(() => {
        fetchStats(playlist.id).then(x => !isFetchError(x) && setPlaylistStats(x));
    }, [playlist.id, fetchStats]);

    return(
        <>
            <div className='related-info-buttons'>
                <Loading visible={loading} />
                <div>
                    <h2><Translate id="usage" /></h2>
                    <table className='simple-table'>
                        <thead>
                            <tr>
                                <th><Translate id='location' /></th>
                                <th><Translate id='count' /></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr 
                                className='clickable' 
                                onClick={() => setShowMenu("Playlists")}
                            >
                                <td><Translate id='playlists' /></td><td>{playlistStats?.playlistCount ?? 0}</td>
                            </tr>
                            <tr 
                                className='clickable' 
                                onClick={() => setShowMenu("Overrides")}
                            >
                                <td><Translate id='playlist_overrides' /></td><td>{playlistStats?.overrideCount ?? 0}</td>
                            </tr>
                            <tr 
                                className='clickable' 
                                onClick={() => setShowMenu("Floors")}
                            >
                                <td><Translate id='floors' /></td><td>{playlistStats?.floorCount ?? 0}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Button icon={'location-arrow'} onClick={() => setShowMenu('Location')} >
                    <label>
                        <Translate id={'playlist_floor_locate_button'} data={{count: proxies.length}} />
                    </label>
                </Button>
                <PlaylistOverridePanel playlist={playlist} />
            </div>
            {showMenu === 'Location' &&
                <FloorLocator playlist={playlist} proxies={proxies} onClose={() => setShowMenu(undefined)} />
            }
            {playlistStats && showMenu === 'Overrides' &&
                <OverridesWithPlaylistOverview
                    playlistId={playlist.id}
                    onClose={() => setShowMenu(undefined)} 
                />
            }
            {playlistStats && showMenu === 'Playlists' &&
                <PlaylistsWithPlaylistOverview
                    playlistId={playlist.id}
                    onClose={() => setShowMenu(undefined)} 
                />
            }
            {playlistStats && showMenu === 'Floors' &&
                <FloorWithPlaylistOverview
                    playlistId={playlist.id}
                    onClose={() => setShowMenu(undefined)} 
                />
            }
        </>
        
    )
}

const FloorLocator = (props: {playlist: PlayListType; proxies: ProxyContent[], onClose: () => void;}) => {
    const {isRtl} = useContext(SecureContext);
    const {playlist, proxies, onClose} = props;
    const {floorContent} = useContext(SecureContext);
    const [playlists, setPlaylists] = useState<{[key: string]: PlayListType}>({});
    const [getPlaylists, loadingPlaylists] = PlaylistContext.useMany();
    
    useEffect(() => {
        if(proxies){
            const plIds = [...new Set(proxies.flatMap(x => x.path))];
            getPlaylists(plIds).then(x => !isFetchError(x) && setPlaylists(toDict(x, x => x.id, x => x)));
        }
    },[proxies, getPlaylists]);
    
    return(
        <Dialog onClose={onClose} loading={loadingPlaylists}>
            <div className='playlist-related-info' dir={isRtl ? 'rtl' : undefined}>
                <h1><Translate id='playlist_floor_locate' /></h1>
                <div className='playlist-path-map'>
                    {proxies.map(x => (
                        <div className='flex' key={x.path.join(",")}>
                            <div className='root'>
                                <div className='title'>{floorContent?.floorName}</div>
                                <img src={MenuSampleImg} alt={'menu_sample'} />
                            </div>
                            <div className='seperator'><FontAwesomeIcon icon={isRtl ? 'angle-left' : 'angle-right'} /></div>
                            {x.path.map(p => 
                                <Fragment key={p}>
                                    <PlayListPreview key={p} playlist={playlists[p]} width={110} onlyImage noMenu hideBadges />
                                    <div className='seperator'><FontAwesomeIcon icon={isRtl ? 'angle-left' : 'angle-right'} /></div>
                                </Fragment>
                            )}
                            <div className='marked'><PlayListPreview playlist={playlist} width={110} onlyImage noMenu hideBadges /></div>
                        </div>
                    ))}
                </div>
            </div>
        </Dialog>
    )
}

