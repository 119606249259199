import { ReactNode, useContext, useMemo, useState } from "react";
import { ActiveContext, SecureContext } from "../../components/_MyFloor/MyFloorApp";
import { Organization } from "../../model/Organization";
import { accountIsChildAllowed } from "../../model/AccountType";
import TopMenuButton from "../../components/Menu/TopMenuButton";
import OrganizationPicker from "../../components/forms/Organization/OrganizationPicker";

const useActiveOrganization = (onPickCallBack?: (org: Organization) => void): [Organization|undefined, ReactNode, ReactNode] => {
    const {me, myOrg} = useContext(SecureContext);
    const {activeOrg: _a, setActiveOrg} = useContext(ActiveContext);
    const activeOrg = _a || myOrg;


    const [showChooseOrg, setShowChooseOrg] = useState(false);

    const topMenuButton = useMemo(() => accountIsChildAllowed(me) ? <TopMenuButton action={() => setShowChooseOrg(true)} icon={'building'} /> : undefined, [me, setShowChooseOrg]);

    const orgPicker = (
        <OrganizationPicker 
            onChoose={o => {setActiveOrg(o); onPickCallBack && onPickCallBack(o);}} 
            onClose={() => setShowChooseOrg(false)} 
            closeOnChoose 
        />
    );
    
    return [activeOrg, showChooseOrg ? orgPicker : null, topMenuButton]
}

export default useActiveOrganization;