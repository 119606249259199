import React, {CSSProperties, useEffect, useState} from 'react';
import {DbOrUploadImage} from "../../services/ImageHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import missingProfileImage from "../../images/missing-profile.png";
import {AccountType, getFullName} from "../../model/AccountType";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import { AccountContext } from '../../api/AccountContext';
import { isFetchError } from '../../services/FetchHelper';

interface Props{
    account?: AccountType;
    accountId?: string;
    onClick?: (acc: AccountType) => void;
    icon?: IconProp;
    hideName?: boolean;
    className?: string;
    style?: CSSProperties;
    fullImage?: boolean;
    onlyName?: boolean;
    small?: boolean;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}

const Author = (props: Props) => {
    const {account: _account, accountId, onClick, icon, hideName, className, style, fullImage, onlyName, small, onMouseEnter, onMouseLeave } = props;

    const [fetchedAccount, setAccount] = useState<AccountType|undefined>();
    const account = _account || fetchedAccount;
    const [getAccount] = AccountContext.useAccount();

    useEffect(() => {
        if(!_account && accountId){
            getAccount(accountId, true).then(x => !isFetchError(x) && setAccount(x));
        }
    }, [_account, accountId, getAccount]);

    if(!account) return null;
    if(onlyName) return <p className='author-name'>{getFullName(account)}</p>;
    return(
        <div
            className={`author-preview hover-trigger${small ? ' small' : ''}${onClick ? ' clickable' : ''} ${className || ''}`}
            style={style}
            onClick={onClick ? () => onClick(account) : undefined}
            onMouseLeave={onMouseLeave ? onMouseLeave : undefined}
            onMouseEnter={onMouseEnter ? onMouseEnter : undefined}
        >
            {
                !account.profileImage && account.firstname ?
                    <div className='initials-container'>
                        <div className='initials-image profile-img'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                                <text x="250" y="350" textAnchor='middle' >{account.firstname[0].toUpperCase()}{account.lastname && account.lastname[0].toUpperCase()}</text>
                            </svg>
                        </div>
                    </div>
                        :
                    <DbOrUploadImage
                        className='profile-img'
                        image={account.profileImage}
                        tempImage={missingProfileImage}
                        realSize={fullImage}
                    />
            }

            {icon && <FontAwesomeIcon icon='trash' className='icon' />}
            {!hideName && <div className='author-name' dir='auto'>{getFullName(account)}</div> }
            <div className='hover-msg author-hover-details' dir='auto'>
                {getFullName(account)}
            </div>
        </div>
    );
}

export default Author;