import React, {useEffect, useState} from 'react';
import Dialog from "../Dialog";
import {Loading} from "../Loading";
import Author from "../ModelPreview/Author";
import ConfirmationForm from "./games/ConfirmationForm";
import {AccountType, isAccountType} from "../../model/AccountType";
import InputFormGroup from './FormGroups/InputFormGroup';
import { FetchError, isFetchError } from '../../services/FetchHelper';
import { GeneralError } from '../Error/GeneralError';
import Translate from '../Helper/Translate';
import { AccountContext } from '../../api/AccountContext';
import { SearchResult } from '../../model/Response/SearchResult';

interface Props {
    chooseFunction: (acc: AccountType) => void;
    loading?: boolean;
    onClose: () => void;
    ownerId: string;
    error: FetchError|undefined;
}

const TransferOwner = (props: Props) => {
    const {error, ownerId} = props;
    const [searchTerm, setSearchTerm] = useState("");
    const [, setSearchFire] = useState<ReturnType<typeof setTimeout>>();
    const [confirmAccount, setConfirmAccount] = useState<AccountType>();
    const [searchResult, setSearchResults] = useState<SearchResult<AccountType> & {searchTerm: string}>();
    const [owner, setOwner] = useState<AccountType>();

    const [searchAccounts, loadingSearch] = AccountContext.useSearch();
    const [getAccount, loadingAccount] = AccountContext.useAccount();

    useEffect(() => {
        getAccount(ownerId, true).then(x => !isFetchError(x) && setOwner(x));
    }, [ownerId, getAccount]);

    useEffect(() => {
        searchTerm && setSearchFire(x => {
            if(x) clearTimeout(x);
            return setTimeout(() => 
                searchAccounts({searchString: searchTerm, ascending: true, sort: 'firstName'})
                    .then(x => !isFetchError(x) && setSearchResults({...x, searchTerm}))
            , 500)
        });
    }, [searchTerm, searchAccounts]);

    if(!owner) return null;

    return(
        <Dialog onClose={props.onClose} className='author-chooser-dialog' loading={props.loading||loadingAccount}>
            <h2><Translate id='current_owner'/></h2>
            <div className='authors'>
                {isAccountType(owner) &&
                    <Author account={owner} />
                }
                <div className='clear-fix'/>
            </div>
            <h2><Translate id='owner_choose'/></h2>
            <InputFormGroup
                name='search'
                type='text'
                placeholder='search'
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                noLabel
            />

            <div className='playlist-authors relative'>
                <Loading visible={loadingSearch} />
                {searchResult?.items.map(x => 
                    <Author
                        key={x.id}
                        account={x}
                        onClick={() => setConfirmAccount(x)}
                    />
                )}
                <div className='clear-fix'/>
            </div>
            <GeneralError error={error} />
            {confirmAccount &&
                <ConfirmationForm
                    text='transfer_ownership_confirm'
                    confirmText='yes'
                    cancelText='no'
                    onConfirm={() => {
                        confirmAccount && props.chooseFunction(confirmAccount);
                        setConfirmAccount(undefined)
                    }}
                    onCancel={() => setConfirmAccount(undefined)}
                />
            }
        </Dialog>
    )
}

export default TransferOwner;