import { TableColumn } from "../components/Collections/SimpleTable";

export const getCsvFileString = <T,> (items: T[], columns: TableColumn<T>[], t: (id: string) => string) => {
    const csvString: string[] = [];

    //const push header:
    const headers: string[] = [];
    columns.forEach(({id, header}) => headers.push(t(header ?? id)));
    csvString.push(headers.join(";"));

    items.forEach((item: T) => {
        const row: string[] = [];
        columns.forEach(({value, csvValue}) => row.push(csvValue ? csvValue(item) : value(item) as string));
        csvString.push(row.join(";"));
    })

    return csvString.join("\n");
}