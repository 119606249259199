import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {courseList} from "../../model/Subject";
import { getLanguageName } from '../../model/LocalizationVariables';
import Translate from '../Helper/Translate';
import { VerifiedMarkTag } from './VerifiedMark';
import { SecureContext } from '../_MyFloor/MyFloorApp';

interface Props{
    game: {id: string, languageCode: string, languageName: string, courseName: string|null, minAge: number, maxAge: number, tags: string|null, isVerified?: boolean};
    ellipsis?: boolean;
}

export const GameTagList = (props: Props) => {
    const {game, ellipsis} = props;
    const ref = useRef<HTMLDivElement>(null);
    const [removeCount, setRemoveCount] = useState(0);
    const tags = useMemo(() => game.tags?.split(",").filter(x => x) ?? [], [game.tags]);
    const {me, isRtl} = useContext(SecureContext);
    const [userLanguage, setUserLanguage] = useState(me?.language); 
    if (userLanguage !== me?.language){
        setUserLanguage(me?.language); 
        setRemoveCount(0);
    }
    useEffect(() => {
        const {current} = ref;
        const isOverflow = !!(ellipsis && current && current.scrollHeight > current.clientHeight);
        if(isOverflow && removeCount < tags.length) setRemoveCount(i => i + 1);
    },[ref, removeCount, tags, ellipsis, ref.current?.clientWidth, ref.current?.scrollHeight]);
    const activeLanguage = localStorage.getItem("myfloor_lang");
    const gameLanguage = game.courseName && getLanguageName(activeLanguage, game.courseName);
    const glCapitalized = gameLanguage ? `${gameLanguage.charAt(0).toUpperCase()}${gameLanguage.slice(1)}` : null;

    
    const reducedTags = tags.slice(0, tags.length - removeCount);
    if(removeCount) reducedTags.push("...");
    return(
        <div className='hover-trigger-2'>
            <div className='taglist' ref={ref} dir={isRtl ? 'rtl' : undefined}>
                {game.isVerified && <VerifiedMarkTag />}
                { game.languageCode && 
                    <span className='tag language hover-trigger'>
                        {game.languageCode.replace(/^\w/, c => c.toUpperCase())}
                        <div className='hover-msg'>{game.languageName}</div>
                    </span>
                }
                {
                    game.courseName &&
                    <span className='tag subject'>
                        { courseList.findIndex(c => game.courseName === c) !== -1 ?
                            <Translate id={game.courseName}/> :
                            glCapitalized
                        }
                    </span>
                }
                {
                    !!game.maxAge &&
                    <span className='tag years'>
                        {game.minAge}-{game.maxAge} <Translate id={'years_old'}/>
                    </span>
                }
                {
                    reducedTags.map(t => {
                        if(!t) return null;
                        return <span key={`${game.id}${t}`} className='tag'>{t}</span>
                    })
                }
            </div>   
            {!!removeCount && 
                <div className='hover-msg-2'>
                    <div className='taglist'>
                        {
                            tags.slice(tags.length - removeCount).map(t => <span key={`${game.id}${t}`} className='tag'>{t}</span>)
                        }
                    </div>   
                </div> 
            }
        </div>
        
    );
}