import React, { useEffect, useState } from 'react';
import { PlayListType } from '../../../model/PlayListType';
import Translate from '../../Helper/Translate';
import PlayListPreview from '../../ModelPreview/PlayListPreview';
import { PlaylistContext, PlaylistWithContent } from '../../../api/PlaylistContext';
import { isFetchError } from '../../../services/FetchHelper';
import { Loading } from '../../Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props{
    playlist: PlayListType;
    toAdd?: PlayListType;
    onConfirm: () => void;
    onCancel: () => void;
}

export const ViewModeWarning = (props: Props) => {
    const {playlist, onConfirm: _onConfirm, onCancel, toAdd} = props;

    const onConfirm = () => {
        _onConfirm();
        onCancel();
    }

    const [getContent, loadingContent] = PlaylistContext.useGetWithContent();
    const [content, setContent] = useState<PlaylistWithContent>();


    useEffect(() => {
        if(playlist.viewMode === 'Standard' && !content && !loadingContent){
            setContent({games: [], playlists: [], playlist: playlist});
            getContent(playlist.id).then(x => !isFetchError(x) && setContent(x));
        }
    }, [content, playlist, loadingContent, getContent]);

    return(
        <div className='confirm-overlay' onClick={onCancel}>
            <div className='confirm' onClick={e => e.stopPropagation()}>
                <Loading visible={loadingContent} />
                <h2 dir='auto'><Translate id='playlist_view_mode_change' /></h2>
                <div className='confirm-text' dir='auto'>
                    <Translate id={playlist.viewMode === 'Standard' ? "playlist_transform_warning_explorer" : "playlist_transform_standard_question"} />
                </div>
                <div className='flex view-mode-change'>
                    {playlist.viewMode === "Standard" &&
                        <>
                            <PlayListPreview playlist={playlist} onlyImage noMenu />
                            <FontAwesomeIcon icon={'chevron-right'} />
                            <PlayListPreview 
                                onlyImage 
                                noMenu 
                                playlist={
                                    {
                                    ...playlist, 
                                    viewMode: 'Grid', 
                                    gridGames: content?.games ?? [], 
                                    gridPlaylists: toAdd ? [...content?.playlists ?? [], toAdd] : content?.playlists ?? [],
                                    combinedSort: toAdd ? [...playlist.combinedSort, {id: toAdd?.id, sort: Number.MAX_VALUE}] : playlist.combinedSort
                                }} 
                            />
                        </>
                    }
                    {playlist.viewMode === "Grid" &&
                        <>
                            <PlayListPreview playlist={playlist} onlyImage noMenu />
                            <FontAwesomeIcon icon={'chevron-right'} />
                            <PlayListPreview playlist={{...playlist, viewMode: 'Standard'}} onlyImage noMenu />
                        </>
                    }
                    
                </div>
                <div className='confirm-buttons'>
                    <button autoFocus className='btn' onKeyPress={e => e.key === "Enter" && onConfirm()} onClick={onConfirm}><Translate id={'yes'} /></button>
                    <button className='btn' onClick={onCancel}><Translate id={'no'} /></button>
                </div>
            </div>
        </div>
    )
}