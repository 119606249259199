import { useRef, ReactNode } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Loading} from "./Loading";
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
    onClose: () => void;
    onClick?: () => void;
    onIconClick?: () => void;
    loading?: boolean;
    className?: string;
    icon?: IconProp;
    icons?: DialogIcon[];
    children: ReactNode;
    scrollable?: boolean;
}

interface DialogIcon{
    onClick: () => void;
    icon: IconProp;
    hoverMsg?: ReactNode;
    hidden?: boolean; 
    disabled?: boolean;

}

export const Dialog = (props: Props) => {
    const mouseDownInsideDialog = useRef(false);

    const onOverlayClick = () => {
        if(!mouseDownInsideDialog.current) {
            props.onClose();
        }
    };
    const dialogClasses = `dialog${props.className ? ` ${props.className}` : ''}`;
    return (
        <div className={`dialog-overlay scrollable`} onMouseDown={() => {onOverlayClick(); mouseDownInsideDialog.current = false;}}>
            <div className={dialogClasses} onMouseDown={() => mouseDownInsideDialog.current = true}>
                <div className='dialog-total-content'>
                    { props.icons &&
                        <div className='dialog-icon-container'>
                            {props.icons.filter(i => !i.hidden).map(i => 
                                    <button disabled={i.disabled} key={i.icon.toString()} className='icon hover-trigger' onClick={i.onClick}>
                                        <FontAwesomeIcon icon={i.icon} />
                                        {i.hoverMsg && 
                                            <div className='hover-msg'>
                                                {i.hoverMsg}
                                            </div>
                                        }
                                    </button>
                                
                            )}
                        </div>
                    }
                    <button className='close-button' onClick={() => props.onClose()}>
                        <FontAwesomeIcon icon='times' />
                    </button>
                    <div className='dialog-top'>
                        <Loading visible={!!props.loading}/>
                        <div className='dialog-content'>
                            {props.children}
                        </div>
                    </div>
                    <div className='dialog-bottom'>
                        { props.icon && <div className='dialog-icon' onClick={props.onIconClick}><FontAwesomeIcon icon={props.icon}/></div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dialog;