import { useCallback } from "react";
import { BaseGame } from "../../model/Game/BaseGame";
import { GameStats } from "../../model/GameStats";
import { GameSearchModel } from "../../model/Request/SearchModel";
import { SearchResult } from "../../model/Response/SearchResult";
import { ContextFunc, ContextReturn, useFetchDelete, useFetchGet, useFetchPost } from "../../services/FetchHelper";
import { GameType } from "../../model/Game/GameType";

interface IGameContext{
    useGetCopies: ContextFunc<BaseGame[], [string]>;
    useMyGames: ContextFunc<BaseGame[], []>;
    useAuthorGames: ContextFunc<BaseGame[], []>;
    useBaseGame: ContextFunc<BaseGame, [string]>;
    useManyBaseGames: ContextFunc<BaseGame[], [string[]]>;
    useOrganizationGameSearch: ContextFunc<SearchResult<BaseGame>, [string, GameSearchModel]>;
    useByFloor: ContextFunc<{[key: string]: BaseGame}, [string]>;
    useSearch: ContextFunc<SearchResult<BaseGame>, [GameSearchModel]>;
    useStats: ContextFunc<GameStats, [string]>;
    useMyLanguageGames: ContextFunc<BaseGame[], []>;
    useMyLanguageVerifiedGames: ContextFunc<BaseGame[], []>,
    useMyLanguageGamesEdit: ContextFunc<BaseGame[], []>;
    useStaticGame: ContextFunc<BaseGame[], []>;
    usePublicGames: ContextFunc<BaseGame[], [number]>;
    useGamesFromFollowlist: ContextFunc<BaseGame[], []>;
    useAccountPopularGames: ContextFunc<BaseGame[], [string]>;
    useAllAccountGames: ContextFunc<BaseGame[], [string]>;
    useDeletedGames: ContextFunc<BaseGame[], []>;
    useRestoreGame: ContextFunc<void, [GameType, string]>;
    usePermDelete: ContextFunc<void, [GameType, string]>;
    usePermDeleteAll: ContextFunc<void, []>;
    useMany: ContextFunc<BaseGame[], [string[]]>;
    useCustomersGames: ContextFunc<BaseGame[], [string]>;
}

const baseUrl = 'api/game'

export const GameContext: IGameContext = {
    useGetCopies: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame[]>();
        const invoke = useCallback((id: string) => rawInvoke(`${baseUrl}/${id}/copies`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useMyGames: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame[]>();
        const invoke = useCallback(() => rawInvoke(`${baseUrl}/mygames`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useAuthorGames: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame[]>();
        const invoke = useCallback(() => rawInvoke(`${baseUrl}/mygames/author`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useBaseGame: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame>();
        const invoke = useCallback((gameId: string) => rawInvoke(`${baseUrl}/${gameId}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useManyBaseGames: () => {
        const [rawInvoke, loading, error] = useFetchPost<BaseGame[]>();
        const invoke = useCallback((gameIds: string[]) => rawInvoke(`${baseUrl}/many`, gameIds), [rawInvoke]);
        return [invoke, loading, error];
    },
    useOrganizationGameSearch: () => {
        const [rawInvoke, loading, error] = useFetchPost<SearchResult<BaseGame>>();
        const invoke = useCallback((orgId: string, model: GameSearchModel) => rawInvoke(`${baseUrl}/organization/${orgId}/search`, model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useByFloor: () => {
        const [rawInvoke, loading, error] = useFetchPost<{ [key: string]: BaseGame; }>();
        const invoke = useCallback((apiKey: string) => rawInvoke(`${baseUrl}/byfloor`, { id: apiKey }), [rawInvoke]);
        return [invoke, loading, error];
    },
    useSearch: () => {
        const [rawInvoke, loading, error] = useFetchPost<SearchResult<BaseGame>>();
        const invoke = useCallback((model: GameSearchModel) => rawInvoke(`api/library/search/games`, model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useStats: () => {
        const [rawInvoke, loading, error] = useFetchGet<GameStats>();
        const invoke = useCallback((gameId: string) => rawInvoke(`api/game/${gameId}/stats`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useMyLanguageGames: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame[]>();
        const invoke = useCallback(() => rawInvoke('api/game/myLanguage'), [rawInvoke]);
        return [invoke, loading, error];
    },
    useMyLanguageVerifiedGames: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame[]>();
        const invoke = useCallback(() => rawInvoke('api/game/myLanguage/verified'), [rawInvoke]);
        return [invoke, loading, error];
    },
    useMyLanguageGamesEdit: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame[]>();
        const invoke = useCallback(() => rawInvoke('api/game/myLanguage/edit'), [rawInvoke]);
        return [invoke, loading, error];
    },
    useStaticGame: () => {
        const [rawInvoke, loading, error] = useFetchPost<BaseGame[]>();
        const invoke = useCallback(() => rawInvoke('api/game/static'), [rawInvoke]);
        return [invoke, loading, error];
    },
    usePublicGames: () => {
        const [rawInvoke, loading, error] = useFetchPost<BaseGame[]>();
        const invoke = useCallback((count: number) => rawInvoke(`api/game/public/${count}`, {}), [rawInvoke]);
        return [invoke, loading, error];
    },
    useGamesFromFollowlist: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame[]>();
        const invoke = useCallback(() => rawInvoke('api/game/followlist'), [rawInvoke]);
        return [invoke, loading, error];
    },
    useAccountPopularGames: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame[]>();
        const invoke = useCallback((accountId: string) => rawInvoke(`api/game/account/${accountId}/popular`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useAllAccountGames: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame[]>();
        const invoke = useCallback((accountId: string) => rawInvoke(`api/game/account/${accountId}/all`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useDeletedGames: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame[]>();
        const invoke = useCallback(() => rawInvoke(`api/game/mygames/deleted`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useRestoreGame: () => {
        const [rawInvoke, loading, error] = useFetchPost<void>();
        const invoke = useCallback((gameType: GameType, id: string) => rawInvoke(`api/workshop/${gameType}/${id}/restore`), [rawInvoke]);
        return [invoke, loading, error];
    },
    usePermDelete: () => {
        const [rawInvoke, loading, error] = useFetchDelete<void>();
        const invoke = useCallback((gameType: GameType, id: string) => rawInvoke(`api/workshop/${gameType}/${id}/perm`), [rawInvoke]);
        return [invoke, loading, error];
    },
    usePermDeleteAll: () => {
        const [rawInvoke, loading, error] = useFetchDelete<void>();
        const invoke = useCallback(() => rawInvoke(`api/game/mygames/deleted/perm`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useMany: function (): ContextReturn<BaseGame[], [string[]]> {
        const [rawInvoke, loading, error] = useFetchPost<BaseGame[]>();
        const invoke = useCallback((ids: string[]) => rawInvoke('api/game/many', ids), [rawInvoke]);
        return [invoke, loading, error];
    },
    useCustomersGames: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame[]>();
        const invoke = useCallback((orgId: string) => rawInvoke(`api/game/byCustomers/${orgId}`), [rawInvoke]);
        return [invoke, loading, error];
    }
}