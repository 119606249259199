import React, {CSSProperties, MouseEvent, useContext, useEffect, useState} from 'react';
import {DbOrUploadImage} from "../../services/ImageHelper";
import {PlayListType, getIconStyle} from "../../model/PlayListType";
import {accountHasAnyPermission} from "../../model/AccountType";
import moment from "moment";
import IconMenu, { IconMenuPointProps } from './IconMenu';
import { PlaylistContext } from '../../api/PlaylistContext';
import { isFetchError } from '../../services/FetchHelper';
import { GlobalModalContext, SecureContext } from '../_MyFloor/MyFloorApp';
import PlaylistGridCover from '../PlayList/PlaylistGridCover';
import NestedGridImage from '../../images/grid_preview.png';
import Author from './Author';
import { GameTagList } from '../WorkShop/GameTagList';
import Translate from '../Helper/Translate';
import TextView from '../TextView';
import { EventEffects } from '../../events/PlaylistEvents';
import { playlistUpdatedSetPlaylist } from '../PlayList/Explorer/Helpers';
import { ReactComponent as LicenseExpiredSvg} from '../../images/license_expired_info.svg';

interface Props{
    playlistId?: string;
    playlist?: PlayListType;
    onClick?: (playlistId: string, e: MouseEvent) => void;
    onlyImage?: boolean;
    style?: CSSProperties;
    className?: string;
    noMenu?: boolean;
    customMenu?: (IconMenuPointProps|undefined)[];
    width?: number;
    hideBadges?: boolean;
    nested?: number;
}

const maxNestedLabels = 1;
const maxNestedTitle = 2;
const maxNestedGrid = 1;

const PlayListPreview = (props: Props) => {
    const { playlistId, playlist: _playlist, onClick: _onClick, onlyImage, style, className, noMenu, customMenu, width: _width, nested: _nested, hideBadges} = props;
    const nested = _nested ?? 0;
    const {me, floorContent, isRtl} = useContext(SecureContext);
    const {showPlaylistToFloor, showPlaylistToPlaylist} = useContext(GlobalModalContext);
    const [menuVisible, setMenuVisible] = useState(false);
    const [playlist, setPlaylist] = useState<PlayListType>();
    const [getPlaylist] = PlaylistContext.usePlaylist();

    useEffect(() => setPlaylist(_playlist), [_playlist]);
    useEffect(() => {
        if(playlistId){
            getPlaylist(playlistId).then(x => !isFetchError(x) && setPlaylist(x));
        }
    }, [playlistId, getPlaylist]);

    EventEffects.usePlaylistUpdatedEffect(p => setPlaylist(pOld => playlistUpdatedSetPlaylist(pOld, p)), []);

    if(!_playlist && !playlistId) return null;
    if (!playlist) return null;

    const scale = _width ? _width / 220 : 1;
    const width = 220 * scale;
    const height = 150 * scale;
    
    const labelStyle = { background: `linear-gradient(to bottom, ${playlist.labelColorHex}DD, ${playlist.labelColorHex}00` };

    const isNews = playlist.isPublic && moment(playlist.createdDateTime).isAfter(moment().add(-30, 'days')) && (playlist.games?.length || 0) > 4;
    const isUpdated = !isNews && playlist.isPublic && moment(playlist.editedDateTime).isAfter(moment().add(-14, 'days')) && (playlist.games?.length || 0) > 4;
    const onFloor = floorContent?.playlistContent?.[playlist.id];

    const onClick = (e: MouseEvent<HTMLElement>) => {
        if(_onClick){
            e.stopPropagation();
            _onClick(playlist.id, e);
        }
        else if(!noMenu){
            setMenuVisible(x => !x);
        }
    };

    const iconstyle = {transform: `scale(${scale})`, background: playlist.backgroundColorHex};

    const renderAsGrid = getIconStyle(playlist) === "Grid";

    return(
        <div
            className={`item-icon ${className || ''}${_onClick || !noMenu ? ' clickable' : ''}`}
            onClick={onClick}
            style={scale !== 1 ? {...(style ?? {}), width: `${width}px`} : undefined}
            dir={isRtl ? 'rtl' : undefined}
        >
            <div className='icon-wrapper' style={scale !== 1 ? { width: `${width}px`, height: `${height}px` } : undefined} >
                <div className={`icon`} style={iconstyle}>
                    {!!playlist &&
                        <div className={`${playlist.isPublished ? '' : 'draft'}`} style={{ backgroundColor: playlist.backgroundColorHex }}>
                            {!renderAsGrid && <DbOrUploadImage className='image' image={playlist.image} realSize />}
                            {renderAsGrid && 
                                (nested < maxNestedGrid 
                                    ? <PlaylistGridCover playlists={playlist.gridPlaylists} games={playlist.gridGames} playlist={playlist} nested={nested} /> 
                                    : <DbOrUploadImage image={playlist.image} tempImage={NestedGridImage} />
                                )
                            }
                            {nested < maxNestedTitle && playlist.showTitle && playlist.showLabel && <div className='icon-label' style={labelStyle} />}
                            {nested < maxNestedTitle && playlist.showTitle &&                        
                                <TextView 
                                    element="div" 
                                    className="icon-title" 
                                    text={{
                                        content: playlist.name ?? "", 
                                        fontColorHex: playlist.fontColorHex ?? "", 
                                        fontFamily: playlist.fontFamily ?? "Lato", 
                                        fontSize: playlist.fontSize ?? 20,
                                    }}
                                    defaultAlignment={'initial'} 
                                />
                            }
                        </div>
                        }
                    {nested < maxNestedLabels && !noMenu && 
                        <IconMenu
                            visible={menuVisible}
                            menuPoints={customMenu || [
                                {link: `/workshop/playlist/edit/${playlist.id}`, icon: 'edit', label: 'edit_content'},
                                playlist.isPublished && accountHasAnyPermission(me, 'Floor', "ChildOrganizations", "Admin") ?
                                    {onClick: () => showPlaylistToFloor(playlist), icon: 'folder-plus', label: 'floor_add'} : 
                                    undefined,
                                playlist.isPublished && accountHasAnyPermission(me, 'Admin', 'PlaylistInPlaylist') ?
                                    {icon: 'plus-square', label: 'playlist_add', onClick: () => showPlaylistToPlaylist(playlist)} :
                                    undefined
                            ]}
                        />
                    }
                    {nested < 1 && !hideBadges &&
                        <LabelAndBadges 
                            isNews={isNews} 
                            isUpdated={isUpdated} 
                            onFloor={!!onFloor} 
                            isPublished={playlist.isPublished} 
                            isPublic={playlist.isPublic}
                            hasUnlicensedGame={playlist.hasUnlicensedGame}
                            isUnlicensed={playlist.isUnlicensed} 
                        />
                    }
                </div>
            </div>
            {!onlyImage && 
                <>
                    <div className='flex space-between' dir={isRtl ? 'rtl' : undefined}>
                        <div className='title' dir='auto'>{playlist.name}</div>
                        <div className='playlist-meta'>
                            <div className='playlist-length hover-trigger'>
                                {(playlist.games?.length ?? 0) + (playlist.nestedPlaylistIds.length ?? 0)}
                                <div className='hover-msg'>
                                    <div className='bold'><Translate id={'playlist_contains_heading'}/></div>
                                    {!!playlist.games?.length && <div><Translate id={'playlist_games_length'} data={{count: playlist.games?.length ?? 0}} /></div>}
                                    {!!playlist.nestedPlaylistIds?.length && <div><Translate id={'playlist_playlists_length'} data={{count: playlist.nestedPlaylistIds?.length ?? 0}} /></div>}
                                </div>
                            </div>
                            <Author account={playlist.owner} hideName />
                        </div>
                    </div>
                    <div className='text-wrapper' style={{height: `${90 * scale}px`}}>
                        <GameTagList game={playlist} ellipsis />
                        <div className='description' dir='auto'>{playlist.description}</div>
                    </div>
                </>
            }
        </div>
    );
}

export default PlayListPreview;

const LabelAndBadges = (props: {isPublished: boolean; isPublic: boolean; isNews: boolean; isUpdated: boolean; onFloor: boolean; isUnlicensed: boolean; hasUnlicensedGame: boolean}) => {
    const {isPublished, isPublic, isNews, isUpdated, onFloor, hasUnlicensedGame, isUnlicensed} = props;
    return(
        <>
            {!isPublished &&
                <div className='draft-label' dir="ltr">
                    <div className='draft-title'><Translate id='draft' /></div>
                    <div className='draft-text'><Translate id='draft_info' /></div>
                </div>
            }
            
            {!isPublished &&
                <div className='draft-label-2'>
                    <div><Translate id='draft'/></div>
                </div>
            }
            
            {isPublished && !isPublic &&
                <div className='publish-label'>
                    <div><Translate id='isPublished'/></div>
                </div>
            }

            {isPublished && isPublic &&
                <div className='public-label'>
                    <div><Translate id='isPublic'/></div>
                </div>
            }

            <div className='badge-container'>
                {isNews && <div className='badge new-badge'><Translate id='new_badge' /></div>}
                {isUpdated && <div className='badge update-badge'><Translate id='update_badge' /></div>}
                {onFloor && <div className='badge floor-badge'><Translate id='floor_badge' /></div>}
                {(isUnlicensed || hasUnlicensedGame) &&
                    <div className='expired-label'>
                        <LicenseExpiredSvg />
                        <div className='text hover-trigger'>
                            <div><Translate id='unlicensed_title' /></div>
                            <div className='hover-msg'><Translate id={isUnlicensed ? 'requires_license' : 'playlist_contains_unlicensed_game'} /></div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}