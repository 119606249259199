import { CSSProperties } from "react";
import {TextAlignment, TextType} from "../model/Game/TextType";

export const isRtlActive = (activeLanguage?: string) => ['iw','ar'].includes(activeLanguage || 'en');

export const getTextStyle = (t: TextType): CSSProperties => {
    return {
        fontSize: t.fontSize + 'px',
        color: t.fontColorHex,
        fontFamily: t.fontFamily,
        textAlign: getTextAlign(t)
    };
}

export const getHashString = (t: TextType): string => {
    if(!t) return "";
    return `${t.content}${t.fontFamily}${t.fontSize}${t.fontColorHex}`.toLowerCase();
};

export const getTextAlign = (t: TextType): 'left'|'center'|'right' => {
    if(t.textAlignment === TextAlignment.Left){
        return 'left';
    }
    else if(t.textAlignment === TextAlignment.Right){
        return 'right';
    }
    else{
        return 'center';
    }
};

export const superScriptValues = [
    {key: '0', unicode: '\u2070'},
    {key: '1', unicode: '\u00B9'},
    {key: '2', unicode: '\u00B2'},
    {key: '3', unicode: '\u00B3'},
    {key: '4', unicode: '\u2074'},
    {key: '5', unicode: '\u2075'},
    {key: '6', unicode: '\u2076'},
    {key: '7', unicode: '\u2077'},
    {key: '8', unicode: '\u2078'},
    {key: '9', unicode: '\u2079'},
    {key: '+', unicode: '\u207A'},
    {key: '-', unicode: '\u207B'},
    {key: '=', unicode: '\u207C'},
    {key: '(', unicode: '\u207D'},
    {key: ')', unicode: '\u207E'}
];
export const subScriptValues = [
    {key: '0', unicode: '\u2080'},
    {key: '1', unicode: '\u2081'},
    {key: '2', unicode: '\u2082'},
    {key: '3', unicode: '\u2083'},
    {key: '4', unicode: '\u2084'},
    {key: '5', unicode: '\u2085'},
    {key: '6', unicode: '\u2086'},
    {key: '7', unicode: '\u2087'},
    {key: '8', unicode: '\u2088'},
    {key: '9', unicode: '\u2089'},
    {key: '+', unicode: '\u208A'},
    {key: '-', unicode: '\u208B'},
    {key: '=', unicode: '\u208C'},
    {key: '(', unicode: '\u208D'},
    {key: ')', unicode: '\u208E'}
];
export const mathTextValues = [
    {key: 'sqrt', unicode: '\u221A'},
    {key: 'infinity', unicode: '\u221E'},
    {key: 'mangle', unicode: '\u2221'},
    {key: 'angle', unicode: '\u2220'},
    {key: 'aequal', unicode: '\u2248'},
    {key: 'lequal', unicode: '\u2266'},
    {key: '<=', unicode: '\u2266'},
    {key: 'gequal', unicode: '\u2267'},
    {key: '>=', unicode: '\u2267'},
    {key: 'dot', unicode: '\u22C5'},
    {key: '*', unicode: '\u22C5'},
    {key: 'bullet', unicode: '\u2219'},
    {key: "1/4", unicode: "\u00BC"},
    {key: "1/2", unicode: "\u00BD"},
    {key: "3/4", unicode: "\u00BE"},
    {key: '/', unicode: '\u2215'},
    {key: 'div', unicode: '\u2215'},
    {key: '-', unicode: '\u2212'},
    {key: 'min', unicode: '\u2212'},
    {key: 'sum', unicode: '\u2211'},
    {key: "pi", unicode: "\u03C0"},
    {key: "deg", unicode: "\u00B0"},
    {key:"+-", unicode: "\u00B1"}
]

export const getSuperScriptText = (t: string): string => {
    superScriptValues.forEach(x => t = t.replaceAll(x.key, x.unicode))
    return t;
}

export const getSubScriptText = (t: string): string => {
    subScriptValues.forEach(x => t = t.replaceAll(x.key, x.unicode))
    return t;
}

export const getMathText = (t: string): string => {
    mathTextValues.forEach(x => t = t.replaceAll(x.key, x.unicode))
    return t;
}

export const capitalizeString = (x: string) => x.charAt(0).toUpperCase() + x.slice(1);
export const unCapitalizeString = (x: string) => x.charAt(0).toLowerCase() + x.slice(1);