import React from 'react';
import PlayListPreview from "../ModelPreview/PlayListPreview";
import Game from "../ModelPreview/Game";
import { PlayListType } from '../../model/PlayListType';
import { BaseGame } from '../../model/Game/BaseGame';

interface Props{
    playlist: PlayListType;
    playlists: PlayListType[];
    games: BaseGame[];

    onClick?: () => void;
    className?: string;
    nested?: number;
}

const PlaylistGridCover = (props: Props) => {
    const {playlists, games, playlist, nested} = props;
    let itemCount = 0;
    return (
        <div className='grid-cover'>
            {playlist.combinedSort.map(x => {
                if(itemCount === 9) return null;

                const p = playlist.id === x.id ? playlist : playlists?.find(p => p.id === x.id);
                if(p){
                    itemCount++;
                    return <div
                        key={x.id} 
                        className='grid-item'
                    >
                        <PlayListPreview 
                            playlist={{...p, gridPlaylists: playlists, gridGames: games}} 
                            onlyImage 
                            noMenu 
                            hideBadges 
                            nested={(nested ?? 0) + 1}
                        />
                    </div>
                } 
                const game = games?.find(g => g.id === x.id);
                if(game){
                    itemCount++;
                    return <div key={x.id} className='grid-item'><Game game={game} onlyImage noMenu hideBadges /></div>
                } 
                return null;
            })}
        </div>
    );
};

export default PlaylistGridCover;