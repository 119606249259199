import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Dropzone from "react-dropzone";
import { DocumentUploadType } from "../../../model/DocumentUploadType";
import Dialog from "../../Dialog";
import { SubmitButton } from "../FormGroup";
import { DbDocument } from '../../../model/DbDocument';
import Translate from '../../Helper/Translate';

interface Props {
    className?: string;
    name?: string;
    onChange: (model: DocumentUploadType|undefined) => void;
    value: DbDocument|DocumentUploadType|undefined;
}
export const DocumentFormGroup = (props: Props) => {
    const {className, name, onChange, value } = props;
    const accept = 'application/pdf';
    const [showForm, setShowForm] = useState(false);
    return(
        <>
            <div className={`form-group ${className || ''}`}>
                <div className='button-quick'>
                    <QuickDocument submit={onChange} accept={accept} />
                    <button type='button' className='btn' onClick={() => setShowForm(true)}>
                        { name && <Translate id={name} /> }
                    </button>
                    {value && 
                        <span className={'remove-doc-container'} onClick={() => onChange(undefined)}>
                            <FontAwesomeIcon icon='file-pdf' className='icon-doc' />
                            <FontAwesomeIcon icon='times' className='remove-icon'/>
                        </span>
                    }
                    <div className='clear-fix' />
                </div>
            </div>
            { showForm &&
                <Dialog onClose={() => setShowForm(false)}>
                    <DocumentForm
                        accept={accept}
                        submit={d => {
                            onChange(d);
                            setShowForm(false);
                        }}
                    />
                </Dialog>
            }
        </>
    )
};


interface QuickProps{
    submit: (doc: DocumentUploadType) => void;
    accept: string;
}

const QuickDocument = (props: QuickProps) => {
    const {submit, accept} = props;

    const fileDropped = (acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        if (file){
            submit({ file });
        }
    };

    return(
        <div className='quick-upload'>
            <div className='drop-container'>
                <Dropzone onDrop={fileDropped} accept={accept}>
                    {({getRootProps, getInputProps, isDragActive}) => {
                        return (
                            <div {...getRootProps()} className={`dropzone${isDragActive ? ' active' : ''} `}>
                                <input {...getInputProps()}/>
                                <FontAwesomeIcon icon='upload' />
                            </div>
                        )
                    }}
                </Dropzone>
            </div>
        </div>
    );
};



interface FormProps{
    submit: (s: DocumentUploadType) => void;
    accept: string;
}

const DocumentForm = (props: FormProps) => {
    const {submit, accept} = props;
    const [file, setFile] = useState<File>();
    

    const fileDropped = (acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        if (file){
            setFile(file);
        }
    };

    return(
        <div>
            <h2><Translate id='document_form_title'/></h2>
            <div className='drop-container'>
                <div>
                    <Dropzone onDrop={fileDropped} accept={accept}>
                        {({getRootProps, getInputProps, isDragActive}) => {
                            return (
                                <div {...getRootProps()} className={`dropzone${isDragActive ? ' active' : ''} `}>
                                    <input {...getInputProps()}/>
                                    <p><Translate id='dropzone_upload_text_document' /></p>
                                </div>
                            )
                        }}
                    </Dropzone>
                </div>
            </div>
            <div className='sound-container'>
                {file && <FontAwesomeIcon icon={"file-pdf"}/>
                }
            </div>
            <SubmitButton split text={'submit'} onClick={() => file && submit({file})} disabled={!file} />
        </div>

    );
}